import React from "react";

const DocViewer = ({ fileUrl }) => {
  const url = `https://docs.google.com/gview?url=${fileUrl}&embedded=true`;

  return (
    <iframe
      src={url}
      style={{ width: "100%", height: "500px" }}
      frameBorder="0"
    ></iframe>
  );
};

export default DocViewer;
