import styled from "styled-components/macro";
import { colorProvider, commonStyles } from "../utils/StylePropertiy";

export const NotiListContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  border-radius: 8px;
`;

export const NotiInnerList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackTwo}`
      : commonStyles.grey_bor};
`;
export const NotiHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.themeColor ? "#1F242F" : "#edeef1")};
  font-size: 0.8rem;
  font-weight: 400;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#464c5e")};
  border-radius: 8px 8px 0 0;
  padding: 0.8rem 1.2rem;

  img {
    height: 18px;
    width: 18px;
    object-fit: contain;
    margin-right: 10px;
  }
`;
export const NotiList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
`;
export const NotiItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.7rem 0.8rem;
  border-top: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #edeef1"};
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#FFFFFF")};
`;
export const FirstNoti = styled.div`
  display: flex;
  align-items: center;
`;
export const ColorCircle = styled.div`
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: red;
  margin-left: 4px;
`;
export const IconCircle = styled.div`
  margin: 0 1rem;
  img {
    height: 30px;
    width: 30px;
    object-fit: contain;
  }
`;
export const NotiDesc = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363a44")};
`;
export const NotiView = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
  color: ${(props) => (props.themeColor ? "#F05D24" : "#f05d24")};
  margin-right: 1.5rem;
`;
export const DateTime = styled.div`
  font-size: 0.8rem;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#8a94a6")};
  font-weight: 400;
`;
export const NavBarNoti = styled.nav`
  width: ${commonStyles.wid};
  max-width: 1350px;
  display: ${commonStyles.ds_fx};
  flex-direction: column;
  padding: 0.7rem 0;
  margin-top: 1rem;
  /* position: sticky;
  top: 0; */
  @media (max-width: 1400px) {
    padding: 0.7rem 1rem;
  }
`;