import styled from "styled-components/macro";

export const SettingContainer = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const InnerSetting = styled.div`
  width: 100%;
  border-top: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
export const RowCommiittee = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
    margin-bottom: 1.5rem;
`
export const ComRowSwitch = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 1.5rem 0;
  gap: 0.5rem;

  p {
    font-size: 1rem;
    font-weight: 600;
    color: ${(props) => (props.themeColor ? "#CECFD2" : "#363A44")};
  }
`;
export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  border-radius: 8px;
`;
export const ParentContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  border-radius: 8px;
  padding: 1.7rem;
`;
export const CourseContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  border-radius: 8px;
  padding: 1.7rem;
  gap: 1.5rem;
`;
export const InnerContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1.5rem;
  margin-bottom: 0.5rem;
`;
export const InnerContentContainerCourse = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
`;
export const FormTitle = styled.div`
  width: 100%;
  text-align: left;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  font-weight: 500;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#24262d")};
  font-family: "EB Garamond", serif;
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
  padding-bottom: 1rem;
  text-transform: capitalize;

  p {
    text-transform: capitalize;
  }
`;
export const CheckContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
`;
export const ComContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
`;
export const CheckTitleRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  p {
    font-size: 0.8rem;
    font-weight: 500;
    color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
    text-transform: capitalize;
  }
  img {
    height: 18px;
    width: 18px;
    object-fit: contain;
    cursor: pointer;
  }
`;
export const TrailerRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: ${(props) =>
    props.themeColor ? "1px solid #1F242F" : "1px solid #EDEEF1"};
  border-radius: 8px;
  padding: 1rem;
`;
export const TrailerFirst = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  p {
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: capitalize;
    color: ${(props) => (props.themeColor ? "#ECECED" : "#363A44")};
  }
  img {
    height: 18px;
    width: 18px;
    object-fit: contain;
    cursor: pointer;
  }
`;
export const TrailerSecond = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  .trailer-text {
    font-size: 0.8rem;
    font-weight: 500;
    color: ${(props) => (props.themeColor ? "#94969C" : "#3D424F")};
  }
  .orange-text {
    font-size: 0.8rem;
    font-weight: 500;
    color: ${(props) => (props.themeColor ? "#F05D24" : "#F05D24")};
    text-decoration: underline;
    border-left: 1px solid #b3b9c6;
    padding-left: 1rem;
    cursor: pointer;
  }
  img {
    height: 18px;
    width: 18px;
    object-fit: contain;
    margin-right: 5px;
  }
`;
export const AddCommit = styled.div`
  background-color: #f6591c;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  gap: 7px;

  p {
    color: #ffffff;
    font-size: 0.8rem;
    font-weight: 600;
    
  }

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 5px;
  }

  @media (max-width: 769px) {
    justify-content: center;
    align-items: center;
    p {
      display: none;
    }
    img {
      margin: 0;
    }
  }
`;