import styled from "styled-components/macro";
import downArrowIcon from "../assets/images/downArrow.png";
import {
  commonStyles,
  colorProvider,
  fontProvider,
  fontWeight,
} from "../utils/StylePropertiy";

export const SendMailBox = styled.section`
  width: 100%;
  min-width: 650px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) =>
    props.themeColor ? colorProvider.darkBlue : colorProvider.white};
  padding: 2rem;
`;
export const SendMailBoxTwo = styled.section`
  width: 100%;
  min-width: 600px;
  display: flex;
  flex-direction: column;
  min-width: ${(props) => props.wiDth};
  margin: 1rem 0;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#FFFFFF")};
`;
export const SendMailBoxThree = styled.section`
  width: 100%;
  min-width: 500px;
  max-height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  min-width: ${(props) => props.wiDth};
  margin: 1rem 0;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#FFFFFF")};
`;
export const PublicRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
export const LabelBox = styled.div`
  width: 20%;

  p {
    color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
    font-weight: 500;
    font-size: 0.8rem;
  }
`;
export const InputBoxContainer = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
`;
export const InputBox = styled.div`
  width: 100%;
`;
export const EmailEditor = styled.div`
  width: 100%;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#FFFFFF")};
  margin-top: 1rem;
  margin-bottom: 3rem;
  max-width: 600px;
  svg {
    /* display: none; */
  }
  .quill {
    height: 150px; // Set the height you want for the editor
    width: 100%; // Set the width you want for the editor
    .ql-toolbar .ql-active {
      border: ${(props) =>
        props.themeColor
          ? "1px solid #333741"
          : "1px solid #D7DAE0"}; /* Light grey background for active buttons */
      border-radius: 5px;
    }

    .ql-toolbar .ql-active img {
      opacity: 0.7; /* Slightly lower opacity for the icon when active */
    }
    .ql-toolbar.ql-snow {
      border: ${(props) =>
        props.error
          ? `1px solid red`
          : props.themeColor
          ? `1px solid #333741`
          : commonStyles.com_b} !important;
    }
    .ql-toolbar.ql-snow + .ql-container.ql-snow {
      border: ${(props) =>
        props.error
          ? `1px solid red`
          : props.themeColor
          ? `1px solid #333741`
          : commonStyles.com_b} !important;
    }
    .ql-snow .ql-picker-options {
      background-color: ${(props) =>
        props.themeColor ? colorProvider.darkBlue : colorProvider.white};
    }
    .ql-container {
      height: 100%;
      border-radius: 0 0 8px 8px;
    }

    .ql-editor {
      min-height: 100%;
      padding: 10px;
      overflow-y: auto;
    }

    .ql-toolbar {
      border-radius: 8px 8px 0 0;
    }
    .ql-snow .ql-stroke {
      stroke: ${(props) =>
        props.themeColor ? "#94969C" : colorProvider.seeMore} !important;
    }

    .ql-snow .ql-fill,
    .ql-snow .ql-stroke.ql-fill {
      fill: ${(props) =>
        props.themeColor ? "#94969C" : colorProvider.seeMore} !important;
    }
    .ql-snow .ql-picker.ql-picker {
      border: ${(props) =>
        props.themeColor ? `1px solid #333741` : commonStyles.com_b} !important;
      border-radius: 8px;
      padding: 4px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${(props) =>
        props.themeColor ? "#94969C" : colorProvider.greyDark} !important;

      svg {
        display: none;
      }

      .ql-picker-label::before {
        /* display: none; */
      }

      .ql-picker-label::after {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        background: url(${downArrowIcon}) no-repeat center center;
        background-size: contain;
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }

      &.ql-expanded .ql-picker-label::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
      border-color: transparent;
    }
    .ql-snow.ql-toolbar button,
    .ql-snow .ql-toolbar button {
      margin-right: 12px !important;
    }
    .ql-toolbar.ql-snow .ql-formats {
      margin-right: 0 !important;
    }
    .ql-editor.ql-blank::before {
      color: ${(props) => (props.themeColor ? "#0C111D" : "#000")} !important;
    }
    .ql-editor p {
      color: ${(props) => (props.themeColor ? "#fff" : "#000")} !important;
    }
  }
`;
export const InputBottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  margin-bottom: 1rem;
`;
export const CopyBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  p {
    color: #363a44;
    font-weight: 500;
    font-size: 0.8rem;
  }

  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
    margin-right: 7px;
  }
`;
export const ComPublicRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  p {
    color: #363a44;
    font-weight: 500;
    font-size: 0.8rem;
  }
`;
// Week Days

export const WeekDayRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_fe};
  align-items: ${commonStyles.ai_c};
  margin: ${fontProvider.zeroTen} 0;
`;
export const InnerWeekDay = styled.div`
  width: 78%;
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.ai_c};
`;

export const DayCom = styled.div`
  border: ${(props) =>
    props.isActive
      ? `1px solid #333741`
      : props.themeColor
      ? `1px solid #333741`
      : commonStyles.com_b};
  border-radius: ${commonStyles.sec_br};
  padding: ${fontProvider.zeroSix} ${fontProvider.zeroTen};
  cursor: ${commonStyles.pointer};
  background-color: ${(props) =>
    props.isActive
      ? colorProvider.navLink
      : props.theme
      ? colorProvider.darkBlue
      : colorProvider.white};

  p {
    font-size: ${fontProvider.zeroNin};
    font-weight: ${fontWeight.four};
    color: ${colorProvider.greyDark};
    color: ${(props) =>
      props.isActive
        ? colorProvider.white
        : props.themeColor
        ? colorProvider.whiteThree
        : colorProvider.greyDark};
  }
`;
export const DaysContainer = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.ai_c};
`;
export const DaysFirst = styled.div`
  width: 22%;
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
`;
export const DaysSecond = styled.div`
  width: 78%;
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
`;
export const ComRow = styled.div`
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.ai_c};
  gap: ${fontProvider.zeroFive};
`;
export const ComFeedbackRowssUser = styled.div`
  width: 17%;
  display: flex;
  align-items: center;
`;
export const CheckBoxs = styled.div`
  cursor: pointer;
  img {
    height: 20px !important;
    width: 20px !important;
    object-fit: contain;
    margin-right: 10px;
  }
`;
export const FeedbackOrangeTextss = styled.div`
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
  font-size: ${(props) => (props.fontSizes ? props.fontSizes : "0.8rem")};
  font-weight: 500;
  text-transform: capitalize;
  @media (max-width: 769px) {
    width: 100%;
  }
`;
