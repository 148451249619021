import { lazy } from "react";
import Notification from "../pages/private/Notification";

// Public Route
const Login = lazy(() => import("../pages/public/Login"));
const Forgot = lazy(() => import("../pages/public/Forgot"));
const CheckMail = lazy(() => import("../pages/public/CheckMail"));
const ResetPass = lazy(() => import("../pages/public/ResetPass"));
const Success = lazy(() => import("../pages/public/Success"));
const GetOtp = lazy(() => import("../pages/public/GetOtp"));
const EnterOtp = lazy(() => import("../pages/public/EnterOtp"));
const OtpSuccess = lazy(() => import("../pages/public/OtpSuccess"));
const RedirectPage = lazy(() => import("../pages/public/RedirectPage"));
// Private Route
// const Homepage = lazy(() => import("../pages/private/Homepage"));
const Homepage = lazy(() => import("../pages/private/Homepage"));
const Courses = lazy(() => import("../pages/private/Courses"));
const Reports = lazy(() => import("../pages/private/Reports"));
const CourseContent = lazy(() =>
  import("../pages/private/CourseDetail/CourseContent")
);
const CourseDetail = lazy(() =>
  import("../pages/private/CourseDetail/CourseDetail")
);
const ManageLearners = lazy(() =>
  import("../pages/private/CourseDetail/ManageLearners")
);
const PolicyDocument = lazy(() =>
  import("../pages/private/CourseDetail/PolicyDocument")
);
const ReqAdditional = lazy(() =>
  import("../pages/private/CourseDetail/ReqAdditional")
);
const Committee = lazy(() => import("../pages/private/CourseDetail/Committee"));
const AddCommittee = lazy(() =>
  import("../pages/private/CourseDetail/AddCommittee")
);
const Renew = lazy(() => import("../pages/private/CourseDetail/Renew"));

const LearnerView = lazy(() =>
  import("../pages/private/CourseDetail/LearnerView")
);
const AddLearner = lazy(() =>
  import("../pages/private/CourseDetail/AddLearner")
);
const AddPolicy = lazy(() => import("../pages/private/CourseDetail/AddPolicy"));
const ProfileDetails = lazy(() =>
  import("../pages/private/Profile/ProfileDetails")
);
const Password = lazy(() => import("../pages/private/Profile/Password"));
const CompanyDetails = lazy(() =>
  import("../pages/private/Profile/CompanyDetails")
);
const Feedback = lazy(() => import("../pages/private/Feedback/Feedback"));

const ReportManagement = lazy(() =>
  import("../pages/private/Reports/MasterReport.js")
);
const CourseReports = lazy(() =>
  import("../pages/private/Reports/SubReports/CourseReports.js")
);
const ClientReports = lazy(() =>
  import("../pages/private/Reports/SubReports/ClientReports.js")
);
const AgreementDateReports = lazy(() =>
  import("../pages/private/Reports/SubReports/AgreementDateReports.js")
);
const IndustryReports = lazy(() =>
  import("../pages/private/Reports/SubReports/IndustryReports.js")
);
const RollsReports = lazy(() =>
  import("../pages/private/Reports/SubReports/RollsReports.js")
);
const ProgressReports = lazy(() =>
  import("../pages/private/Reports/SubReports/ProgressReports.js")
);
const AuditReports = lazy(() =>
  import("../pages/private/Reports/SubReports/AuditReports.js")
);
export const publicRoutes = [
  { path: "/client-admin", component: Login },
  { path: "/client-admin/forgot", component: Forgot },
  { path: "/client-admin/check-mail", component: CheckMail },
  { path: "/client-admin/reset-password", component: ResetPass },
  { path: "/client-admin/success", component: Success },
  { path: "/client-admin/get-otp", component: GetOtp },
  { path: "/client-admin/otp", component: EnterOtp },
  { path: "/client-admin/otp-success", component: OtpSuccess },
  { path: "/client-admin/redirect", component: RedirectPage },
];
export const privateRoutes = [
  { path: "/client-admin/dashboard", component: Homepage },
  { path: "/client-admin/courses", component: Courses },
  // { path: "/client-admin/courses-content", component: CourseContent },
  // { path: "/client-admin/courses-detail", component: CourseDetail },
  // { path: "/client-admin/manage-learners", component: ManageLearners },
  // { path: "/client-admin/learner-view", component: LearnerView },
  // { path: "/client-admin/add-learner", component: AddLearner },
  // { path: "/client-admin/policy-document", component: PolicyDocument },
  // { path: "/client-admin/add-policy", component: AddPolicy },
  // { path: "/client-admin/committee", component: Committee },
  // { path: "/client-admin/add-committee", component: AddCommittee },
  // {
  //   path: "/client-admin/request-additional-learners",
  //   component: ReqAdditional,
  // },
  // { path: "/client-admin/renew", component: Renew },
  // { path: "/client-admin/reports", component: Reports },
  // { path: "/client-admin/master-reports", component: ReportManagement },
  // { path: "/client-admin/course-reports", component: CourseReports },
  // { path: "/client-admin/client-reports", component: ClientReports },
  // { path: "/client-admin/agreement-reports", component: AgreementDateReports },
  // { path: "/client-admin/industry-reports", component: IndustryReports },
  // { path: "/client-admin/roll-out-reports", component: RollsReports },
  // { path: "/client-admin/progress-reports", component: ProgressReports },
  { path: "/client-admin/notification", component: Notification },
  { path: "/client-admin/profile-details", component: ProfileDetails },
  { path: "/client-admin/password", component: Password },
  { path: "/client-admin/company-details", component: CompanyDetails },
];
export const surveyRoutes = [
  { path: "/client-admin/client-survey-form", component: Feedback },
];
