export const commonStyles = {
  ds_fx: "flex",
  jc_sb: "space-between",
  jc_c: "center",
  jc_fs: "flex-start",
  jc_fe: "flex-end",
  jc_l: "left",
  jc_r: "right",
  ai_c: "center",
  ai_fs: "flex-start",
  ai_fe: "flex-end",
  wid: "100%",
  het: "100%",
  of_ct: "center",
  ob_con: "contain",
  fd_col: "column",
  fd_row: "row",
  three_px: "3px",
  four_px: "4px",
  five_px: "5px",
  seven_px: "7px",
  nav_br: "6px",
  sec_br: "8px",
  com_b: "1px solid #D7DAE0",
  navcom_r_b: "1px solid #B3B9C6",
  course_bor: "1px solid #edeef1",
  grey_bor: "1px solid #EAECF0",
  trans_bor: "1px solid transparent",
  orange_bor: "1px solid #F05D24",
  wrap: "wrap",
  cap: "capitalize",
  pointer: "pointer",
};

export const colorProvider = {
  comLink: "#667085",
  navLink: "#F05D24",
  navBackground: "#F6F7F9",
  white: "#FFFFFF",
  trannsp: "transparent",
  blueColor: "#002245",
  lightGrey: "#D7DAE0",
  orange: "#F6591C",
  greyDark: "#363A44",
  greyTer: "#D1D2D4",
  warOrange: "#FDA712",
  transColor: "#0C111D8A",
  red: "red",
  blackCol: "#24262D",
  dateColor: "#565E73",
  numberT: "#464C5E",
  seeMore: "#8A94A6",
  redText: "#E0271A",
  green: "#17B26A",
  lightOrange: "#FFF5ED",
  darkBlue: "#0C111D",
  greyOne: "#B3B9C6",
  blackOne: "#0D2344",
  whiteTwo: "#ECECED",
  blackTwo: "#333741",
  blackThree: "#161B26",
  blackFour: "#344054",
  greyThree: "#94969C",
  blackFive: "#1F242F",
  greyFour: "#F5F5F6",
  whiteThree: "#CECFD2",
  greyFive: "#85888E",
  greySix: "#F0F1F1"
};
export const fontProvider = {
  zeroThree: "0.3rem",
  zeroFour: "0.4rem",
  zeroFive: "0.5rem",
  zeroSix: "0.6rem",
  zeroSev: "0.7rem",
  zeroEig: "0.8rem",
  zeroNin: "0.9rem",
  zeroTen: "1rem",
  oneOne: "1.1rem",
  oneTwo: "1.2rem",
  oneThree: "1.3rem",
  oneFour: "1.4rem",
  oneFive: "1.5rem",
  oneSix: "1.6rem",
  oneSev: "1.7rem",
  oneEig: "1.8rem",
  oneNine: "1.9rem",
  two: "2rem",
  twoOne: "2.1rem",
  twoTwo: "2.2rem",
  twoThree: "2.3rem",
  twoFour: "2.4rem",
  twoFive: "2.5rem",
  twoSix: "2.6rem",
  twoSev: "2.7rem",
  twoEig: "2.8rem",
};
export const fontWeight = {
  three: "300",
  four: "400",
  five: "500",
  six: "600",
  seven: "700",
  eight: "800",
};
