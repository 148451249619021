import styled from "styled-components/macro";
import {
  commonStyles,
  colorProvider,
  fontProvider,
  fontWeight,
} from "../utils/StylePropertiy";
import { Link } from "react-router-dom";

export const DetailContainer = styled.section`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_fs};
  align-items: ${commonStyles.jc_fs};
  text-transform: ${commonStyles.cap};
  position: relative;
  /* background-color: aqua; */
  /* height: 100vh; */
`;
export const BackImage = styled.div`
  width: ${commonStyles.wid};
  position: absolute;

  top: 0;

  img {
    width: ${commonStyles.wid};
    height: 252px;
    object-fit: fill;
    filter: blur(12px);
  }

  .transBack {
    width: ${commonStyles.wid};
    height: 252px;
    /* background: red; */
    position: absolute;
    top: 0;
    background: #00000066;
  }
`;
export const BackToCourse = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_fs};
  align-items: ${commonStyles.jc_c};
  margin-top: 1.5rem;
  cursor: ${commonStyles.pointer};

  img {
    height: 12px;
    width: 12px;
    object-fit: ${commonStyles.ob_con};
  }

  p {
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.five};
    color: ${(props) =>
      props.themeColor ? colorProvider.greySix : colorProvider.lightGrey};
    margin-left: 10px;
  }
`;
export const DetailInnerCon = styled.div`
  width: ${commonStyles.wid};
  max-width: 1350px;
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_fs};
  flex-direction: ${commonStyles.fd_col};

  @media (max-width: 320px) {
    padding: 0 1rem;
  }
  @media (max-width: 480px) {
    padding: 0 1rem;
  }
  @media (max-width: 769px) {
    padding: 0 1rem;
  }
  @media (max-width: 1024px) {
    padding: 0 1rem;
  }
`;
export const DetailCon = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  z-index: 1;
  align-items: ${commonStyles.ai_c};
  background-color: ${(props) =>
    props.themeColor ? "#0C111D" : "transparent"};
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;
export const TitleBoxes = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_fs};
  align-items: ${commonStyles.jc_fs};
  flex-direction: ${commonStyles.fd_col};
  border-radius: 12px;
  margin-top: ${fontProvider.two};

  .logo-box {
    height: 45px;
    width: 93px;
    border-radius: 4px;
    object-fit: center;
  }

  p {
    font-family: EB Garamond;
    font-size: ${fontProvider.twoSev};
    font-weight: ${fontWeight.five};
    color: ${colorProvider.white};
    margin-top: 5px;
    @media (max-width: 320px) {
      font-size: 1.7rem;
    }
    @media (max-width: 480px) {
      font-size: 1.7rem;
    }
  }
  span {
    font-family: EB Garamond;
    font-size: ${fontProvider.twoSix};
    font-weight: ${fontWeight.eight};
    color: ${colorProvider.white};
    @media (max-width: 320px) {
      font-size: 1.7rem;
    }
    @media (max-width: 480px) {
      font-size: 1.7rem;
    }
  }

  .desc {
    font-family: "Inter", sans-serif;
    font-size: ${fontProvider.zeroTen};
    font-weight: ${fontWeight.five};
    color: ${colorProvider.white};
  }
`;
export const BadgeRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_c};
  margin-top: 13px;
`;
export const BadgeBox = styled.div`
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.jc_c};
  box-shadow: 0px 1px 2px 0px #0000000f, 0px 1px 3px 0px #0000001a;
  background-color: ${(props) =>
    props.themeColor ? colorProvider.blackThree : colorProvider.white};
  border-radius: ${commonStyles.sec_br};
  padding: ${fontProvider.zeroTen} ${fontProvider.two};

  @media (max-width: 320px) {
    width: 100%;
    padding: 1rem;
  }
  @media (max-width: 480px) {
    width: 100%;
    padding: 1rem;
  }
`;
export const BadgeCommonBox = styled.div`
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
`;
export const BadgeTitle = styled.p`
  font-size: ${fontProvider.zeroEig};
  font-weight: ${fontWeight.five};
  color: ${(props) =>
    props.themeColor ? colorProvider.greyThree : colorProvider.comLink};

  @media (max-width: 320px) {
    font-size: 0.5rem;
  }
  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;
export const BadgeImageRow = styled.div`
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.jc_c};
  margin-top: ${fontProvider.zeroSev};
  gap: ${fontProvider.zeroThree};
`;
export const Badge = styled.div`
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  border-radius: 50%;
  align-items: ${commonStyles.jc_c};
  background-color: ${(props) =>
    props.themeColor ? colorProvider.darkBlue : colorProvider.navBackground};
  border: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackTwo}`
      : commonStyles.course_bor};

  padding: ${fontProvider.zeroThree};
  img {
    height: 52px;
    width: 52px;
    object-fit: ${commonStyles.ob_con};
    border-radius: 50%;

    @media (max-width: 480px) {
      height: 30px;
      width: 30px;
    }
    @media (max-width: 320px) {
      height: 21px;
      width: 21px;
    }
  }
`;
export const VerLine = styled.div`
  height: 40px;
  width: 2px;
  background-color: ${colorProvider.lightGrey};
  margin: 0 ${fontProvider.two};
  @media (max-width: 320px) {
    margin: 0 0.8rem;
  }
  @media (max-width: 480px) {
    margin: 0 0.8rem;
  }
`;
export const PeopleBadge = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 50%;
  position: relative;
  border: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackTwo}`
      : `1px solid ${colorProvider.white}`};

  img {
    height: ${commonStyles.wid};
    width: ${commonStyles.wid};
    border-radius: 50%;
  }
  @media (max-width: 480px) {
    height: 40px;
    width: 40px;
  }

  @media (max-width: 320px) {
    height: 30px;
    width: 30px;
  }

  .circle-box {
    height: 27px;
    width: 27px;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    border: ${(props) =>
      props.themeColor
        ? `2px solid ${colorProvider.blackTwo}`
        : `2px solid ${colorProvider.white}`};
    display: ${commonStyles.ds_fx};
    justify-content: ${commonStyles.jc_c};
    align-items: ${commonStyles.jc_c};
    text-align: ${commonStyles.jc_c};
    background-color: ${colorProvider.navBackground};
    background-color: ${(props) =>
      props.themeColor ? colorProvider.darkBlue : colorProvider.navBackground};
    font-size: ${fontProvider.zeroSev};
    font-weight: ${fontWeight.six};
    color: ${colorProvider.navLink};
    @media (max-width: 320px) {
      height: 12px;
      width: 12px;
      font-size: 0.25rem;
    }
    @media (max-width: 480px) {
      height: 16px;
      width: 16px;
      font-size: 0.35rem;
    }
  }

  .common-box {
    height: ${commonStyles.wid};
    width: ${commonStyles.wid};
    border-radius: 50%;
    display: ${commonStyles.ds_fx};
    justify-content: ${commonStyles.jc_c};
    align-items: ${commonStyles.jc_c};
    text-align: ${commonStyles.jc_c};
    background-color: ${colorProvider.lightOrange};
    font-size: ${fontProvider.zeroTen};
    font-weight: ${fontWeight.six};
    color: ${colorProvider.navLink};
    @media (max-width: 320px) {
      font-size: 0.5rem;
    }
  }
`;
export const CourseContentBox = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.ai_fs};
  align-items: ${commonStyles.ai_fs};
  flex-direction: ${commonStyles.fd_col};
`;
export const MultiHeadingRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.ai_fs};
  align-items: ${commonStyles.jc_c};
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #edeef1"};

  /* padding-bottom: 10px; */
  margin-top: 1.6rem;

  @media (max-width: 552px) {
    overflow-x: scroll;
  }
  ::-webkit-scrollbar {
    display: none !important;
  }
`;
export const HeadingBox = styled(Link)`
  text-decoration: none;
  color: ${(props) =>
    props.activecolor
      ? colorProvider.orange
      : props.themeColor
      ? colorProvider.greyFive
      : colorProvider.comLink};
  font-size: ${fontProvider.zeroEig};
  font-weight: ${fontWeight.five};
  margin-right: ${fontProvider.zeroTen};
  padding-bottom: 14px;
  cursor: ${commonStyles.pointer};
  border-bottom: ${(props) =>
    props.activecolor ? "2px solid #F6591C" : "2px solid transparent"};
`;
export const AboutBox = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.ai_fs};
  align-items: ${commonStyles.ai_fs};
  flex-direction: ${commonStyles.fd_col};
  border-bottom: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackTwo}`
      : commonStyles.com_b};
  margin-top: ${fontProvider.oneFive};
  padding-bottom: ${fontProvider.zeroEig};

  .about-title {
    font-size: ${fontProvider.oneTwo};
    font-weight: ${fontWeight.six};
    color: ${(props) =>
      props.themeColor ? colorProvider.greyFour : colorProvider.blackCol};
    font-family: EB Garamond;
    margin-bottom: 10px;
  }
  .about-desc {
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.four};
    color: ${colorProvider.numberT};
    color: ${(props) =>
      props.themeColor ? colorProvider.whiteThree : colorProvider.numberT};
    line-height: 20px;
    text-transform: none;
  }
`;
export const EpisodeTitle = styled.p`
  font-size: ${fontProvider.oneFive};
  font-weight: ${fontWeight.five};
  color: ${(props) =>
    props.themeColor ? colorProvider.greyFour : colorProvider.blackCol};
  font-family: EB Garamond;
  margin: ${fontProvider.zeroTen} 0;
  text-align: ${commonStyles.jc_l};
`;
// Episodes
export const EpisodeContainer = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.ai_fs};
  align-items: ${commonStyles.ai_fs};
  flex-direction: ${commonStyles.fd_col};
`;
export const VideoRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};

  flex-direction: ${commonStyles.fd_col};
  align-items: ${commonStyles.jc_c};
  border: ${(props) =>
    props.themeColor ? "1px solid #333741;" : "1px solid #d7dae0"};
  background-color: ${(props) =>
    props.themeColor ? "#0C111D" : "transparent"};
  border-radius: 8px;
  padding: 12px 14px;
  margin-bottom: 10px;
  position: relative;
`;
export const FileRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  position: relative;
`;
export const VideoImageBox = styled.div`
  border-radius: 8px;
  position: relative;

  img {
    height: 70px;
    width: 100px;
    border-radius: 8px;
    object-fit: contain;
  }
  .play-butttonon {
    position: absolute;
    /* top: 50%; */
    height: 25px;
    width: 25px;
    object-fit: contain;
    margin-top: 1.5rem;
    margin-left: -4rem;
    cursor: pointer;
  }
`;
export const VideoContent = styled.div`
  display: ${commonStyles.ds_fx};

  flex-direction: ${commonStyles.fd_col};
  align-items: ${commonStyles.jc_c};
  width: ${commonStyles.wid};
  justify-content: ${commonStyles.jc_c};
  margin-left: 8px;
`;
export const VideoTitleRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-content: ${commonStyles.jc_c};
`;
export const VideoTitle = styled.div`
  font-size: ${fontProvider.zeroEig};
  font-weight: 500;
  color: ${(props) =>
    props.themeColor ? colorProvider.greyFour : colorProvider.greyDark};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.jc_c};
  width: ${commonStyles.wid};

  button {
    border: none;
    outline: none;
    cursor: ${commonStyles.pointer};
    background: transparent;
  }
  img {
    height: 10px;
    width: 10px;
    object-fit: ${commonStyles.ob_con};
  }
`;
export const MinBox = styled.div`
  font-size: ${fontProvider.zeroSev};
  color: ${(props) =>
    props.themeColor ? colorProvider.greyThree : colorProvider.comLink};
  width: ${commonStyles.wid};
  margin: 5px 0;
`;
export const TagsBox = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};

  flex-direction: ${commonStyles.fd_col};
  align-items: ${commonStyles.jc_c};
`;
export const TagsTitle = styled.div`
  font-size: ${fontProvider.zeroSev};
  font-weight: 600;
  color: ${(props) =>
    props.themeColor ? colorProvider.greyFour : colorProvider.greyDark};
  width: ${commonStyles.wid};
  margin: 7px 0 0.5rem 0;
`;
export const TagsRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-wrap: wrap;
  align-items: ${commonStyles.jc_c};
  margin-top: 5px;
`;
export const ComTags = styled.div`
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.jc_c};
  background-color: ${(props) => (props.themeColor ? "#161B26" : "#f6f7f9")};
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid transparent"};
  color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
  padding: 5px 7px;
  font-size: ${fontProvider.zeroSev};
  font-weight: 500;
  margin-right: 7px;
  border-radius: 4px;
`;
export const DescContent = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-wrap: wrap;
  align-items: ${commonStyles.jc_c};
  font-size: ${fontProvider.zeroSev};
  color: ${(props) =>
    props.themeColor ? colorProvider.whiteTwo : colorProvider.comLink};
  font-weight: 400;
  line-height: 17px;
`;
export const MediaDescBox = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
`;
export const MediaDescText = styled.div`
  font-size: ${fontProvider.zeroSev};
  font-weight: 400;
  text-align: left;
  color: #8a94a6;
  display: ${commonStyles.ds_fx};
`;
// Pop-up
export const LeaderContainer = styled.div`
  background-color: ${colorProvider.darkBlue};
  display: ${commonStyles.ds_fx};
  width: ${commonStyles.wid};
  flex-direction: ${commonStyles.fd_col};
  padding: ${fontProvider.oneTwo};
  border-radius: ${commonStyles.sec_br};
  @media (max-width: 480px) {
    margin-top: 12rem;
  }
`;
export const LeaderHeader = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.jc_c};
  border-bottom: 1px solid #333741;
  padding-bottom: ${fontProvider.zeroEig};
`;
export const HeaderBox = styled.div`
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};

  .head-title {
    font-size: ${fontProvider.oneThree};
    font-weight: ${fontWeight.six};
    text-align: ${commonStyles.jc_l};
    color: ${colorProvider.white};
    font-family: EB Garamond;
  }
  .head-desc {
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.five};
    text-align: ${commonStyles.jc_l};
    color: ${colorProvider.greyOne};
  }
`;
export const CrossButton = styled.div`
  cursor: ${commonStyles.pointer};
  img {
    height: 12px;
    width: 12px;
    object-fit: ${commonStyles.ob_con};
  }
`;
export const TopRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.jc_fe};
`;
export const CommonTopper = styled.div`
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.jc_c};
  flex-direction: ${commonStyles.fd_col};
  margin-top: ${fontProvider.oneTwo};
`;
export const CustProfile = styled.div`
  /* This creates a larger "background" that can show through as a border. Adjust padding to control "border" size */
  /* padding: 2px; */
  border-radius: 50%;

  /* Gradient background */
  background-image: linear-gradient(156.58deg, #fcfcfd 17.17%, #b7b8be 84.45%);

  /* Ensuring the gradient background only shows within the border area */
  background-clip: padding-box;

  /* Cover up the excess background to only show it as a border */
  box-shadow: 0 0 0 2px white; /* Adjust the color to match your component's background */

  img {
    height: 110px;
    width: 110px;
    border-radius: 50%;
    display: block; /* This ensures the img occupies the full size without extra space */
    object-fit: cover; /* Adjusts the image's fit within the container without stretching it */

    @media (max-width: 320px) {
      height: 85px;
      width: 85px;
    }
    @media (max-width: 480px) {
      height: 85px;
      width: 85px;
    }
  }
`;
export const Crown = styled.div`
  margin-bottom: 5px;

  img {
    height: auto;
    width: 60px;
  }
`;
export const CustProfileGold = styled.div`
  /* This creates a larger "background" that can show through as a border. Adjust padding to control "border" size */
  /* padding: 2px; */

  /* Gradient background */

  /* Create space for the "gradient border" inside the solid border */

  /* Apply the gradient as a background */
  background-image: linear-gradient(
      293.93deg,
      #b98d5f 21.63%,
      #ffffff 32.3%,
      #d3b790 69.28%,
      #f7e1c2 75.48%,
      #d3b790 80.66%
    ),
    linear-gradient(
      258.85deg,
      #cfac82 59.85%,
      #fcedd7 66.98%,
      #f8edc0 71.9%,
      #cfac82 73.96%,
      #cfac82 82.85%
    );

  /* Ensure the background (gradient) is clipped to the padding box */
  background-clip: content-box;

  /* Optionally, if you want rounded borders */
  border-radius: 50%;

  /* Adjustments for the inner content */
  display: flex;
  justify-content: center;
  align-items: center;
  /* Height and width as needed */
  height: 135px;
  width: 135px;

  /* Ensuring the gradient background only shows within the border area */
  /* background-clip: padding-box; */

  /* Cover up the excess background to only show it as a border */
  /* box-shadow: 0 0 0 2px white;  */
  /* Adjust the color to match your component's background */
  @media (max-width: 320px) {
    height: 105px;
    width: 105px;
  }

  img {
    height: 130px;
    width: 130px;
    border-radius: 50%;
    display: block; /* This ensures the img occupies the full size without extra space */
    object-fit: cover; /* Adjusts the image's fit within the container without stretching it */
    @media (max-width: 320px) {
      height: 100px;
      width: 100px;
    }
  }
`;
export const NumberBox = styled.div`
  margin-top: -1.4rem;
  img {
    height: 40px;
    width: 40px;
    object-fit: ${commonStyles.ob_con};
  }
`;
export const ProfileTitle = styled.p`
  font-size: ${fontProvider.zeroEig};
  font-weight: ${fontWeight.six};
  color: ${colorProvider.white};
  margin-top: ${fontProvider.zeroFive};
  @media (max-width: 320px) {
    font-size: 0.6rem;
    font-weight: 400;
  }
`;
export const UserList = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  /* justify-content: ${commonStyles.jc_sb}; */
  align-items: ${commonStyles.jc_c};
  flex-direction: ${commonStyles.fd_col};
  margin-top: ${fontProvider.oneTwo};
  gap: ${fontProvider.zeroSix};
`;
export const UserRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.jc_c};
  background-color: ${colorProvider.blackThree};
  border-radius: ${commonStyles.sec_br};
  padding: ${fontProvider.zeroSev} ${fontProvider.zeroTen};
`;
export const ProfileNameBox = styled.div`
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.jc_c};
  gap: ${fontProvider.zeroSix};

  img {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: 1.5px solid ${colorProvider.blackTwo};
    object-fit: ${commonStyles.ob_con};
  }

  p {
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.five};
    color: ${colorProvider.whiteTwo};
  }
`;
export const CicleNumber = styled.div`
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_c};
  background-color: ${colorProvider.blackTwo};
  border-radius: 50%;
  padding: 5px 10px;

  p {
    font-size: ${fontProvider.zeroTen};
    font-weight: ${fontWeight.five};
    color: ${colorProvider.whiteTwo};
  }
`;
export const SaveButton = styled.button`
  background-color: #f6591c;
  border-radius: 5px;
  text-align: center;
  font-weight: 600;
  font-size: 0.8rem;
  color: #ffffff;
  /* width: 100%; */
  outline: none;
  border: none;
  padding: 0.6rem 1rem;
  margin: 1.6rem 0;
  margin-right: 0.5rem;
  cursor: ${(props) => (props.cursor ? "not-allowed" : "pointer")};

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 5px;
  }

  /* @media (max-width: 351px) {
    font-size: 1rem;
  } */

  .buttonload {
    background-color: #04aa6d; /* Green background */
    border: none; /* Remove borders */
    color: white; /* White text */
    padding: 12px 16px; /* Some padding */
    font-size: 16px; /* Set a font size */
  }

  i {
    /* margin-left: 0.5rem; */
    font-size: 1.4rem;
  }

  :hover {
    background-color: #417690;
  }
`;
export const DownloadContainer = styled.div`
  min-width: 400px;
  padding: 1.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background-color: ${(props) =>
    props.themeColor ? colorProvider.darkBlue : colorProvider.white};
  border-radius: 12px;

  img {
    height: 50px;
    width: 50px;
    object-fit: contain;
  }

  .title {
    font-size: 1.2rem;
    font-weight: 600;
    color: ${(props) =>
      props.themeColor ? colorProvider.greyFour : colorProvider.blackCol};
  }
`;
export const AddCommitteeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
  border-radius: 8px;
`;
