import React, { useEffect, useRef, useState } from "react";
import * as CDS from "../../../styles/CourseDetailStyled";
import * as SES from "../../../styles/SettingStyled";
import * as MS from "../../../styles/ManageStyled";
import * as MSPOP from "../../../styles/ModelStyledTwo";
import * as TS from "../../../styles/TemplateStyled";
import greenTick from "../../../assets/images/greenTick.png";
import redCross from "../../../assets/images/redCross.png";
import shareIcon from "../../../assets/images/shareIcon.png";
import plusIcon from "../../../assets/images/plusIcon.png";
import YearInput from "../../../components/DatePicker";
import searchIcon from "../../../assets/images/lightSearchIcon.png";
import lightDownload from "../../../assets/images/lightDownload.png";
import CourseView from "./CourseView";
import Select from "../../../components/Select";
import darkCheckboxBaseIcon from "../../../assets/images/dark_base.png";
import darkcheckIcon from "../../../assets/images/darkcheckIcon.png";
import checkboxIcon from "../../../assets/images/Checkbox.png";
import checkboxBaseIcon from "../../../assets/images/_Checkbox_base.png";
import { useSelector, useDispatch } from "react-redux";
import { setTableLength } from "../../../store/action";
import * as SEC from "../../../styles/SectionStyled";
import { Table } from "antd";
import * as CS from "../../../styles/CommonStyled";
import editIcon from "../../../assets/images/editLight.png";
import darkArrow from "../../../assets/images/darkArrow.png";
import darkRight from "../../../assets/images/darkRight.png";
import lighRight from "../../../assets/images/lighRight.png";
import lightLeft from "../../../assets/images/lightLeft.png";
import moment from "moment";
import dotImage from "../../../assets/images/dotImage.png";
import delIcon from "../../../assets/images/delete.png";
import binTable from "../../../assets/images/binTable.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import bookIcon from "../../../assets/images/bookIcon.png";
import Switch from "../../../components/Switch/Switch";
import darkDownload from "../../../assets/images/darkDownload.png";
import crossIcon from "../../../assets/images/crossIcon.png";
import lightEye from "../../../assets/images/lightEye.png";
import { ComMember, Commiittee, Course } from "../../../utils/api";
import axios from "../../../utils/axios";
import { removeDuplicatesById } from "../../../utils/Helpers";
import { Modal as PopUp } from "../../../components/PopUp/Modal";
import { colorProvider } from "../../../utils/StylePropertiy";
import PdfView from "./PdfView";
import DocViewer from "./DocView";
import Modal from "../../../components/Modal/Modal";
import ModalPolicy from "../../../components/Modal/ModalPolicy";
import { Formik, Field, Form, FieldArray } from "formik";
import * as PS from "../../../styles/ProfileStyled";
import * as LS from "../../../styles/LearnerStyled";
import Skeleton from "react-loading-skeleton";
import {
  comiteClientValidator,
  memberValidator,
} from "../../../utils/validator";
import * as UE from "../../../styles/UserElement";
import Input from "../../../components/InputAddTwo";
import plsuIcon from "../../../assets/images/plsuIcon.png";
import * as CMS from "../../../styles/CommitteeStyled";
import { useLocation } from "react-router-dom";
import baseRadio from "../../../assets/images/baseRadio.png";
import checkRadio from "../../../assets/images/checkRadio.png";
import darkBaseRadio from "../../../assets/images/darkBaseRadio.png";
import darkCheckRadio from "../../../assets/images/darkCheckRadio.png";
import PageSelectTwo from "../../../components/PageSelectTwo";
// import { PopUp } from "../../../components/PopUp/PopUp";
import { get } from "lodash";
import InputMobile from "../../../components/InputMobile";
import profileDemo from "../../../assets/images/profileDemo.png";
import darkBin from "../../../assets/images/darkBin.png";
import binTwo from "../../../assets/images/binTwo.png";
import UploadTrailerOnButton from "../../../components/UploadTrailerOnButton_Two";
import { UploadFile } from "../../../utils/uploadImage";
import warnIcon from "../../../assets/images/warn.png";

const AddCommittee = ({ navigate, theme, courseId, localState }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [stateData, setStateData] = useState(location?.state?.category_data);
  const [activeId, setActiveId] = useState(false);
  const [subIsAddCommit, setSubIsAddCommit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMem, setIsLoadingMem] = useState(false);
  const [isComitForm, setIsComitForm] = useState(false);
  const [errorObj, setErrorObj] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [currentPageMem, setCurrentPageMem] = useState(1);
  const [totalCountMem, setTotalCountMem] = useState(1);
  const [listIdMem, setListIdMem] = useState("");
  const [opensMem, setOpensMem] = useState(false);
  const [isAddCommit, setIsAddCommit] = useState(false);
  const [listId, setListId] = useState("");
  const [opens, setOpens] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [currentPageLearner, setCurrentPageLearner] = useState(1);
  const [feildLoading, setFeildLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const sentinelRef = useRef();
  const sentinelRefMem = useRef();
  const [inputType, setInputType] = useState("createdAt");
  const [sortType, setSortType] = useState("ascend");
  const [comitInitialValues, setComitInitialValues] = useState({
    committeeName: get(stateData, "committeeName", ""),
    city: get(stateData, "city", ""),
    commiteId: get(stateData, "commiteId", ""),
  });

  const skeletonBaseColor = theme ? "#1F242F" : "#EDEEF1";
  const skeletonHighlightColor = theme ? "#1F242F" : "#EDEEF1";
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const pageLimitLearner = 10;
  const pageLimit = 10;
  const [memberValues, setMemberValues] = useState({
    internal: true,
    external: false,
    name: "",
    email: "",
    countryCode: "",
    mobileNumber: "",
    city: "",
    position: "",
    profilePicture: "",
    fileName: "",
    fileSize: "",
    fileUrl: "",
    learnerId: "",
    profileImage: "",
  });
  const [userList, setUserList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const handleComit = async (values) => {
    setIsLoading(true);
    let url = Commiittee.addCommiittee;
    const formValue = {
      courseId: courseId,
      city: values.city,
      title: values.committeeName,
    };
    if (values.commiteId) formValue["_id"] = values.commiteId;
    try {
      const { data } = await axios.post(url, formValue);
      setIsLoading(false);
      navigate(-1);
    } catch (error) {
      setIsLoading(false);
      console.log(error.response.data.message);
      let errObj = {};
      error.response.data.errors?.map((val) => {
        console.log(val.path, "error val");
        if (!errObj[val.path]) {
          errObj[val.path] = val.msg;
        }
      });
      setErrorObj(errObj);
    }
  };

  const getComMember = async () => {
    setIsLoadingMem(true);

    if (currentPageMem === 1) {
      setMemberList([]); // Reset feedback list on major parameter change or first page load
    }
    let url = `${ComMember.getComMember}`;
    const formValue = {
      limit: pageLimit,
      page: currentPageMem,
      commiteId: comitInitialValues?.commiteId,
    };
    try {
      const { data } = await axios.post(url, formValue);
      setIsLoadingMem(false);
      if (data?.result?.docs?.length > 0) {
        setMemberList((prevList) => [
          ...prevList, // Keeps existing data if this is a subsequent page of data
          ...data?.result?.docs,
        ]);
      } else if (currentPage === 1) {
        setMemberList([]); // Clear the feedback list if response is empty and it's the first page
      }
      setTotalCountMem(data?.result?.totalPages);
    } catch (error) {
      // alert(error.response.data.message);
      setIsLoadingMem(false);
      console.log(error.response.data.message);
    }
  };
  const handleMember = async (values) => {
    console.log(values, "values");
    setIsLoading(true);
    let url = ComMember.addComMember;
    const formValue = {
      // city: values.city,
      title: comitInitialValues?.committeeName,
      // mobileNumber: values.mobileNumber,
      // countryCode: values.countryCode || "+91",
      email: values.email,
      name: values.name,
      position: values.designation || "",
      // profileImage: values.profileImage,
      commiteId: comitInitialValues?.commiteId,
    };
    if (values.city) formValue["city"] = values.city;
    if (values.mobileNumber) formValue["mobileNumber"] = values.mobileNumber;
    if (values.countryCode) formValue["countryCode"] = values.countryCode;
    if (values.position) formValue["position"] = values.position;
    if (values.profileImage) formValue["profileImage"] = values.profileImage;
    if (values.internal) formValue["type"] = "internal";
    if (values.external) formValue["type"] = "external";
    if (values.learnerId) formValue["learnerId"] = values.learnerId;
    if (values._id) formValue["_id"] = values._id;
    try {
      const { data } = await axios.post(url, formValue);
      setIsLoading(false);
      setIsComitForm(false);

      setErrorObj({});
      getComMember();
    } catch (error) {
      setIsLoading(false);
      console.log(error.response.data.message);
      let errObj = {};
      error.response.data.errors?.map((val) => {
        console.log(val.path, "error val");
        if (!errObj[val.path]) {
          errObj[val.path] = val.msg;
        }
      });
      setErrorObj(errObj);
    }
  };
  const loadCategoryOptions = async (
    search,
    loadedOptions,
    { page = 1 } = {}
  ) => {
    let url = `${Course.getCourseContent}learners`;
    const formValue = {
      page: currentPageLearner,
      limit: pageLimitLearner,
      _id: courseId,
      languageId: "66262d5fac4634a8113234ae",
      userStatus: "all",
      courseStatus: "all",
    };
    search && (formValue["search"] = search);
    try {
      const { data } = await axios.post(url, formValue);

      if (data?.result) {
        const options = data?.result?.docs?.map((item) => ({
          label: item.userName || "",
          value: item._id || "",
          email: item.email || "",
          countryCode: item.mobileNumberCountryCode || "",
          mobileNumber: item.mobileNumber || "",
          position: item.designation || "",
          city: item.city || "",
        }));

        setCategoryList((oldCat) => [...oldCat, ...options]);

        return {
          options,
          hasMore: page < data?.result?.totalPages, // Determine if there are more pages
          additional: {
            page: page + 1, // Prepare for the next page
          },
        };
      }
    } catch (error) {
      console.log(error.response?.data?.message || "An error occurred");
    }

    return { options: [], hasMore: false, additional: { page: page + 1 } };
  };

  useEffect(() => {
    // CategoryList(-1, "createdAt");
    loadCategoryOptions("", { currentPageLearner });
  }, [currentPageLearner]);
  useEffect(() => {
    if (stateData?.commiteId && currentPageMem) {
      getComMember(-1, "createdAt");
    }
  }, [stateData?.commiteId, currentPageMem]);

  const handleClose = () => {
    setIsComitForm(false);
  };
  const handleCloses = () => {
    setOpens(false);
  };
  const deleteMember = async (e) => {
    let url = ComMember.delComMember;
    const formValue = {
      ids: [e],
    };
    try {
      const { data } = await axios.post(url, formValue);
      getComMember();
      setOpensMem(false);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  return (
    <>
      <CDS.DetailContainer>
        {/* <CourseView
          theme={theme}
          courseId={courseId}
          navigate={navigate}
          localState={localState}
        /> */}

        <CDS.DetailCon>
          <CDS.DetailInnerCon style={{ overflow: "hidden" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                width: "100%",
              }}
            >
              <Formik
                enableReinitialize
                initialValues={comitInitialValues}
                validate={comiteClientValidator}
                validateOnChange
                onSubmit={handleComit}
              >
                {(formikBag) => (
                  <Form style={{ width: "100%" }}>
                    <UE.FormContainer
                      style={{ padding: 0, marginTop: "1.5rem" }}
                      themeColor={theme}
                    >
                      <UE.FormContainer
                        themeColor={theme}
                        style={{ padding: "0" }}
                      >
                        <UE.InputBoxContainer themeColor={theme}>
                          <UE.InputBox wiDth={"49%"}>
                            <Field name={`committeeName`}>
                              {({ field, form, meta }) => (
                                <Input
                                  {...field}
                                  placeholder={localState.committee_name}
                                  label="Committee Name"
                                  onChange={(e) => {
                                    formikBag.setFieldValue(
                                      "committeeName",
                                      e.target.value
                                    );
                                    setErrorObj({});
                                  }}
                                  error={
                                    formikBag.touched.committeeName &&
                                    formikBag.errors.committeeName
                                      ? formikBag.errors.committeeName
                                      : null
                                  }
                                  erroros={errorObj.title}
                                />
                              )}
                            </Field>
                          </UE.InputBox>
                          <UE.InputBox wiDth={"49%"}>
                            <Field name={`city`}>
                              {({ field, form, meta }) => (
                                <Input
                                  {...field}
                                  placeholder={localState.city}
                                  label="City"
                                  onChange={(e) => {
                                    formikBag.setFieldValue(
                                      "city",
                                      e.target.value
                                    );
                                    setErrorObj({});
                                  }}
                                  error={
                                    formikBag.touched.city &&
                                    formikBag.errors.city
                                      ? formikBag.errors.city
                                      : null
                                  }
                                  erroros={errorObj.city}
                                />
                              )}
                            </Field>
                          </UE.InputBox>
                        </UE.InputBoxContainer>
                        {comitInitialValues?.commiteId ? (
                          <UE.AddQuizsComit
                            style={{
                              justifyContent: "right",
                            }}
                          >
                            <button
                              type="button"
                              onClick={() => {
                                setIsComitForm(true);
                                setMemberValues({
                                  internal: true,
                                  external: false,
                                  name: "",
                                  email: "",
                                  countryCode: "",
                                  mobileNumber: "",
                                  city: "",
                                  position: "",
                                  profilePicture: "",
                                  fileName: "",
                                  fileSize: "",
                                  fileUrl: "",
                                  learnerId: "",
                                });

                                setErrorObj({});
                              }}
                            >
                              <img
                                src={plsuIcon}
                                style={{ marginRight: "10px" }}
                                alt=""
                              />
                              {localState.add_member}
                            </button>
                          </UE.AddQuizsComit>
                        ) : (
                          ""
                        )}
                      </UE.FormContainer>
                    </UE.FormContainer>
                    {/* <UE.FormContainerBlue
                      marTop={stateData?.commiteId ? "-1rem" : "16rem"}
                      themeColor={theme}
                    ></UE.FormContainerBlue> */}
                    {stateData?.commiteId ? (
                      removeDuplicatesById(memberList)?.length > 0 ? (
                        <UE.FormContainerComitTwo themeColor={theme}>
                          {removeDuplicatesById(memberList)?.map((val, ind) => {
                            return (
                              <CMS.CommitRow themeColor={theme} key={ind}>
                                <CMS.FirstBox themeColor={theme}>
                                  <p className="title">{val.name}</p>
                                  <p className="desc">{val.city}</p>
                                </CMS.FirstBox>
                                <CMS.IconBox themeColor={theme}>
                                  <img
                                    src={editIcon}
                                    onClick={() => {
                                      setMemberValues({
                                        internal: val.type === "internal",
                                        external: val.type === "external",
                                        name: val.name || "",
                                        email: val.email || "",
                                        countryCode: val.countryCode || "",
                                        mobileNumber: val.mobileNumber || "",
                                        city: val.city || "",
                                        position: val.position || "",
                                        profilePicture:
                                          val.profilePicture || "",
                                        fileName: val.fileName || "",
                                        fileSize: val.fileSize || "",
                                        profileImage: val.profileImage || "",
                                        _id: val._id || "",
                                        learnerId: val.learnerId,
                                        commiteId:
                                          comitInitialValues?.commiteId,
                                      });
                                      setIsComitForm(true);

                                      setErrorObj({});
                                    }}
                                    alt=""
                                  />
                                  <img
                                    src={delIcon}
                                    onClick={() => {
                                      setListIdMem(val._id);
                                      setOpensMem(true);
                                    }}
                                    alt=""
                                  />
                                </CMS.IconBox>
                              </CMS.CommitRow>
                            );
                          })}
                          <div
                            ref={sentinelRefMem}
                            style={{ height: "1px", width: "100%" }}
                          ></div>
                        </UE.FormContainerComitTwo>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}

                    <UE.ButtonGroup
                      themeColor={theme}
                      style={{ margin: "0.5rem 0", padding: "0.8rem 0" }}
                    >
                      <UE.CancelButton
                        style={{ margin: "0.5rem 0" }}
                        themeColor={theme}
                        type="button"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        {localState.cancel}
                      </UE.CancelButton>
                      <UE.SaveButton
                        style={{ margin: "0.5rem 0" }}
                        // cursor={isLoading}
                        // disabled={isLoading}
                        className="buttonload"
                        type="submit"
                      >
                        {isLoading ? (
                          <i class="fa fa-spinner fa-spin"></i>
                        ) : (
                          localState.save_and_continue
                        )}
                      </UE.SaveButton>
                    </UE.ButtonGroup>
                  </Form>
                )}
              </Formik>
            </div>
          </CDS.DetailInnerCon>
        </CDS.DetailCon>
      </CDS.DetailContainer>
      <PopUp
        isOpen={isComitForm}
        onClose={handleClose}
        maxWidth="700px"
        title={
          <CMS.FirstRow
            themeColor={theme}
            style={{ padding: "0 !important", marginBottom: "1rem" }}
          >
            <SES.ComRowSwitch
              themeColor={theme}
              style={{ margin: "1rem 0 0.5rem 0", fontSize: "0.9rem" }}
            >
              <p style={{ fontWeight: "600" }}>
                {localState.add_internal_or_add_external_members} :
              </p>
            </SES.ComRowSwitch>
          </CMS.FirstRow>
        }
        content={
          <>
            <SEC.InnerSectionsBanner themeColor={theme}>
              <Formik
                enableReinitialize
                initialValues={memberValues}
                validate={memberValidator}
                validateOnChange
                onSubmit={(values) => handleMember(values, false)}
              >
                {(formikBag) => {
                  return (
                    <Form style={{ width: "100%", overflowX: "hidden" }}>
                      <UE.FormContainerBanner
                        style={{ margin: 0 }}
                        themeColor={theme}
                      >
                        <UE.FormContainerBorder
                          themeColor={theme}
                          style={{
                            padding: "0.8rem 0.7rem 0.8rem 1rem",
                            marginBottom: "1rem",
                          }}
                        >
                          <UE.InputBoxContainers
                            style={{
                              justifyContent: "flex-start",
                              // marginTop: "0.7rem",
                            }}
                          >
                            <UE.ComFeedbackRowss>
                              <UE.CheckBoxs
                                onClick={() => {
                                  formikBag.setFieldValue(`internal`, true);
                                  formikBag.setFieldValue(`external`, false);
                                  formikBag.setFieldValue("name", "");
                                  formikBag.setFieldValue("email", "");
                                  formikBag.setFieldValue("countryCode", "");
                                  formikBag.setFieldValue("mobileNumber", "");
                                  formikBag.setFieldValue("position", "");
                                  formikBag.setFieldValue("city", "");
                                  formikBag.setFieldValue("learnerId", "");
                                }}
                              >
                                <img
                                  src={
                                    formikBag.values.internal
                                      ? theme
                                        ? darkCheckRadio
                                        : checkRadio
                                      : theme
                                      ? darkBaseRadio
                                      : baseRadio
                                  }
                                  alt=""
                                />
                              </UE.CheckBoxs>
                              <UE.FeedbackOrangeTextUpl themeColor={theme}>
                                {localState.add_internal_members}
                              </UE.FeedbackOrangeTextUpl>
                            </UE.ComFeedbackRowss>
                            <UE.ComFeedbackRowss>
                              <UE.CheckBoxs
                                onClick={() => {
                                  formikBag.setFieldValue(`external`, true);
                                  formikBag.setFieldValue(`internal`, false);
                                  formikBag.setFieldValue("name", "");
                                  formikBag.setFieldValue("email", "");
                                  formikBag.setFieldValue("countryCode", "");
                                  formikBag.setFieldValue("mobileNumber", "");
                                  formikBag.setFieldValue("position", "");
                                  formikBag.setFieldValue("city", "");
                                  formikBag.setFieldValue("learnerId", "");
                                  setErrorObj({});
                                }}
                              >
                                <img
                                  src={
                                    formikBag.values.external
                                      ? theme
                                        ? darkCheckRadio
                                        : checkRadio
                                      : theme
                                      ? darkBaseRadio
                                      : baseRadio
                                  }
                                  alt=""
                                />
                              </UE.CheckBoxs>
                              <UE.FeedbackOrangeTextUpl themeColor={theme}>
                                {localState.add_external_members}
                              </UE.FeedbackOrangeTextUpl>
                            </UE.ComFeedbackRowss>
                          </UE.InputBoxContainers>
                        </UE.FormContainerBorder>
                        <UE.InputBoxContainer style={{ gap: "1rem" }}>
                          <UE.InputBox style={{ margin: 0 }} wiDth={"48%"}>
                            {formikBag.values.internal ? (
                              <Field name="name">
                                {({ field, form }) => (
                                  <PageSelectTwo
                                    {...field}
                                    label="Name"
                                    value={
                                      formikBag.values.learnerId
                                        ? categoryList?.find(
                                            (val) =>
                                              val.value ===
                                              formikBag.values.learnerId
                                          )
                                        : ""
                                    }
                                    placeholder={localState.type_to_search}
                                    loadOptions={loadCategoryOptions}
                                    onChange={(e) => {
                                      console.log(e, "value");
                                      formikBag.setFieldValue(
                                        "learnerId",
                                        e.value
                                      );
                                      formikBag.setFieldValue("name", e.label);
                                      formikBag.setFieldValue("email", e.email);
                                      formikBag.setFieldValue(
                                        "countryCode",
                                        e.countryCode
                                      );
                                      formikBag.setFieldValue(
                                        "mobileNumber",
                                        e.mobileNumber
                                      );
                                      formikBag.setFieldValue(
                                        "position",
                                        e.position
                                      );
                                      formikBag.setFieldValue("city", e.city);
                                      setErrorObj({});
                                    }}
                                    additional={{
                                      page: 1,
                                    }}
                                    error={
                                      formikBag.touched.name &&
                                      formikBag.errors.name
                                        ? formikBag.errors.name
                                        : null
                                    }
                                    erroros={errorObj.name}
                                  />
                                )}
                              </Field>
                            ) : (
                              <Field name="name">
                                {({ field }) => (
                                  <Input
                                    {...field}
                                    placeholder={localState.name}
                                    label="Name"
                                    value={formikBag.values.name}
                                    onChange={(e) => {
                                      formikBag.setFieldValue(
                                        "name",
                                        e.target.value
                                      );
                                      setErrorObj({});

                                      formikBag.setFieldTouched(
                                        "name",
                                        true,
                                        false
                                      );
                                    }}
                                    error={
                                      formikBag.touched.name &&
                                      formikBag.errors.name
                                        ? formikBag.errors.name
                                        : null
                                    }
                                    erroros={errorObj.name}
                                  />
                                )}
                              </Field>
                            )}
                          </UE.InputBox>
                          <UE.InputBox style={{ margin: 0 }} wiDth={"48%"}>
                            <Field name="email">
                              {({ field }) => (
                                <Input
                                  {...field}
                                  placeholder={localState.email}
                                  label="Email"
                                  value={formikBag.values.email}
                                  onChange={(e) => {
                                    formikBag.setFieldValue(
                                      "email",
                                      e.target.value
                                    );
                                    setErrorObj({});
                                    formikBag.setFieldTouched(
                                      "email",
                                      true,
                                      false
                                    );
                                  }}
                                  error={
                                    formikBag.touched.email &&
                                    formikBag.errors.email
                                      ? formikBag.errors.email
                                      : null
                                  }
                                  erroros={errorObj.email}
                                />
                              )}
                            </Field>
                          </UE.InputBox>
                        </UE.InputBoxContainer>
                        <UE.InputBoxContainer>
                          <UE.InputBox style={{ margin: 0 }} wiDth={"48%"}>
                            <Field name="mobileNumber">
                              {({ field }) => (
                                <InputMobile
                                  {...field}
                                  placeholder={localState.mobile_number}
                                  label="Mobile Number"
                                  icon={true}
                                  type="number"
                                  value={
                                    formikBag.values.countryCode +
                                    formikBag.values.mobileNumber
                                  }
                                  onChange={(phone, data) => {
                                    formikBag.setFieldValue(
                                      `countryCode`,
                                      data?.format?.slice(0, 1) + data.dialCode
                                    );
                                    formikBag.setFieldValue(
                                      `mobileNumber`,
                                      phone?.slice(data.dialCode.length)
                                    );
                                  }}
                                  // cursorNot={formikBag.values.internal}
                                  error={
                                    formikBag.touched.mobileNumber &&
                                    formikBag.errors.mobileNumber
                                      ? formikBag.errors.mobileNumber
                                      : null
                                  }
                                  erroros={errorObj.mobileNumber}
                                />
                              )}
                            </Field>
                          </UE.InputBox>
                          <UE.InputBox style={{ margin: 0 }} wiDth={"48%"}>
                            <Field name="position">
                              {({ field }) => (
                                <Input
                                  {...field}
                                  placeholder={localState.designation}
                                  label="Designation"
                                  value={formikBag.values.position}
                                  onChange={(e) => {
                                    formikBag.setFieldValue(
                                      "position",
                                      e.target.value
                                    );
                                    formikBag.setFieldTouched(
                                      "position",
                                      true,
                                      false
                                    );
                                    setErrorObj({});
                                  }}
                                  // cursorNot={formikBag.values.internal}
                                  error={
                                    formikBag.touched.position &&
                                    formikBag.errors.position
                                      ? formikBag.errors.position
                                      : null
                                  }
                                  erroros={errorObj.position}
                                />
                              )}
                            </Field>
                          </UE.InputBox>
                        </UE.InputBoxContainer>
                        <UE.InputBoxContainer>
                          <UE.InputBox style={{ margin: 0 }} wiDth={"48%"}>
                            <Field name="city">
                              {({ field }) => (
                                <Input
                                  {...field}
                                  placeholder={localState.city}
                                  label="city"
                                  value={formikBag.values.city}
                                  onChange={(e) => {
                                    formikBag.setFieldValue(
                                      "city",
                                      e.target.value
                                    );

                                    formikBag.setFieldTouched(
                                      "city",
                                      true,
                                      false
                                    );
                                    setErrorObj({});
                                  }}
                                  // cursorNot={formikBag.values.internal}
                                  error={
                                    formikBag.touched.city &&
                                    formikBag.errors.city
                                      ? formikBag.errors.city
                                      : null
                                  }
                                  erroros={errorObj.city}
                                />
                              )}
                            </Field>
                          </UE.InputBox>
                        </UE.InputBoxContainer>
                        <UE.InputBoxContainer>
                          <PS.InputLable themeColor={theme}>
                            Upload Profile
                          </PS.InputLable>
                          <UE.InnerBoxs>
                            {/* <PS.ProfieIcon>
                              <img
                                alt=""
                                src={formikBag.values.fileUrl || profileDemo}
                              />
                            </PS.ProfieIcon> */}
                            <PS.UploadProfileBox>
                              <UE.InputBox wiDth={"100%"}>
                                <Field name="file">
                                  {({ field }) => (
                                    <>
                                      <LS.ProfileRow>
                                        <LS.ProfileBox>
                                          {isLoadingUpload ? (
                                            <span>
                                              <i class="fa fa-spinner fa-spin"></i>
                                            </span>
                                          ) : feildLoading ? (
                                            <Skeleton
                                              width={100}
                                              baseColor={skeletonBaseColor}
                                              highlightColor={
                                                skeletonHighlightColor
                                              }
                                              height={100}
                                              style={{
                                                borderRadius: "50%",
                                              }}
                                            />
                                          ) : (
                                            <img
                                              className="profile-picture"
                                              src={
                                                formikBag.values.profileImage ||
                                                profileDemo
                                              }
                                              alt=""
                                            />
                                          )}

                                          <LS.DelIcon
                                            onClick={() => {
                                              formikBag.setFieldValue(
                                                "profileImage",
                                                ""
                                              );
                                            }}
                                            themeColor={theme}
                                          >
                                            <img
                                              src={theme ? darkBin : binTwo}
                                              alt=""
                                            />
                                          </LS.DelIcon>
                                        </LS.ProfileBox>
                                        <LS.ProfileContent themeColor={theme}>
                                          {/* <button>
                                <img src={orangeCloud} alt="" />
                                Upload Photo
                              </button> */}
                                          <UploadTrailerOnButton
                                            type="file"
                                            id="picos"
                                            themeColor={theme}
                                            accept="image/jpeg, image/png, image/gif"
                                            accepTed="image/"
                                            alertMsg={localState.no_csv_found}
                                            description="jpg, png (Max. 5 MB)"
                                            star={false}
                                            onChange={async (e) => {
                                              setIsLoadingUpload(true);
                                              var image = await UploadFile(
                                                e.target.files[0],
                                                setUploadProgress
                                              );
                                              if (image) {
                                                setIsLoadingUpload(false);
                                                formikBag.setFieldValue(
                                                  "profileImage",
                                                  image
                                                );
                                              }
                                              e.target.value = "";
                                            }}
                                          />
                                          {/* <p>SVG, PNG, JPG (max. 800x400px)</p> */}
                                        </LS.ProfileContent>
                                      </LS.ProfileRow>
                                    </>
                                  )}
                                </Field>
                              </UE.InputBox>
                            </PS.UploadProfileBox>
                          </UE.InnerBoxs>
                        </UE.InputBoxContainer>
                        <UE.ButtonGroup
                          themeColor={theme}
                          style={{ margin: "0.5rem 0", padding: "0.8rem 0" }}
                        >
                          <UE.CancelButton
                            style={{ margin: "0.5rem 0" }}
                            themeColor={theme}
                            type="button"
                            onClick={() => {
                              setIsComitForm(false);
                            }}
                          >
                            {localState.cancel}
                          </UE.CancelButton>
                          <UE.SaveButton
                            style={{ margin: "0.5rem 0" }}
                            cursor={isLoading}
                            disabled={isLoading}
                            className="buttonload"
                            type="submit"
                          >
                            {isLoading ? (
                              <i class="fa fa-spinner fa-spin"></i>
                            ) : (
                              localState.save_and_continue
                            )}
                          </UE.SaveButton>
                        </UE.ButtonGroup>
                      </UE.FormContainerBanner>
                    </Form>
                  );
                }}
              </Formik>
            </SEC.InnerSectionsBanner>
          </>
        }
      />
      <PopUp
        isOpen={opensMem}
        onClose={handleCloses}
        maxWidth="400px"
        title={
          <>
            <SEC.WarnIconBox>
              <img src={warnIcon} alt="danger" />
            </SEC.WarnIconBox>
          </>
        }
        content={
          <>
            <SEC.InnerSection themeColor={theme}>
              <SEC.Title style={{ textAlign: "center" }} themeColor={theme}>
                {localState.are_you_sure}!
              </SEC.Title>
              {/* <SEC.TitleDesc themeColor={theme}>
                (Category contains associated content or)
              </SEC.TitleDesc> */}
              <SEC.Subtitle style={{ textAlign: "center" }} themeColor={theme}>
                “
                {
                  localState.deleting_this_member_will_remove_all_associated_data_and_cannot_be_reversed
                }
                ”.
              </SEC.Subtitle>
              <SEC.BtnGroup>
                <SEC.ComButton
                  themeColor={theme}
                  onClick={() => setOpensMem(false)}
                  type="button"
                >
                  {localState.cancel}
                </SEC.ComButton>
                <SEC.ComButton
                  themeColor={theme}
                  color={"#FFFFFF"}
                  borderColor={"#B3B9C6"}
                  backColor={"#E0271A"}
                  onClick={() => {
                    deleteMember(listIdMem);
                  }}
                >
                  {localState.delete}
                </SEC.ComButton>
              </SEC.BtnGroup>
            </SEC.InnerSection>
          </>
        }
      />
    </>
  );
};

export default AddCommittee;
