import React, { useEffect, useRef, useState } from "react";
import * as NS from "../../../styles/NotiStyled";
import * as SEC from "../../../styles/SectionStyled";
import notiIcon from "../../../assets/images/notiIcon.png";
import infoIcon from "../../../assets/images/infoIcon.png";
import darkArrow from "../../../assets/images/darkArrow.png";
import warns from "../../../assets/images/warns.png";
import { useSelector, useDispatch } from "react-redux";
import { ProfileInfo } from "../../../utils/api";
import wanrIcon from "../../../assets/images/warns.png";
import axios from "../../../utils/axios";
import { removeDuplicatesById } from "../../../utils/Helpers";

const Notification = ({
  component,
  headTitle,
  headDesc,
  navigate,
  localState,
}) => {
  const theme = useSelector((state) => state?.isDark);
  const [notificationData, setNotificationData] = useState([]);
  const [currentPageSubs, setCurrentPageSubs] = useState(1);
  const [totalCountSubs, setTotalCountSubs] = useState(0);
  const [feildLoading, setFeildLoading] = useState(false);
  const sentinelRef = useRef();
  useEffect(() => {
    const getNotification = async () => {
      setFeildLoading(true);
      if (currentPageSubs === 1) {
        setNotificationData([]);
      }
      let url = `${ProfileInfo.getNotification}?page=${currentPageSubs}&limit=10`;
      try {
        const { data } = await axios.get(url);
        if (data?.result) {
          setNotificationData((prevList) => [
            ...prevList,
            ...data?.result?.docs,
          ]);
          setTotalCountSubs(data?.result?.totalPages);
        } else if (currentPageSubs === 1) {
          setNotificationData([]);
        }
      } catch (error) {
        console.log(error.response.data.message);
      } finally {
        setFeildLoading(false); // Ensure loading state is reset
      }
    };
    getNotification();
  }, [currentPageSubs]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          currentPageSubs < totalCountSubs &&
          !feildLoading
        ) {
          setCurrentPageSubs((prevPage) => prevPage + 1);
        }
      },
      { threshold: 0.5 } // Adjusted threshold for better triggering
    );

    if (sentinelRef.current) observer.observe(sentinelRef.current);

    return () => observer.disconnect();
  }, [feildLoading, currentPageSubs, totalCountSubs]);

  const getIconType = (e) => {
    if (e === "error") {
      return wanrIcon;
    } else if (e === "info") {
      return infoIcon;
    } else {
      return "";
    }
  };
  return (
    <NS.NavBarNoti themeColor={theme}>
      <SEC.TitleRow themeColor={theme}>
        <SEC.TitleBox themeColor={theme}>
          <img
            src={darkArrow}
            onClick={() => {
              navigate(-1);
            }}
            alt=""
          />
          <SEC.HeadTitle themeColor={theme}>
            {localState.notifications}
          </SEC.HeadTitle>
        </SEC.TitleBox>
      </SEC.TitleRow>
      <NS.NotiListContainer themeColor={theme}>
        <NS.NotiInnerList themeColor={theme}>
          <NS.NotiHeader themeColor={theme}>
            <img src={notiIcon} alt="" />
            {localState.mark_all_as_read}
          </NS.NotiHeader>
          <NS.NotiList themeColor={theme}>
            {notificationData.length > 0 ? (
              removeDuplicatesById(notificationData)?.map((val, ind) => {
                return (
                  <NS.NotiItem themeColor={theme}>
                    <NS.FirstNoti>
                      <NS.ColorCircle></NS.ColorCircle>
                      <NS.IconCircle>
                        <img src={infoIcon} alt="" />
                      </NS.IconCircle>
                      <NS.NotiDesc themeColor={theme}>
                        {localState.you_have_a_new_request}.
                      </NS.NotiDesc>
                    </NS.FirstNoti>
                    <NS.FirstNoti>
                      <NS.NotiView>{localState.view}</NS.NotiView>
                      <NS.DateTime>10:56:42&nbsp;&nbsp;18 Jan 2023</NS.DateTime>
                    </NS.FirstNoti>
                  </NS.NotiItem>
                );
              })
            ) : (
              <NS.NotiItem
                themeColor={theme}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <NS.NotiDesc themeColor={theme}>
                  {localState.no_data}
                </NS.NotiDesc>
              </NS.NotiItem>
            )}

            {/* <NS.NotiItem themeColor={theme}>
              <NS.FirstNoti>
                <NS.ColorCircle></NS.ColorCircle>
                <NS.IconCircle>
                  <img src={warns} alt="" />
                </NS.IconCircle>
                <NS.NotiDesc themeColor={theme}>
                  You have a new request of additional license from Deepika for
                  the WorkSafe Delta.
                </NS.NotiDesc>
              </NS.FirstNoti>
              <NS.FirstNoti>
                <NS.NotiView>View</NS.NotiView>
                <NS.DateTime>10:56:42&nbsp;&nbsp;18 Jan 2023</NS.DateTime>
              </NS.FirstNoti>
            </NS.NotiItem>
            <NS.NotiItem themeColor={theme}>
              <NS.FirstNoti>
                <NS.ColorCircle></NS.ColorCircle>
                <NS.IconCircle>
                  <img src={warns} alt="" />
                </NS.IconCircle>
                <NS.NotiDesc themeColor={theme}>
                  You have a new request of additional license from Deepika for
                  the WorkSafe Delta.
                </NS.NotiDesc>
              </NS.FirstNoti>
              <NS.FirstNoti>
                <NS.DateTime>10:56:42&nbsp;&nbsp;18 Jan 2023</NS.DateTime>
              </NS.FirstNoti>
            </NS.NotiItem>
            <NS.NotiItem themeColor={theme}>
              <NS.FirstNoti>
                <NS.ColorCircle></NS.ColorCircle>
                <NS.IconCircle>
                  <img src={infoIcon} alt="" />
                </NS.IconCircle>
                <NS.NotiDesc themeColor={theme}>
                  You have a new request of additional license from Deepika for
                  the WorkSafe Delta.
                </NS.NotiDesc>
              </NS.FirstNoti>
              <NS.FirstNoti>
                <NS.NotiView>View</NS.NotiView>
                <NS.DateTime>10:56:42&nbsp;&nbsp;18 Jan 2023</NS.DateTime>
              </NS.FirstNoti>
            </NS.NotiItem>
            <NS.NotiItem themeColor={theme}>
              <NS.FirstNoti>
                <NS.ColorCircle></NS.ColorCircle>
                <NS.IconCircle>
                  <img src={warns} alt="" />
                </NS.IconCircle>
                <NS.NotiDesc themeColor={theme}>
                  You have a new request of additional license from Deepika for
                  the WorkSafe Delta.
                </NS.NotiDesc>
              </NS.FirstNoti>
              <NS.FirstNoti>
                <NS.DateTime>10:56:42&nbsp;&nbsp;18 Jan 2023</NS.DateTime>
              </NS.FirstNoti>
            </NS.NotiItem>
            <NS.NotiItem themeColor={theme}>
              <NS.FirstNoti>
                <NS.ColorCircle></NS.ColorCircle>
                <NS.IconCircle>
                  <img src={infoIcon} alt="" />
                </NS.IconCircle>
                <NS.NotiDesc themeColor={theme}>
                  You have a new request of additional license from Deepika for
                  the WorkSafe Delta.
                </NS.NotiDesc>
              </NS.FirstNoti>
              <NS.FirstNoti>
                <NS.NotiView>View</NS.NotiView>
                <NS.DateTime>10:56:42&nbsp;&nbsp;18 Jan 2023</NS.DateTime>
              </NS.FirstNoti>
            </NS.NotiItem>
            <NS.NotiItem themeColor={theme}>
              <NS.FirstNoti>
                <NS.ColorCircle></NS.ColorCircle>
                <NS.IconCircle>
                  <img src={warns} alt="" />
                </NS.IconCircle>
                <NS.NotiDesc themeColor={theme}>
                  You have a new request of additional license from Deepika for
                  the WorkSafe Delta.
                </NS.NotiDesc>
              </NS.FirstNoti>
              <NS.FirstNoti>
                <NS.DateTime>10:56:42&nbsp;&nbsp;18 Jan 2023</NS.DateTime>
              </NS.FirstNoti>
            </NS.NotiItem>
            <NS.NotiItem themeColor={theme}>
              <NS.FirstNoti>
                <NS.ColorCircle></NS.ColorCircle>
                <NS.IconCircle>
                  <img src={infoIcon} alt="" />
                </NS.IconCircle>
                <NS.NotiDesc themeColor={theme}>
                  You have a new request of additional license from Deepika for
                  the WorkSafe Delta.
                </NS.NotiDesc>
              </NS.FirstNoti>
              <NS.FirstNoti>
                <NS.NotiView>View</NS.NotiView>
                <NS.DateTime>10:56:42&nbsp;&nbsp;18 Jan 2023</NS.DateTime>
              </NS.FirstNoti>
            </NS.NotiItem> */}
          </NS.NotiList>
        </NS.NotiInnerList>
      </NS.NotiListContainer>
    </NS.NavBarNoti>
  );
};

export default Notification;
