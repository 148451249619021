import React, { useState } from "react";
import styled from "styled-components/macro";
import errorImage from "../assets/images/error.png";
import downArrowIcon from "../assets/images/downArrow.png";
import uploadIcon from "../assets/images/upload_trailer.png";
import darkVideoIcon from "../assets/images/darkVideoIcon.png";
import { useSelector } from "react-redux";
import Select from "react-select";
import "./SelectStyle.css";

const InputBorder = styled.div`
  border: ${(props) =>
    props.error
      ? "1px solid red"
      : props.themeColor
      ? "1px solid #333741"
      : "1px solid #D7DAE0"};
  border-radius: 8px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  padding: 1.6rem 0px;
  /* border-radius: 8px; */
  width: 100%;
  display: flex;
  cursor: pointer;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};

  img {
    height: 18px;
    width: 18px;
  }

  input {
    font: 1.1rem;
  }
  input::placeholder {
    font-size: 0.8rem !important;
    color: ${(props) => (props.themeColor ? "#94969C" : "#8A94A6")};
  }
`;
export const InputBox = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.7rem;
  position: relative;
`;
export const InputLable = styled.label`
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 0.3rem;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
  margin-bottom: 0.5rem;
  text-transform: capitalize;

  span {
    font-weight: 400;
  }
  @media (max-width: 351px) {
    font-size: 0.8rem;
  }
`;
export const InBox = styled.div`
  display: flex;
  color: ${(props) => (props.themeColor ? "#94969C" : "#8A94A6")};
  font-size: 0.8rem;
  align-items: center;
  width: 10%;

  img {
    height: 9px;
    width: 9px;
    object-fit: contain;
    margin-left: 4px;
  }
`;
export const IconBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 45px;
    height: 45px;
    object-fit: contain;
  }
`;
export const UploadText = styled.label`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 400;
  color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
  margin: 0.4rem 0;
  text-transform: capitalize;

  span {
    color: ${(props) => (props.themeColor ? "#CECFD2" : "#f6591c")};
    margin-right: 5px;
    font-weight: 500;
  }
`;
export const UploadDesc = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
  font-size: 0.7rem;
  text-align: center;
`;
export default function Input(props) {
  const {
    error,
    type,
    icon,
    noBorderBottom,
    label,
    star,
    optional,
    description,
    accepTed,
    other,
    others,
    alertMsg,
    upIcon,
    erroros,
    accept
  } = props;
  const theme = useSelector((state) => state?.isDark);
  const uploadRef = React.useRef();
  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    if (e?.dataTransfer?.files && e.dataServiceTransfer?.files?.length > 0) {
      const files = Array?.from(e?.dataTransfer?.files);
  
      // Filter files based on the 'accept' attribute
      const validFiles = files?.filter((file) => accept?.includes(file.type));
  
      if (validFiles?.length > 0) {
        props.onChange({ target: { files: validFiles } });
      } else {
        alert("The file type is not supported. Please upload a CSV, Excel sheet, or PDF.");
      }
  
      e.dataTransfer.clearData();
    }
  };
  return (
    <>
      <InputBox htmlFor="pic">
        <InputLable themeColor={theme}>
          {label}
          {star ? <span style={{ color: "#F6591C" }}>*</span> : ""}
          {optional ? <span style={{ color: "#667085" }}> (upto 5)</span> : ""}
        </InputLable>
        <InputBorder
          className={isDragging ? "drag-over" : ""}
          error={error || erroros}
          themeColor={theme}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          style={{ backgroundColor: isDragging ? "lightgrey" : "" }}
        >
          {icon ? (
            <InBox themeColor={theme}>
              IN <img src={downArrowIcon} alt="" />
            </InBox>
          ) : (
            ""
          )}
          <IconBox>
            <img
              src={upIcon ? upIcon : theme ? darkVideoIcon : uploadIcon}
              alt=""
            />
          </IconBox>
          <UploadText themeColor={theme}>
            <span>Click to upload </span> or drag and drop
          </UploadText>
          <UploadDesc themeColor={theme}>{description}</UploadDesc>
          <input {...props} />
          {/* <button
            disabled={!chunkBtnStatus}
            onClick={UploadFile}
            style={{ justifyContent: "center" }}
          >
            Submit
          </button> */}
        </InputBorder>
        {error || erroros ? (
          <p
            style={{
              paddingTop: 5,
              fontSize: 11,
              color: "red",
              textAlign: "left",
            }}
          >
            {error || erroros}
          </p>
        ) : null}
      </InputBox>
    </>
  );
}
