import {
  USER_DATA,
  IS_DARK,
  IS_TOGGLE,
  ACCESS_MODULE,
  SIDEBAR_TOGGLE,
  DELETE_LENGTH,
  SIDEBAR_TOGGLE_RESP,
  IS_MODAL,
  ACCESS_TOKEN,
  setCourseId,
  COURSE_ID,
  CLIENT_ID,
  CLIENT_VIEW_ID,
  ERROROBJ,
  ERRORPOPMSG,
  LANGUAGEID,
  SELECTLANG,
  SELECTLANGJSON,
  LANGUAGES,
  PROFILEUPDATE,
  PROFILEDATA,
  PROFILEDETAIL,
} from "./actionTypes";
import englishLang from "../utils/Language/english.json";
let data = JSON.parse(localStorage.getItem("rainClientData"));
let userDataClient =
  JSON.parse(localStorage.getItem("rainClientUserData")) || {};

let userDataDetail =
  JSON.parse(localStorage.getItem("rainClientUserDetail")) || {};
let theme = localStorage.rainClientTheme;
let access_token = localStorage.rainClientToken?.toString();
let rainCourseId = localStorage.rainCourseId;
let rainClientId = localStorage.rainClientId;
let rainCourseViewId = JSON.parse(localStorage.getItem("rainCourseViewId"));
let languages = JSON.parse(localStorage.getItem("clientLanguagesObj")) || [];
let rainClientLang = window.localStorage.getItem("rainClientLang");

if (access_token) {
  console.log("Token as string:", access_token);
} else {
  console.log("No token found or token is undefined");
}

const initialState = {
  userData: data,
  isDark: theme,
  accessModule: data?.data?.permissions,
  isSidebarToggle: false,
  deleteLength: [],
  sidebarIsOpen: false,
  isModal: false,
  access_token: access_token,
  courseId: rainCourseId,
  clientId: rainClientId,
  courseViewId: rainCourseViewId,
  errorPop: false,
  errorPopMsg: null,
  languageId: "",
  selectLang: rainClientLang,
  selectLangJson: {},
  languages: languages,
  profileUpdate: 1,
  profileData: userDataClient,
  profileDetail: userDataDetail,
};

const customReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case USER_DATA:
      return {
        ...state,
        userData: data,
      };
    case IS_DARK:
      return {
        ...state,
        isDark: data,
      };
    case IS_TOGGLE:
      return {
        ...state,
        isToggle: data,
      };
    case ACCESS_MODULE:
      return {
        ...state,
        accessModule: data,
      };
    case SIDEBAR_TOGGLE:
      return {
        ...state,
        isSidebarToggle: data,
      };
    case DELETE_LENGTH:
      return {
        ...state,
        deleteLength: data,
      };
    case SIDEBAR_TOGGLE_RESP:
      return {
        ...state,
        sidebarIsOpen: data,
      };
    case IS_MODAL:
      return {
        ...state,
        isModal: data,
      };
    case ACCESS_TOKEN:
      return {
        ...state,
        access_token: data,
      };
    case CLIENT_ID:
      return {
        ...state,
        clientId: data,
      };
    case COURSE_ID:
      return {
        ...state,
        courseId: data,
      };
    case CLIENT_VIEW_ID:
      return {
        ...state,
        courseViewId: data,
      };
    case ERROROBJ:
      return {
        ...state,
        errorPop: data,
      };
    case ERRORPOPMSG:
      return {
        ...state,
        errorPopMsg: data,
      };
    case LANGUAGEID:
      return {
        ...state,
        languageId: data,
      };
    case SELECTLANG:
      return {
        ...state,
        selectLang: data,
      };
    case SELECTLANGJSON:
      return {
        ...state,
        selectLangJson: data,
      };
    case LANGUAGES:
      return {
        ...state,
        languages: data,
      };
    case PROFILEUPDATE:
      return {
        ...state,
        profileUpdate: data,
      };
    case PROFILEDATA:
      return {
        ...state,
        profileData: data,
      };
    case PROFILEDETAIL:
      return {
        ...state,
        profileData: data,
      };
    default:
      return state;
  }
};

export default customReducer;
