import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import "./video.css";

const VideoMedia = (props) => {
  const playerRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [progress, setProgress] = useState(0);
  const [videoDur, setVideoDur] = useState(0);
  const [isPaused, setIsPaused] = useState(null);

  const { handleCurrentTime, timing, timeArr, blurTime, videoURL } = props;

  useEffect(() => {
    // Update current time every second
    const interval = setInterval(() => {
      if (playerRef.current && playerRef.current.getCurrentTime) {
        const currentTime = playerRef.current.getCurrentTime();
        // setCurrentTime(currentTime);
      }
    }, 1000);

    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    console.log("First move");
    if (timeArr?.includes(Math.floor(currentTime?.toFixed()))) {
      // setIsPaused(true); // Pause the video when the marker time is reached
      console.log("second move");
      // handleCurrentTime(currentTime + 1);
    }
  }, [currentTime]);

  return (
    <div
      style={{
        backgroundColor: "black",
        overflow: "hidden",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <ReactPlayer
        url={videoURL}
        controls
        width={800}
        height={450}
        style={{ borderRadius: "12px" }}
        loop={false}
        onReady={() => {
          console.log("Player is ready");
          // Add your actions here
        }}
        playing={!isPaused}
        config={{
          file: {
            attributes: {
              controlsList: "nodownload", // Disable download button
            },
          },
        }}
        onProgress={(e) => setProgress((e.played * 100) / e.playedSeconds)}
        onDuration={(duration) => setVideoDur(duration)}
        // onSeek={handleSeek}
        ref={playerRef}
      />
      {/* <button onClick={handleGetCurrentTime}>Get Current Time</button> */}
      <div
        style={{
          width: "95.3%",
          height: "10px",
          backgroundColor: "transparant",
          position: "relative",
        }}
      >
        {/* {timeArr?.map((time, index) => (
          <div
            key={index}
            className="custom-marker"
            style={{
              left: `${(time / videoDur) * 100}%`,
            }}
            onClick={() => handleMarkerClick(time)}
          />
        ))} */}
      </div>
    </div>
  );
};

export default VideoMedia;
