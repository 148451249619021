import React, { useState, Suspense, useEffect, lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { publicRoutes, privateRoutes } from "./routes";
import { useSelector, useDispatch } from "react-redux";
import englishLang from "../src/utils/Language/english.json";
import * as CS from "./styles/CommonStyled";
import * as LE from "./styles/LoginStyled";
import Navbar from "./components/Navbar/Navbar";
import { RMlogin } from "./utils/api";
import bannerSample from "./assets/images/bannerSample.png";
import axios from "./utils/axios";
import Sidebar from "./components/Sidebar/Sidebar";
import backVideo from "./assets/images/backVideo.mp4";
import { setErrorPop, setSelectLangJson } from "./store/action";
import { Modal } from "./components/PopUp/Modal";
import * as SEC from "./styles/SectionStyled";
import warn from "./assets/images/warns.png";
import CourseDetail from "./pages/private/CourseDetail/CourseDetail";
import CourseContent from "./pages/private/CourseDetail/CourseContent";
import ManageLearners from "./pages/private/CourseDetail/ManageLearners";
import PolicyDocument from "./pages/private/CourseDetail/PolicyDocument";
import ReqAdditional from "./pages/private/CourseDetail/ReqAdditional";
import Renew from "./pages/private/CourseDetail/Renew";
import Committee from "./pages/private/CourseDetail/Committee";
import AddLearner from "./pages/private/CourseDetail/AddLearner";
import LearnerView from "./pages/private/CourseDetail/LearnerView";
import AddPolicy from "./pages/private/CourseDetail/AddPolicy";
import AddCommittee from "./pages/private/CourseDetail/AddCommittee";
const Reports = lazy(() => import("./pages/private/Reports"));
const CourseReports = lazy(() =>
  import("./pages/private/Reports/SubReports/CourseReports")
);
const ClientReports = lazy(() =>
  import("./pages/private/Reports/SubReports/ClientReports")
);
const AgreementDateReports = lazy(() =>
  import("./pages/private/Reports/SubReports/AgreementDateReports")
);
const IndustryReports = lazy(() =>
  import("./pages/private/Reports/SubReports/IndustryReports")
);
const RollsReports = lazy(() =>
  import("./pages/private/Reports/SubReports/RollsReports")
);
const ProgressReports = lazy(() =>
  import("./pages/private/Reports/SubReports/ProgressReports")
);
const AuditReports = lazy(() =>
  import("./pages/private/Reports/SubReports/AuditReports")
);
const FeedbackReports = lazy(() =>
  import("./pages/private/Reports/SubReports/FeedbackReports")
);
const AddandLearnerDeleteReports = lazy(() =>
  import("./pages/private/Reports/SubReports/AddandLearnerDeleteReports")
);
const MasterReport = lazy(() => import("./pages/private/Reports/SubReports/MasterReports"));
const EntryReports = lazy(() => import("./pages/private/Reports/SubReports/EntryReports"));

const Feedback = lazy(() => import("./pages/private/Feedback/Feedback"));
const CoursesDetailComp = lazy(() =>
  import("./pages/private/CourseDetail/CoursesDetailComp")
);

const PublicRoute = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state?.isDark);
  const [bannerData, setBannerData] = useState([]);
  const getBanner = async () => {
    let url = RMlogin.getBanner;
    try {
      const { data } = await axios.get(url);
      setBannerData(data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBanner();
  }, []);
  return (
    <Routes>
      {publicRoutes.map((val, ind) => {
        return (
          <Route
            exact
            key={ind}
            path={val.path}
            element={
              <Suspense>
                <CS.MainContainer>
                  <CS.InnerContainer>
                    <CS.BackContainer>
                      <video
                        className="video-background"
                        width="320"
                        height="240"
                        autoPlay
                        loop
                        muted
                      >
                        <source src={backVideo} type="video/mp4" />
                      </video>
                      <val.component
                        navigate={navigate}
                        location={location}
                        dispatch={dispatch}
                      />
                      <LE.BannerImageBox>
                        <LE.BannerTitle>
                          {bannerData?.showTextStatus
                            ? `“${bannerData?.showText}”`
                            : ""}
                        </LE.BannerTitle>
                        {bannerData?.showBannerImageStatus ? (
                          <LE.BannerImage
                            href={bannerData?.bannerLink}
                            target="_blank"
                          >
                            <img src={bannerData?.bannerImage} alt="" />
                          </LE.BannerImage>
                        ) : (
                          ""
                        )}
                      </LE.BannerImageBox>
                    </CS.BackContainer>
                  </CS.InnerContainer>
                </CS.MainContainer>
              </Suspense>
            }
          />
        );
      })}
      <Route
        exact
        path="/client-admin/client-survey-form"
        element={<Feedback />}
      ></Route>

      <Route path="/*" element={<Navigate to="/client-admin" />} />
    </Routes>
  );
};
const PrivateRoute = ({ accessToken }) => {
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const state = useSelector((state) => state?.userData);
  const theme = useSelector((state) => state?.isDark);
  const courseId = useSelector((state) => state?.courseId);
  const errorPop = useSelector((state) => state?.errorPop);
  const errorPopMsg = useSelector((state) => state?.errorPopMsg);
  const selectLang = useSelector((state) => state?.selectLang);
  const handleClose = () => {
    dispatch(setErrorPop(false));
  };
  console.log(state, "state statestate statestate");
  const [localState, setLocalState] = useState(englishLang?.client_admin);
  console.log(localState, "localState localState localState");

  console.log(selectLang, "selectLang selectLang");
  const languages = useSelector((state) => state?.languages);
  console.log(languages, "languages languages");

  useEffect(() => {
    if (selectLang && languages) {
      let filterLangJson = languages?.find((val) => val._id === selectLang);
      if (filterLangJson) {
        dispatch(
          setSelectLangJson(JSON.parse(filterLangJson?.acexcel_to_json))
        );
      }
    }
  }, [selectLang, languages]);
  return (
    <>
      <Routes>
        {privateRoutes.map((val, ind) => {
          return (
            <Route
              exact
              key={ind}
              path={val.path}
              element={
                <Suspense>
                  <CS.MainContainer themeColor={theme} isHeight={true}>
                    <CS.InnerContainer themeColor={theme}>
                      <Navbar
                        stateData={state}
                        theme={theme}
                        navigate={navigate}
                        dispatch={dispatch}
                        localState={localState}
                      />
                      <CS.ComContainer themeColor={theme}>
                        <val.component
                          stateData={state}
                          navigate={navigate}
                          location={location}
                          dispatch={dispatch}
                          theme={theme}
                          localState={localState}
                          accessToken={accessToken}
                          courseId={courseId}
                        />
                      </CS.ComContainer>
                    </CS.InnerContainer>
                  </CS.MainContainer>
                </Suspense>
              }
            />
          );
        })}
        <Route
          exact
          path="/client-admin/courses-manage"
          element={
            <Suspense>
              <CS.MainContainer themeColor={theme} isHeight={true}>
                <CS.InnerContainer themeColor={theme}>
                  <Navbar
                    stateData={state}
                    theme={theme}
                    navigate={navigate}
                    dispatch={dispatch}
                    localState={localState}
                  />
                  <CS.ComContainer themeColor={theme}>
                    <CoursesDetailComp
                      stateData={state}
                      navigate={navigate}
                      location={location}
                      dispatch={dispatch}
                      theme={theme}
                      localState={localState}
                      accessToken={accessToken}
                      courseId={courseId}
                    />
                  </CS.ComContainer>
                </CS.InnerContainer>
              </CS.MainContainer>
            </Suspense>
          }
        >
          <Route index element={<Navigate to="courses-content" />} />
          <Route
            path="courses-detail"
            element={
              <CourseDetail
                stateData={state}
                navigate={navigate}
                location={location}
                dispatch={dispatch}
                theme={theme}
                localState={localState}
                accessToken={accessToken}
                courseId={courseId}
              />
            }
          />
          <Route
            path="courses-content"
            element={
              <CourseContent
                stateData={state}
                navigate={navigate}
                location={location}
                dispatch={dispatch}
                theme={theme}
                localState={localState}
                accessToken={accessToken}
                courseId={courseId}
              />
            }
          />
          <Route
            path="manage-learners"
            element={
              <ManageLearners
                stateData={state}
                navigate={navigate}
                location={location}
                dispatch={dispatch}
                theme={theme}
                localState={localState}
                accessToken={accessToken}
                courseId={courseId}
              />
            }
          />
          <Route
            path="policy-document"
            element={
              <PolicyDocument
                stateData={state}
                navigate={navigate}
                location={location}
                dispatch={dispatch}
                theme={theme}
                localState={localState}
                accessToken={accessToken}
                courseId={courseId}
              />
            }
          />
          <Route
            path="request-additional-learners"
            element={
              <ReqAdditional
                stateData={state}
                navigate={navigate}
                location={location}
                dispatch={dispatch}
                theme={theme}
                localState={localState}
                accessToken={accessToken}
                courseId={courseId}
              />
            }
          />
          <Route
            path="renew"
            element={
              <Renew
                stateData={state}
                navigate={navigate}
                location={location}
                dispatch={dispatch}
                theme={theme}
                localState={localState}
                accessToken={accessToken}
                courseId={courseId}
              />
            }
          />
          <Route
            path="committee"
            element={
              <Committee
                stateData={state}
                navigate={navigate}
                location={location}
                dispatch={dispatch}
                theme={theme}
                localState={localState}
                accessToken={accessToken}
                courseId={courseId}
              />
            }
          />
          <Route
            path="add-learner"
            element={
              <AddLearner
                stateData={state}
                navigate={navigate}
                location={location}
                dispatch={dispatch}
                theme={theme}
                localState={localState}
                accessToken={accessToken}
                courseId={courseId}
              />
            }
          />
          <Route
            path="learner-view"
            element={
              <LearnerView
                stateData={state}
                navigate={navigate}
                location={location}
                dispatch={dispatch}
                theme={theme}
                localState={localState}
                accessToken={accessToken}
                courseId={courseId}
              />
            }
          />
          <Route
            path="add-policy"
            element={
              <AddPolicy
                stateData={state}
                navigate={navigate}
                location={location}
                dispatch={dispatch}
                theme={theme}
                localState={localState}
                accessToken={accessToken}
                courseId={courseId}
              />
            }
          />
          <Route
            path="add-committee"
            element={
              <AddCommittee
                stateData={state}
                navigate={navigate}
                location={location}
                dispatch={dispatch}
                theme={theme}
                localState={localState}
                accessToken={accessToken}
                courseId={courseId}
              />
            }
          />
        </Route>
        <Route
          exact
          path="/client-admin/reports"
          element={
            <Suspense>
              <CS.MainContainer themeColor={theme} isHeight={true}>
                <CS.InnerContainer themeColor={theme}>
                  <Navbar
                    stateData={state}
                    theme={theme}
                    navigate={navigate}
                    dispatch={dispatch}
                    localState={localState}
                  />
                  <CS.ComContainer themeColor={theme}>
                    <Reports
                      stateData={state}
                      navigate={navigate}
                      location={location}
                      dispatch={dispatch}
                      theme={theme}
                      localState={localState}
                      accessToken={accessToken}
                      courseId={courseId}
                    />
                  </CS.ComContainer>
                </CS.InnerContainer>
              </CS.MainContainer>
            </Suspense>
          }
        >
          <Route index element={<Navigate to="master-reports" />} />
          <Route
            path="master-reports"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <MasterReport
                  stateData={state}
                  navigate={navigate}
                  location={location}
                  dispatch={dispatch}
                  theme={theme}
                  localState={localState}
                  accessToken={accessToken}
                  courseId={courseId}
                />
              </Suspense>
            }
          />
          <Route
            path="entry-reports"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <EntryReports
                  stateData={state}
                  navigate={navigate}
                  location={location}
                  dispatch={dispatch}
                  theme={theme}
                  localState={localState}
                  accessToken={accessToken}
                  courseId={courseId}
                />
              </Suspense>
            }
          />
          <Route
            path="course-reports"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <CourseReports
                  stateData={state}
                  navigate={navigate}
                  location={location}
                  dispatch={dispatch}
                  theme={theme}
                  localState={localState}
                  accessToken={accessToken}
                  courseId={courseId}
                />
              </Suspense>
            }
          />
          <Route
            path="client-reports"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <ClientReports
                  stateData={state}
                  navigate={navigate}
                  location={location}
                  dispatch={dispatch}
                  theme={theme}
                  localState={localState}
                  accessToken={accessToken}
                  courseId={courseId}
                />
              </Suspense>
            }
          />
          <Route
            path="agreement-reports"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <AgreementDateReports
                  stateData={state}
                  navigate={navigate}
                  location={location}
                  dispatch={dispatch}
                  theme={theme}
                  localState={localState}
                  accessToken={accessToken}
                  courseId={courseId}
                />
              </Suspense>
            }
          />
          <Route
            path="industry-reports"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <IndustryReports
                  stateData={state}
                  navigate={navigate}
                  location={location}
                  dispatch={dispatch}
                  theme={theme}
                  localState={localState}
                  accessToken={accessToken}
                  courseId={courseId}
                />
              </Suspense>
            }
          />
          <Route
            path="roll-out-reports"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <RollsReports
                  stateData={state}
                  navigate={navigate}
                  location={location}
                  dispatch={dispatch}
                  theme={theme}
                  localState={localState}
                  accessToken={accessToken}
                  courseId={courseId}
                />
              </Suspense>
            }
          />
          <Route
            path="progress-reports"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <ProgressReports
                  stateData={state}
                  navigate={navigate}
                  location={location}
                  dispatch={dispatch}
                  theme={theme}
                  localState={localState}
                  accessToken={accessToken}
                  courseId={courseId}
                />
              </Suspense>
            }
          />
          <Route
            path="audit-reports"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <AuditReports
                  stateData={state}
                  navigate={navigate}
                  location={location}
                  dispatch={dispatch}
                  theme={theme}
                  localState={localState}
                  accessToken={accessToken}
                  courseId={courseId}
                />
              </Suspense>
            }
          />
          <Route
            path="feedback-reports"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <FeedbackReports
                  stateData={state}
                  navigate={navigate}
                  location={location}
                  dispatch={dispatch}
                  theme={theme}
                  localState={localState}
                  accessToken={accessToken}
                  courseId={courseId}
                />
              </Suspense>
            }
          />
          <Route
            path="add-delete-learner-reports"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <AddandLearnerDeleteReports
                  stateData={state}
                  navigate={navigate}
                  location={location}
                  dispatch={dispatch}
                  theme={theme}
                  localState={localState}
                  accessToken={accessToken}
                  courseId={courseId}
                />
              </Suspense>
            }
          />
        </Route>
        <Route path="/*" element={<Navigate to="/client-admin/dashboard" />} />
      </Routes>
      <Modal
        isOpen={errorPop}
        onClose={handleClose}
        maxWidth="400px"
        title={
          <>
            <SEC.SuccessIconBoxSo>
              <img src={theme ? warn : warn} alt="danger" />
            </SEC.SuccessIconBoxSo>
          </>
        }
        content={
          <SEC.InnerSectionSuccess>
            <SEC.TitleSo themeColor={theme}>Warning!</SEC.TitleSo>
            {console.log(errorPopMsg, "msg")}
            <SEC.Subtitle themeColor={theme}>{errorPopMsg}</SEC.Subtitle>
            <SEC.BtnClose
              style={{ width: "100%", margin: "1rem 0" }}
              themeColor={theme}
              color={"#FFFFFF"}
              borderColor={"#B3B9C6"}
              backColor={"#E0271A"}
              type="primary"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </SEC.BtnClose>
          </SEC.InnerSectionSuccess>
        }
      />
    </>
  );
};

const App = () => {
  const state = useSelector((state) => state?.userData);
  const access_token = useSelector((state) => state?.access_token);
  return (
    <CS.ParentBox>
      {state ? (
        <PrivateRoute stateData={state} accessToken={access_token} />
      ) : (
        <PublicRoute />
      )}
    </CS.ParentBox>
    // <CS.ParentBox><PrivateRoute /></CS.ParentBox>
  );
};

export default App;
