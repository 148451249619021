import styled from "styled-components/macro";
import {
  commonStyles,
  colorProvider,
  fontProvider,
  fontWeight,
} from "../utils/StylePropertiy";

export const ReqTitle = styled.div`
  width: ${commonStyles.wid};
  text-align: ${commonStyles.jc_l};
  font-size: ${fontProvider.zeroTen};
  margin-top: ${fontProvider.two};
  font-weight: ${fontWeight.six};
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
  border-bottom: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #d7dae0"};
  padding-bottom: ${fontProvider.zeroTen};
  text-transform: capitalize;
`;
