import React from "react";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector } from "react-redux";
import "./modal.css";
const useStyles = makeStyles({
  dialogPadding: {},
});

const noop = () => {};
export const Modal = ({
  isOpen = false,
  title = "title",
  content = "content",
  onClose = noop,
  maxWidth,
  width = "100%",
  setShowModal,
}) => {
  const classes = useStyles();
  const theme = useSelector((state) => state?.isDark);
  return (
    <>
      {isOpen ? (
        <Dialog
          open={isOpen}
          onClose={onClose}
          className={theme ? "dark-modal" : "light-modal"}
          maxWidth={maxWidth}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className={classes.dialogPadding} style={{ width: width }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{content}</DialogContent>
          </div>
        </Dialog>
      ) : null}
    </>
  );
};
