import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as HS from "../../styles/HeaderStyled";
import logoImage from "../../assets/images/rainLogo.png";
import downIcon from "../../assets/images/downIcon.png";
import searchIcon from "../../assets/images/searchIcon.png";
import * as LS from "../../styles/LearnerStyled";
import langIcon from "../../assets/images/langIcon.png";
import bellIcon from "../../assets/images/bellIcon.png";
import sunIcon from "../../assets/images/sunIcon.png";
import hills from "../../assets/images/hills.png";
import Avatar from "../../assets/images/Avatar.png";
import lightIcon from "../../assets/images/light.png";
import darkIcon from "../../assets/images/dark.png";
import {
  setIsDark,
  setSidebarIsOpens,
  setUserData,
  setSelectLang,
  setSelectLangJson,
  setProfileData,
  setProfileDetail,
} from "../../store/action";
import darkLogo from "../../assets/images/rainLogoDark.png";
import darkSearch from "../../assets/images/darkSearch.png";
import darkLang from "../../assets/images/darkLang.png";
import darkBell from "../../assets/images/darkBell.png";
import darkDonwArrow from "../../assets/images/darkDonwArrow.png";
import drawer from "../../assets/images/drawer.png";
import darkDrawer from "../../assets/images/darkDrawer.png";
import AvatarIcon from "../../assets/images/Avatar.png";
import mainLogo from "../../assets/images/rainLogo.png";
import crossBig from "../../assets/images/crossBig.png";
import selectIcon from "../../assets/images/selectIcon.png";
import accountIcon from "../../assets/images/accountIcon.png";
import logoutIconGrey from "../../assets/images/logoutIconGrey.png";
import infoIcon from "../../assets/images/infoIcon.png";
import switchIcon from "../../assets/images/switch-user.png";
import warns from "../../assets/images/warns.png";
import darkMoon from "../../assets/images/darkMoon.png";
import Switch from "../../components/Switch/Switch";
import { get } from "lodash";
import SelectQues from "../../components/SelectQues";
import englishLang from "../../utils/Language/english.json";
import hindiLang from "../../utils/Language/hindi.json";
import kannadaLang from "../../utils/Language/kannada.json";
import { ProfileInfo } from "../../utils/api";
import axios from "../../utils/axios";
import profileDemo from "../../assets/images/profileDemo.png";
import squareImage from "../../assets/images/squareImage.png";
import moment from "moment/moment";

const quesOptions = [
  { value: "single_choice", label: "Single Choice" },
  { value: "descriptive", label: "Descriptive" },
  { value: "multiple_choice", label: "Multiple Choice" },
];
const Navbar = ({ dispatch, theme, navigate, stateData, localState }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [navbarData, setNavbarData] = useState(
    get(stateData, "data.client_info")
  );
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  const languages = useSelector((state) => state?.languages);
  const [languageData, setLanguageData] = useState(
    get(stateData, "data.client_info.language", [])?.map((val) => {
      return {
        label: val.title,
        value: val._id,
        isDefault: val.isDefault,
      };
    })
  );

  const profileUpdate = useSelector((state) => state?.profileUpdate);
  const profileData = useSelector((state) => state?.profileData);
  const profileDetail = useSelector((state) => state.profileDetail);
  const selectLang = useSelector((state) => state?.selectLang);

  console.log(profileData, "profileData profileData profileData");
  const [defaultLang, setDefaultLang] = useState(selectLang);
  useEffect(() => {
    if (languageData?.length > 0) {
      let selectedLang = languageData?.find((val) => val._id === defaultLang);
      setDefaultLang(selectedLang);
    }
  }, [languageData]);

  console.log(selectLang, "selectLang selectLang");
  console.log(defaultLang, "defaultLang defaultLang");

  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [isRedBox, setIsRedBox] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [loginValues, setLoginValues] = useState(profileData);
  useEffect(() => {
    // Initialize theme from local storage if available
    const savedTheme = localStorage.getItem("rainTheme");
    if (savedTheme !== null) {
      dispatch(setIsDark(savedTheme === "true")); // Convert the string to a boolean
    }
  }, [dispatch]);
  // console.log(languageData, "languageData");
  const screenWidth = window.innerWidth;
  const profileRef = useRef(null); // Create a ref for the profile menu
  const notificationRef = useRef(null); // Create a ref for the profile menu
  const handleThemeChange = () => {
    const newTheme = !theme;
    localStorage.setItem("rainTheme", newTheme); // Save theme to local storage
    dispatch(setIsDark(newTheme));
  };
  const [innerwidth, setInnerWidth] = useState(window.innerWidth);
  const [langOptions, setLangOptions] = useState(
    languages?.map((val) => {
      return {
        label: val.title,
        value: val._id,
      };
    })
  );
  const handleChangeLanguage = (selectedOption) => {
    setSelectedLanguage(selectedOption.value);
    dispatch(setSelectLang(selectedOption.value)); // Assuming this action updates the language
    setDefaultLang(selectedOption.value);
    window.localStorage.setItem("rainClientLang", selectedOption.value);
  };

  const handleConfirmChangeLanguage = () => {
    // Execute the language change logic
    // Close the modal
  };
  useEffect(() => {
    function handleResize() {
      setInnerWidth(window.innerWidth);
      // console.log("Screen size changed. New width: ", window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleSidebarChange = () => {
    dispatch(setSidebarIsOpens(true));
  };
  const pathName = [
    "/client-admin/courses",
    "/client-admin/courses-manage/courses-content",
    "/client-admin/courses-manage/courses-detail",
    "/client-admin/courses-manage/manage-learners",
    "/client-admin/courses-manage/learner-view",
    "/client-admin/courses-manage/add-learner",
    "/client-admin/courses-manage/policy-document",
    "/client-admin/courses-manage/add-policy",
    "/client-admin/courses-manage/request-additional-learners",
    "/client-admin/courses-manage/renew",
    "/client-admin/courses-manage/committee",
    "/client-admin/courses-manage/add-committee",
  ];
  const reportPathName = [
    "/client-admin/reports/master-reports",
    "/client-admin/reports/course-reports",
    "/client-admin/reports/client-reports",
    "/client-admin/reports/agreement-reports",
    "/client-admin/reports/industry-reports",
    "/client-admin/reports/roll-out-reports",
    "/client-admin/reports/progress-reports",
    "/client-admin/reports/audit-reports",
    "/client-admin/reports/entry-reports"
  ];
  const handleConfirm = () => {
    localStorage.removeItem("rainDataToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("rainClientUserData");
    localStorage.clear();
    dispatch(setUserData(""));
    dispatch(setIsDark(true));
    navigate("/");
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setIsProfileOpen(false);
      }
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setIsNotificationOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileRef]);

  const getProfileDetails = async () => {
    try {
      let url = ProfileInfo.getProfileDetails;
      const { data } = await axios.get(url);
      if (data && data.data) {
        const { firstName, lastName, email, profileImage } = data.data;
        const userData = {
          firstName: firstName || "",
          lastName: lastName || "",
          email: email || "",
          profileImage: profileImage || "",
        };
        setLoginValues(userData);
        dispatch(setProfileData(userData));
        localStorage.setItem("rainClientUserData", JSON.stringify(userData));
      } else {
        setLoginValues({
          firstName: "",
          lastName: "",
          email: "",
          profileImage: "",
        });
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const getProfileDetailsTwo = async () => {
    try {
      let url = ProfileInfo.getCompanyDetails;
      const { data } = await axios.get(url);
      localStorage.setItem(
        "rainClientUserDetail",
        JSON.stringify(data?.data[0])
      );
      dispatch(setProfileDetail(data?.data[0]));
      // setDetailData(data?.data[0]);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  const getNotification = async () => {
    let url = `${ProfileInfo.getNotification}?page=1&limit=6`;
    try {
      const { data } = await axios.get(url);
      if (data?.result) {
        setNotificationData(data?.result?.docs);
        const hasReadMark = data?.result?.docs?.some((val) => val.readMark);
        setIsRedBox(hasReadMark);
        setIsNotificationOpen(!isNotificationOpen);
      } else {
        setNotificationData([]);

        setIsNotificationOpen(!isNotificationOpen);
      }
    } catch (error) {
      console.log(error.response.data.message);
    } finally {
    }
  };
  useEffect(() => {
    if (
      !profileData ||
      Object.keys(profileData)?.length === 0 ||
      !profileUpdate
    ) {
      getProfileDetails();
    }
  }, [profileData]);
  // useEffect(() => {
  //   if (isNotificationOpen) {
  //     getNotification();
  //   }
  // }, [isNotificationOpen]);

  console.log(selectLangJson, "selectLangJson selectLangJson");
  return (
    <HS.HeaderBox themeColor={theme}>
      {innerwidth > 768 ? (
        <>
          <HS.NavBar>
            <HS.FirstContainer>
              <HS.MainLogo onClick={() => navigate("/")}>
                <img src={theme ? darkLogo : logoImage} alt="" />
              </HS.MainLogo>
              <HS.Options
                themeColor={theme}
                to="/client-admin"
                isActive={
                  window.location.pathname === "/client-admin/dashboard"
                }
              >
                {selectLangJson.dashboard}
              </HS.Options>
              <HS.Options
                themeColor={theme}
                to="/client-admin/courses"
                isActive={pathName?.includes(window.location.pathname)}
              >
                {selectLangJson.courses}
              </HS.Options>
              <HS.Options
                themeColor={theme}
                to="/client-admin/reports/master-reports"
                isActive={reportPathName?.includes(window.location.pathname)}
              >
                {selectLangJson.report}
                <HS.DownLogo src={theme ? darkDonwArrow : downIcon} alt="" />
              </HS.Options>
            </HS.FirstContainer>
            <HS.SecondContainer>
              <HS.ComIconContainers themeColor={theme}>
                <img src={theme ? darkSearch : searchIcon} alt="" />
              </HS.ComIconContainers>
              {/* <HS.ComIconContainers themeColor={theme}>
                <span themeColor={theme}>English</span>
                <HS.DownLogo src={theme ? darkDonwArrow : downIcon} alt="" />
              </HS.ComIconContainers> */}
              <HS.ComIconContainersDown themeColor={theme}>
                <img src={theme ? darkLang : langIcon} alt="" />
                <SelectQues
                  options={langOptions}
                  star={false}
                  value={langOptions?.find((val) => val.value === defaultLang)}
                  placeholder="Select"
                  onChange={handleChangeLanguage}
                />
              </HS.ComIconContainersDown>
              <HS.ComIconContainers
                onClick={() => {
                  getNotification();
                }}
                themeColor={theme}
              >
                <img src={theme ? darkBell : bellIcon} alt="" />
              </HS.ComIconContainers>
              <HS.ComIconContainers
                themeColor={theme}
                onClick={handleThemeChange}
                style={{ borderRight: "0" }}
              >
                <img src={theme ? darkIcon : lightIcon} alt="" />
              </HS.ComIconContainers>
              <HS.ProfileImage
                onClick={() => {
                  setIsProfileOpen(!isProfileOpen);
                }}
                style={{ marginRight: "15px" }}
              >
                <img src={profileData?.profileImage || profileDemo} alt="" />
              </HS.ProfileImage>
              <HS.ProfileLogoImage style={{ marginRight: "15px" }}>
                <img
                  src={
                    profileDetail?.client_informations?.logo?.logoImage ||
                    squareImage
                  }
                  alt=""
                />
              </HS.ProfileLogoImage>
              {isProfileOpen ? (
                <HS.AccountBox themeColor={theme} ref={profileRef}>
                  <HS.ProfileRow themeColor={theme}>
                    <HS.ProfileBox themeColor={theme}>
                      <img
                        src={profileData?.profileImage || profileDemo}
                        alt=""
                      />
                    </HS.ProfileBox>
                    <HS.NameBox themeColor={theme}>
                      <HS.NameTitle themeColor={theme}>
                        {profileData?.firstName} {profileData?.lastName}
                      </HS.NameTitle>
                      <HS.NameEmail themeColor={theme}>
                        {profileData?.email}
                      </HS.NameEmail>
                    </HS.NameBox>
                  </HS.ProfileRow>
                  {/* <HS.ComMenuBoxNoBorder
                    themeColor={theme}
                    to="/client-admin/profile-details"
                    onClick={() => {
                      setIsProfileOpen(false);
                    }}
                  >
                    <img src={accountIcon} alt="" />
                    {selectLangJson.view_profile}
                  </HS.ComMenuBoxNoBorder> */}

                  {/* <HS.ComMenuBoxCon
                    themeColor={theme}
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img src={switchIcon} alt="" />
                      Switch to User{" "}
                    </div>
                    <LS.DefaultToggle>
                      <Switch
                        rounded={true}
                        isToggled={isOpen}
                        bgColorChecked="#F6591C"
                        roundColorChecked="#ffffff"
                        border={
                          theme ? "1px solid transparent" : "1px solid #D7DAE0"
                        }
                        themeColor={theme}
                        wiDth={"40px"}
                        bgColorUnChecked={theme ? "#1F242F" : "#F5F5F6"}
                        roundColorUnChecked="#ffffff"
                        onToggle={() => {
                          setIsOpen(!isOpen);
                        }}
                      />
                    </LS.DefaultToggle>
                  </HS.ComMenuBoxCon> */}
                  <HS.ComMenuBox
                    themeColor={theme}
                    to="/client-admin/profile-details"
                    onClick={() => {
                      setIsProfileOpen(false);
                      getProfileDetailsTwo();
                    }}
                  >
                    <img src={accountIcon} alt="" />
                    {selectLangJson.account_settings}
                  </HS.ComMenuBox>
                  <HS.ComMenuBox
                    themeColor={theme}
                    onClick={handleConfirm}
                    style={{ border: "none" }}
                  >
                    <img src={logoutIconGrey} alt="" />
                    {selectLangJson.log_out}
                  </HS.ComMenuBox>
                </HS.AccountBox>
              ) : (
                ""
              )}
              {isNotificationOpen ? (
                <HS.NotificationBox themeColor={theme} ref={notificationRef}>
                  <HS.NotiTitleRow>
                    <HS.NotiTitle themeColor={theme}>
                      Notifications
                    </HS.NotiTitle>
                    <HS.SeeAllText
                      to="/client-admin/notification"
                      onClick={() => {
                        setIsNotificationOpen(false);
                      }}
                    >
                      See all
                    </HS.SeeAllText>
                  </HS.NotiTitleRow>
                  <HS.NotiContentBox>
                    {notificationData?.length > 0 ? (
                      notificationData?.map((val, ind) => {
                        // if (val.readMark) {
                        //   setIsRedBox(true);
                        // }
                        return (
                          <HS.NotificationCard key={ind} themeColor={theme}>
                            <HS.NotiLogo>
                              <img src={infoIcon} alt="" />
                            </HS.NotiLogo>
                            <HS.NotiContent>
                              <HS.NotiMessage themeColor={theme}>
                                {val.body}
                              </HS.NotiMessage>
                              <HS.TimeViewRow>
                                <HS.TimeBox themeColor={theme}>
                                  {moment(val.createdAt).format("h:mm:ss")}
                                  &nbsp;&nbsp;
                                  {moment(val.createdAt).format("DD MMM YYYY")}
                                </HS.TimeBox>
                                {val.viewStatus ? (
                                  <HS.ViewBox themeColor={theme}>
                                    View
                                  </HS.ViewBox>
                                ) : (
                                  ""
                                )}
                              </HS.TimeViewRow>
                            </HS.NotiContent>
                          </HS.NotificationCard>
                        );
                      })
                    ) : (
                      <HS.NotificationCard
                        themeColor={theme}
                        style={
                          theme
                            ? {
                                color: "#fff",
                                display: "flex",
                                justifyContent: "center",
                              }
                            : {
                                color: "#000",
                                display: "flex",
                                justifyContent: "center",
                              }
                        }
                      >
                        No Data
                      </HS.NotificationCard>
                    )}
                    {/* <HS.NotificationCard>
                    <HS.NotiLogo>
                      <img src={infoIcon} alt="" />
                    </HS.NotiLogo>
                    <HS.NotiContent>
                      <HS.NotiMessage>
                        You have a new request of additional license from
                        Deepika for the WorkSafe Delta.
                      </HS.NotiMessage>
                      <HS.TimeViewRow>
                        <HS.TimeBox>18-01-2023 10:56:42</HS.TimeBox>
                        <HS.ViewBox>View</HS.ViewBox>
                      </HS.TimeViewRow>
                    </HS.NotiContent>
                  </HS.NotificationCard>
                  <HS.NotificationCard>
                    <HS.NotiLogo>
                      <img src={warns} alt="" />
                    </HS.NotiLogo>
                    <HS.NotiContent>
                      <HS.NotiMessage>
                        You have a new request of additional license from
                        Deepika for the WorkSafe Delta.
                      </HS.NotiMessage>
                      <HS.TimeViewRow>
                        <HS.TimeBox>18-01-2023 10:56:42</HS.TimeBox>
                      </HS.TimeViewRow>
                    </HS.NotiContent>
                  </HS.NotificationCard>
                  <HS.NotificationCard>
                    <HS.NotiLogo>
                      <img src={infoIcon} alt="" />
                    </HS.NotiLogo>
                    <HS.NotiContent>
                      <HS.NotiMessage>
                        You have a new request of additional license from
                        Deepika for the WorkSafe Delta.
                      </HS.NotiMessage>
                      <HS.TimeViewRow>
                        <HS.TimeBox>18-01-2023 10:56:42</HS.TimeBox>
                        <HS.ViewBox>View</HS.ViewBox>
                      </HS.TimeViewRow>
                    </HS.NotiContent>
                  </HS.NotificationCard>
                  <HS.NotificationCard>
                    <HS.NotiLogo>
                      <img src={infoIcon} alt="" />
                    </HS.NotiLogo>
                    <HS.NotiContent>
                      <HS.NotiMessage>
                        You have a new request of additional license from
                        Deepika for the WorkSafe Delta.
                      </HS.NotiMessage>
                      <HS.TimeViewRow>
                        <HS.TimeBox>18-01-2023 10:56:42</HS.TimeBox>
                        <HS.ViewBox>View</HS.ViewBox>
                      </HS.TimeViewRow>
                    </HS.NotiContent>
                  </HS.NotificationCard>
                  <HS.NotificationCard>
                    <HS.NotiLogo>
                      <img src={warns} alt="" />
                    </HS.NotiLogo>
                    <HS.NotiContent>
                      <HS.NotiMessage>
                        You have a new request of additional license from
                        Deepika for the WorkSafe Delta.
                      </HS.NotiMessage>
                      <HS.TimeViewRow>
                        <HS.TimeBox>18-01-2023 10:56:42</HS.TimeBox>
                      </HS.TimeViewRow>
                    </HS.NotiContent>
                  </HS.NotificationCard> */}
                  </HS.NotiContentBox>
                </HS.NotificationBox>
              ) : (
                ""
              )}
            </HS.SecondContainer>
          </HS.NavBar>
        </>
      ) : (
        ""
      )}

      {innerwidth <= 768 ? (
        <>
          <HS.NavBar>
            <HS.FirstContainer>
              <HS.MainLogo onClick={() => navigate("/")}>
                <img src={theme ? darkLogo : logoImage} alt="" />
              </HS.MainLogo>
            </HS.FirstContainer>
            <HS.SecondContainer style={{ width: "auto" }}>
              <HS.ComIconContainers
                themeColor={theme}
                onClick={handleThemeChange}
                style={{ borderRight: "0" }}
              >
                <img src={theme ? darkIcon : lightIcon} alt="" />
              </HS.ComIconContainers>
              <HS.ComIconContainers
                themeColor={theme}
                onClick={handleSidebarChange}
                style={{ borderRight: "0" }}
              >
                <img
                  src={theme ? darkDrawer : drawer}
                  alt=""
                  style={{ width: "35px", height: "35px" }}
                />
              </HS.ComIconContainers>
            </HS.SecondContainer>
          </HS.NavBar>
        </>
      ) : (
        ""
      )}
    </HS.HeaderBox>
  );
};

export default Navbar;
