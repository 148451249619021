import axios from "axios";

export const UploadFile = async (e, onProgressUpdate) => {
  const file = e;
  const maxProgress = 0;

  // console.log({ file });
  const CHUNK_SIZE = 5 * 1024 * 1024;
  let file_name = Date.now().toString() + "_" + file?.name;
  const res = await axios.post(`https://vc.rainmaker.co.in/api/v2/initiateUpload`, {
    fileName: file_name,
  });
  // console.log({ res });
  // return
  let totalChunks = Math.ceil(file?.size / CHUNK_SIZE);
  // console.log({ res, totalChunks });

  const uploadId = res.data.uploadId;
  // setUpdateId(uploadId_test)
  // console.log(uploadId, "uploadId");
  // const uploadId= "B4rkevK7H1YkTbHYKRJ.K3xXoATQ.gwixAmcERvDMxGhpG0.iRU1IUdXQhiwPPGipI1S54.5JRHulqtvTyaqpQ6zh2bdrRnpaN15haSNa1WWuQ4.fE82PnMxCOIrjLEM"
  const uploadPromises = [];
  let uploadedChunks = 0;
  let start = 0,
    end;
  for (let i = 0; i < totalChunks; i++) {
    end = start + CHUNK_SIZE;
    const chunk = file.slice(start, end);
    const formData = new FormData();
    formData.append("index", i);
    formData.append("totalChunks", totalChunks);
    formData.append("fileName", file_name);
    formData.append("file", chunk);
    // const  uploadPromise = await axios.post(`https://vc.rainmaker.co.in/api/v2/upload?uploadId=${uploadId}`,formData);
    // uploadChunk++;
    const uploadPromise = fetch(
      `https://vc.rainmaker.co.in/api/v2/upload?uploadId=${uploadId}`,
      {
        method: "POST",
        body: formData,
      }
    ).then(() => {
      uploadedChunks++;
      const progress = Math.floor((uploadedChunks / totalChunks) * 100);
      // console.log(progress, "progress bar");
      onProgressUpdate(progress);
    });
    // console.log({ uploadPromise: uploadPromise });
    uploadPromises.push(uploadPromise);
    start = end;
  }

  await Promise.all(uploadPromises);
  // const completeRes = await axios.post(`https://vc.rainmaker.co.in/api/v2/completeUpload?fileName=${file_name}&uploadId=${uploadId}`)
  const completeRes = await fetch(
    `https://vc.rainmaker.co.in/api/v2/completeUpload?fileName=${file_name}&uploadId=${uploadId}`,
    { method: "POST" }
  );
  const { success, data } = await completeRes.json();
  // console.log("file link: ", data);

  return data;
};
