import React, { useState } from "react";
import styled from "styled-components/macro";
import { useSelector } from "react-redux";
import { Field } from "formik";
import downArrowIcon from "../../assets/images/downArrow.png";

const InputBorder = styled.div`
  border: ${(props) =>
    props.error
      ? "1px solid red"
      : props.themeColor
      ? "1px solid #333741"
      : "1px solid #D7DAE0"};
  /* margin-top: ${(props) => (props.error ? "10px" : "0")}; */
  border-radius: 10px;
  opacity: 1;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  /* justify-content: space-between; */
  width: auto;
  height: auto;
  /* padding: ${(props) => (props.backColor ? "0" : "12px 10px")}; */
  padding: 12px 10px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  background-color: ${(props) =>
    props.themeColor ? "#0C111D" : props.backColor ? "#F6F7F9" : "#ffffff"};

  img {
    height: 18px;
    width: 18px;
  }

  input {
    font: 1.1rem;
    background-color: ${(props) =>
      props.themeColor ? "transparent" : "#ffffff"};
  }
  input::placeholder {
    font-size: 0.8rem !important;
    color: ${(props) => (props.themeColor ? "#94969C" : "#8A94A6")};
    text-transform: capitalize;
  }
`;
export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.7rem;
`;
export const InputLable = styled.label`
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 0.3rem;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
  margin-bottom: 0.5rem;
  text-transform: capitalize;

  @media (max-width: 351px) {
    font-size: 0.8rem;
  }
`;
export const InBox = styled.div`
  display: flex;
  color: ${(props) => (props.themeColor ? "#94969C" : "#8A94A6")};
  font-size: 0.8rem;
  align-items: center;
  width: 5%;

  img {
    height: 7px;
    width: 7px;
    object-fit: contain;
    margin-left: 4px;
  }
`;
export default function Input(props) {
  const {
    error,
    type,
    icon,
    noBorderBottom,
    label,
    star,
    optional,
    erroros,
    cursorNot,
    theme,
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <InputBox themeColor={theme}>
        <InputLable themeColor={theme}>
          {label}
          {star ? <span style={{ color: "#F6591C" }}>*</span> : ""}
          {optional ? (
            <span style={{ color: "#667085" }}> (Optional)</span>
          ) : (
            ""
          )}
        </InputLable>
        <InputBorder
          cursorNot={cursorNot}
          style={{
            backgroundColor: theme
              ? cursorNot
                ? "#161B26"
                : "#0C111D"
              : cursorNot
              ? "#F6F7F9"
              : "#ffffff",
          }}
          error={error || erroros}
          themeColor={theme}
        >
          {icon ? (
            <InBox themeColor={theme}>
              IN <img src={downArrowIcon} alt="" />
            </InBox>
          ) : (
            ""
          )}
          <Field
            {...props}
            type={type ? (showPassword ? "text" : type) : "text"}
            style={{
              textAlign: "left",
              border: "none",
              outline: "none",
              width: "90%",
              backgroundColor: theme
                ? cursorNot
                  ? "#161B26"
                  : "#0C111D"
                : cursorNot
                ? "#F6F7F9"
                : "#ffffff",
              color: theme ? "#FFFFFF" : "#161b26",
              cursor: cursorNot ? "not-allowed" : "",
              opacity: cursorNot ? "0.7" : "1",
            }}
            disabled={cursorNot}
          />
          {type === "password" ? (
            <i
              onClick={() => setShowPassword(!showPassword)}
              className={`fa ${
                !showPassword ? "ph ph-eye-slash" : "ph ph-eye"
              }`}
            ></i>
          ) : (
            ""
          )}
          {/* {error ? <img src={errorImage} alt="" /> : ""} */}
        </InputBorder>
        {error || erroros ? (
          <p
            style={{
              paddingTop: 5,
              fontSize: 11,
              color: "red",
              marginBottom: erroros ? 10 : 0,
            }}
          >
            {error || erroros}
          </p>
        ) : null}
      </InputBox>
    </>
  );
}
