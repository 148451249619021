import {
  USER_DATA,
  IS_DARK,
  ACCESS_MODULE,
  IS_TOGGLE,
  SIDEBAR_TOGGLE,
  DELETE_LENGTH,
  SIDEBAR_TOGGLE_RESP,
  IS_MODAL,
  ACCESS_TOKEN,
  COURSE_ID,
  CLIENT_ID,
  CLIENT_VIEW_ID,
  ERROROBJ,
  ERRORPOPMSG,
  LANGUAGEID,
  SELECTLANG,
  SELECTLANGJSON,
  LANGUAGES,
  PROFILEUPDATE,
  PROFILEDATA,
  PROFILEDETAIL,
} from "./actionTypes";

const setUserData = (data) => {
  return {
    type: USER_DATA,
    data,
  };
};
const setIsDark = (data) => {
  return {
    type: IS_DARK,
    data,
  };
};
const setIsAccessModule = (data) => {
  return {
    type: ACCESS_MODULE,
    data,
  };
};
const setIsToggle = (data) => {
  return {
    type: IS_TOGGLE,
    data,
  };
};

const setSidebarToggle = (data) => {
  return {
    type: SIDEBAR_TOGGLE,
    data,
  };
};
const setTableLength = (data) => {
  return {
    type: DELETE_LENGTH,
    data,
  };
};
const setSidebarIsOpens = (data) => {
  return {
    type: SIDEBAR_TOGGLE_RESP,
    data,
  };
};
const setIsModal = (data) => {
  return {
    type: IS_MODAL,
    data,
  };
};
const setAccessToken = (data) => {
  return {
    type: ACCESS_TOKEN,
    data,
  };
};
const setClientId = (data) => {
  return {
    type: CLIENT_ID,
    data,
  };
};
const setCourseId = (data) => {
  return {
    type: COURSE_ID,
    data,
  };
};
const setCourseViewId = (data) => {
  return {
    type: CLIENT_VIEW_ID,
    data,
  };
};
const setErrorPop = (data) => {
  return {
    type: ERROROBJ,
    data,
  };
};
const setErrorPopMsg = (data) => {
  return {
    type: ERRORPOPMSG,
    data,
  };
};
const setLanaguageId = (data) => {
  return {
    type: LANGUAGEID,
    data,
  };
};
const setSelectLang = (data) => {
  return {
    type: SELECTLANG,
    data,
  };
};
const setSelectLangJson = (data) => {
  return {
    type: SELECTLANGJSON,
    data,
  };
};
const setLanguages = (data) => {
  return {
    type: LANGUAGES,
    data,
  };
};

const setUpdateProfile = (data) => {
  return {
    type: PROFILEUPDATE,
    data,
  };
};
const setProfileData = (data) => {
  return {
    type: PROFILEDATA,
    data,
  };
};
const setProfileDetail = (data) => {
  return {
    type: PROFILEDETAIL,
    data,
  };
};
export {
  setUserData,
  setIsDark,
  setIsAccessModule,
  setIsToggle,
  setSidebarToggle,
  setTableLength,
  setSidebarIsOpens,
  setIsModal,
  setAccessToken,
  setCourseId,
  setClientId,
  setCourseViewId,
  setErrorPop,
  setErrorPopMsg,
  setLanaguageId,
  setSelectLang,
  setSelectLangJson,
  setLanguages,
  setUpdateProfile,
  setProfileData,
  setProfileDetail,
};
