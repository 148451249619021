import React, { useState, useRef, useEffect, useCallback } from "react";
import * as MS from "../../../styles/ManageStyled";
import * as TS from "../../../styles/TemplateStyled";
import downloadIcon from "../../../assets/images/downloadIcon.png";
import mailIcon from "../../../assets/images/mailIcon.png";
import shareIcon from "../../../assets/images/shareIcon.png";
import plusIcon from "../../../assets/images/plusIcon.png";
import YearInput from "../../../components/DatePicker";
import searchIcon from "../../../assets/images/lightSearchIcon.png";
import lightDownload from "../../../assets/images/lightDownload.png";
import darkLowIcon from "../../../assets/images/darkLowIcon.png";
import CourseView from "./CourseView";
import Select from "../../../components/Select";
import SelectNoti from "../../../components/SelectNoti";
import darkCheckboxBaseIcon from "../../../assets/images/dark_base.png";
import darkcheckIcon from "../../../assets/images/darkcheckIcon.png";
import checkboxIcon from "../../../assets/images/Checkbox.png";
import checkboxBaseIcon from "../../../assets/images/_Checkbox_base.png";
import whiteDownload from "../../../assets/images/whiteDownload.png";
import warnIcon from "../../../assets/images/warn.png";
import { useSelector, useDispatch } from "react-redux";
import {
  setErrorPop,
  setErrorPopMsg,
  setTableLength,
} from "../../../store/action";
import * as SEC from "../../../styles/SectionStyled";
import { Table } from "antd";
import * as CS from "../../../styles/CommonStyled";
import editIcon from "../../../assets/images/editLight.png";
import darkArrow from "../../../assets/images/darkArrow.png";
import darkRight from "../../../assets/images/darkRight.png";
import lighRight from "../../../assets/images/lighRight.png";
import lightLeft from "../../../assets/images/lightLeft.png";
import moment from "moment";
import dotImage from "../../../assets/images/dotImage.png";
import delIcon from "../../../assets/images/delete.png";
import binTable from "../../../assets/images/binTable.png";
import darkDownload from "../../../assets/images/darksDownload.png";
import darkEmail from "../../../assets/images/darkEmail.png";
import darkShare from "../../../assets/images/darkShare.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import bookIcon from "../../../assets/images/bookIcon.png";
import darkBookIcon from "../../../assets/images/darkBookIcon.png";
import downloadIconOragnge from "../../../assets/images/downloadIconOragnge.png";
import axios from "../../../utils/axios";
import { Course, NotificationAPI } from "../../../utils/api";
import { get } from "lodash";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Modal from "../../../components/Modal/Modal";
import { PopUp } from "../../../components/PopUp/PopUp";
import * as SRS from "../../../styles/SurveyStyled";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Formik, Field, Form, FieldArray } from "formik";
import Input from "../../../components/Inputs/InputAdd";
import SelectLabel from "../../../components/SelectLabel";
import * as CDS from "../../../styles/CourseDetailStyled";
import * as CDD from "../../../styles/CourseDetStyled";
import * as UE from "../../../styles/UserElement";
import TextArea from "../../../components/InputDesciption";
import { EmailSendValidator, notiValidator } from "../../../utils/validator";
import successModal from "../../../assets/images/successModal.png";
import bold from "../../../assets/images/editor/bold.png";
import dot_list from "../../../assets/images/editor/dot_list.png";
import head from "../../../assets/images/editor/head.png";
import editLight from "../../../assets/images/editLight.png";
import image from "../../../assets/images/editor/image.png";
import italic from "../../../assets/images/editor/italic.png";
import link from "../../../assets/images/editor/link.png";
import num_list from "../../../assets/images/editor/num_list.png";
import quote from "../../../assets/images/editor/quote.png";
import { UploadFile } from "../../../utils/uploadImage";

const statusOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Completed",
    value: "completed",
  },
  {
    label: "In progress",
    value: "inProgress",
  },
  {
    label: "Not Started",
    value: "notstarted",
  },
];
const activeOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Active",
    value: "active",
  },
  {
    label: "InActive",
    value: "InActive",
  },
];
const locationList = [
  { value: "Option1", label: "Option 1" },
  { value: "Option2", label: "Option 2" },
  { value: "Option3", label: "Option 3" },
];
const notiType = [
  { value: "warning", label: "Warning" },
  { value: "info", label: "Info" },
];
const CourseDetail = ({ navigate, theme, localState }) => {
  const dispatch = useDispatch();
  const [activeId, setActiveId] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const courseId = useSelector((state) => state?.courseId);
  const clientId = useSelector((state) => state?.clientId);
  console.log(clientId, "clientId");
  const deleteLengthNum = useSelector((state) => state?.deleteLength);
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  const [selectedRowKeys, setSelectedRowKeys] = useState(deleteLengthNum);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(false);
  const [feildLoading, setFeildLoading] = useState(false);
  const [listId, setListId] = useState("");
  const [userList, setUserList] = useState([]);
  const [errorObj, setErrorObj] = useState({});
  const [percentData, setPercentData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpens, setIsOpens] = useState(false);
  const [isOpensTwo, setIsOpensTwo] = useState(false);
  const [courseStatus, setCourseStatus] = useState("all");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [userStatus, setUserStatus] = useState("all");
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [downloadLoadingTwo, setDownloadLoadingTwo] = useState(false);
  const [deleteError, setDeleteError] = useState({
    state: false,
    msg: "",
  });
  const [mailLoginVlaues, setMailLoginValues] = useState({
    // from: "",
    _id: courseId,
    languageId: "66262d5fac4634a8113234ae",
    subject: "",
    body: "",
  });
  const [loginValues, setLoginValues] = useState({
    body: "",
    title: "",
    type: "",
    _id: courseId,
    languageId: "66262d5fac4634a8113234ae",
  });
  const [showsucess, setShowsucess] = useState(false);
  const [showsucessDownload, setShowsucessDownload] = useState(false);
  const [showsucessSend, setShowsucessSend] = useState(false);
  const [showsucessMail, setShowsucessMail] = useState(false);
  const pageLimitLearner = 10;
  const columnsLearner = [
    {
      title: "Learner",
      dataIndex: "userName",
      key: "userName",
      width: 250,
      ellipsis: true,
      render: (text, record) => (
        <CS.TitleColumn>
          <CS.ColumnTitle
            style={{
              whiteSpace: "nowrap",
              width: "250px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            themeColor={theme}
          >
            {record.userName}
          </CS.ColumnTitle>
          <CS.ColumnEmail
            style={{
              whiteSpace: "nowrap",
              width: "250px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            themeColor={theme}
          >
            {record.email}
          </CS.ColumnEmail>
        </CS.TitleColumn>
      ),
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      width: 250,
      ellipsis: true,
      render: (text, record) => (
        <div>
          {record.mobileNumberCountryCode} {record.mobileNumber}
        </div>
      ),
    },
    {
      title: "Last Login",
      dataIndex: "lastLogin",
      key: "lastLogin",
      width: 250,
      ellipsis: true,
      render: (text, record) => (
        <div style={{ textAlign: "center" }}>
          {record?.lastLogin
            ? moment(record?.lastLogin).format("DD/MM/YYYY")
            : "-"}
          {}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      align: "center",
      width: 350,
      ellipsis: true,
      render: (text, record) => (
        <div>
          <CS.StatusButton
            themeColor={theme}
            isActive={record.status === 1 ? true : false}
          >
            {record.status === 1 ? "Active" : "InActive"}
          </CS.StatusButton>
        </div>
      ),
    },
    {
      title: "Completion Status",
      dataIndex: "completionStatus",
      key: "completionStatus",
      width: 350,
      align: "center",
      ellipsis: true,
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {" "}
          <MS.CircleBox>
            <CircularProgressbar
              value={record.completionStatus}
              strokeWidth={"14"}
              styles={buildStyles({
                strokeLinecap: "butt",
                pathTransitionDuration: 0.5,
                pathColor: `#17B26A`,
                textSize: "16px",
                position: "relative",
                trailColor: theme ? "#161B26" : "#EDEEF1",
              })}
            />
            <img
              src={theme ? darkBookIcon : bookIcon}
              alt="Your Alt Text"
              style={{
                position: "absolute",
                width: "10px", // Adjust size accordingly
                height: "10px", // Adjust size accordingly
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </MS.CircleBox>
          <MS.CircleText themeColor={theme}>
            {record.completionStatus === 0
              ? "Not Started"
              : record.completionStatus === 100
              ? "Completed"
              : "In Progress"}
          </MS.CircleText>
        </div>
      ),
    },
    {
      title: "Download Certificate",
      dataIndex: "downloadCertificate",
      key: "downloadCertificate",
      align: "center",
      width: 350,
      ellipsis: true,
      render: (text, record) => (
        <div>
          {" "}
          <CS.ComButton isCursor={record?.downloadCertificate}>
            {downloadLoadingTwo ? (
              <i class="fa fa-spinner fa-spin"></i>
            ) : (
              <img
                src={
                  record?.downloadCertificate
                    ? darkDownload
                    : theme
                    ? darkLowIcon
                    : lightDownload
                }
                onClick={() => {
                  if (record?.downloadCertificate) {
                    downloadCertificate(record._id);
                  } else {
                    return "";
                  }
                }}
                alt=""
              />
            )}
          </CS.ComButton>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "isActive",
      key: "isActive",
      width: 350,
      ellipsis: true,
      render: (text, record) => (
        <div style={{ display: "flex" }}>
          <CS.ComButton
            isCursor={true}
            style={{ cursor: record?.isDeleted ? "pointer" : "not-allowed" }}
            onClick={() => {
              // if (record?.isDeleted) {
              //   setOpens(true);
              //   setListId(record._id);
              // } else {
              //   return "";
              // }
              setOpens(true);
              setListId(record._id);
            }}
          >
            <img src={delIcon} alt="" />
          </CS.ComButton>

          <CS.ComButton
            isCursor={true}
            onClick={() => {
              navigate("/client-admin/courses-manage/learner-view", {
                state: record._id,
              });
            }}
          >
            <img src={editIcon} alt="" />
          </CS.ComButton>
        </div>
      ),
    },
  ];

  const deleteComit = async (e) => {
    let url = Course.deleteLearner;
    const formValue = {
      ids: e,
    };
    try {
      const { data } = await axios.post(url, formValue);
      getLearner();

      setSelectedRowKeys([]);
      setListId();
    } catch (error) {
      // if (error.response && error.response.data.status === 400) {
      //   setDeleteError({
      //     state: true,
      //     msg: error.response.data.mesaage,
      //   });
      // }

      // setTimeout(() => {
      //   setDeleteError({
      //     state: false,
      //     msg: "",
      //   });
      // }, [3000]);
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        setOpens(false);
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
    } finally {
      setOpens(false);
    }
  };
  const handleChange = (e) => {
    console.log(e);
    setInputValue(e.target.value);
  };
  const CustomCheckbox = ({ checked, onChange }) => {
    return (
      <div onClick={() => onChange(!checked)}>
        {checked ? (
          <MS.CheckBoxs>
            <img
              src={theme ? darkcheckIcon : checkboxIcon}
              style={{ marginRight: "0" }}
              alt="Checked"
            />
          </MS.CheckBoxs> // Your custom checked icon
        ) : (
          <MS.CheckBoxs>
            <img
              src={theme ? darkCheckboxBaseIcon : checkboxBaseIcon}
              style={{ marginRight: "0" }}
              alt="Unchecked"
            />
          </MS.CheckBoxs> // Your custom unchecked icon
        )}
      </div>
    );
  };
  const handleCheckboxChange = (id, checked) => {
    let newSelectedRowKeys = [...selectedRowKeys];

    if (checked) {
      newSelectedRowKeys.push(id);
    } else {
      newSelectedRowKeys = newSelectedRowKeys.filter((key) => key !== id);
    }

    console.log(newSelectedRowKeys); // Debugging line
    setSelectedRowKeys(newSelectedRowKeys);
    dispatch(setTableLength(newSelectedRowKeys));
  };
  const CustomIcons = {
    bold: `<img src="${bold}" alt="Bold" style="width: 12px; height: 12px;">`,
    italic: `<img src="${italic}" alt="Bold" style="width: 12px; height: 12px;">`,
    header: `<img src="${head}" alt="Bold" style="width: 12px; height: 12px;">`,
    headerTwo: `<img src="${head}" alt="Bold" style="width: 11px; height: 11px;">`,
    blockquote: `<img src="${quote}" alt="Bold" style="width: 16px; height: 12px;">`,
    link: `<img src="${link}" alt="Bold" style="width: 24px; height: 12px;">`,
    image: `<img src="${image}" alt="Bold" style="width: 18px; height: 18px; margin-left: 13px;">`,
    listBullet: `<img src="${dot_list}" alt="Bold" style="width: 30px; height: 30px;">`,
    listOrdered: `<img src="${num_list}" alt="Bold" style="width: 30px; height: 30px;">`,
    // Add more icons as needed
  };
  const reactQuillRef = useRef(null);
  const fileInputRef = useRef(null);
  // Assign custom icons to Quill
  Quill.import("ui/icons").bold = CustomIcons.bold;
  Quill.import("ui/icons").italic = CustomIcons.italic;
  Quill.import("ui/icons").header["1"] = CustomIcons.header; // For header 1
  Quill.import("ui/icons").header["2"] = CustomIcons.headerTwo; // For header 1
  Quill.import("ui/icons").blockquote = CustomIcons.blockquote;
  Quill.import("ui/icons").link = CustomIcons.link;
  Quill.import("ui/icons").image = CustomIcons.image;
  Quill.import("ui/icons").bullet = CustomIcons.listBullet;
  Quill.import("ui/icons").ordered = CustomIcons.listOrdered;
  const imageHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      if (input !== null && input.files !== null) {
        const file = input.files[0];
        const url = await UploadFile(file, setUploadProgress);
        const quill = reactQuillRef.current;
        if (quill) {
          const range = quill.getEditorSelection();
          range && quill.getEditor().insertEmbed(range.index, "image", url);
        }
      }
    };
  }, []);
  const modules = {
    toolbar: {
      container: [
        ["bold", "italic"], // Toggled buttons
        [{ header: 1 }], // Custom button values
        [{ header: 2 }], // Custom button values
        ["blockquote"],
        ["link", "image"], // Adding link and image support
        [{ list: "bullet" }, { list: "ordered" }], // List options
        [{ size: ["small", false, "large", "huge"] }], // Custom dropdown for text size
      ],
      handlers: {
        image: imageHandler,
      },
    },
  };
  const rowSelection = {
    getCheckboxProps: (record) => ({
      id: record._id, // Assuming each record has a unique _id
    }),
    renderCell: (checked, record, index, originNode) => (
      <CustomCheckbox
        checked={checked}
        onChange={(newChecked) => {
          // if (record.isDeleted) {
          //   handleCheckboxChange(record._id, newChecked);
          // }
          handleCheckboxChange(record._id, newChecked);
        }}
      />
    ),
    selectedRowKeys, // Important to bind the selectedRowKeys state here
    onChange: (newSelectedRowKeys, selectedRows) => {
      setSelectedRowKeys(newSelectedRowKeys); // Update the state when selection changes
      dispatch(setTableLength(newSelectedRowKeys));
    },
    // onChange: (newSelectedRowKeys, selectedRows) => {
    //   const filteredSelectedRowKeys = newSelectedRowKeys.filter((key) =>
    //     userList.some((user) => user._id === key && user.isDeleted)
    //   );
    //   setSelectedRowKeys(filteredSelectedRowKeys); // Update the state when selection changes
    //   dispatch(setTableLength(filteredSelectedRowKeys));
    // },
    // onSelectAll: (selected, selectedRows, changeRows) => {
    //   const filteredSelectedRowKeys = selected
    //     ? userList
    //         .filter((record) => record.isDeleted)
    //         .map((record) => record._id)
    //     : [];

    //   setSelectedRowKeys(filteredSelectedRowKeys); // Update the state when selection changes
    //   dispatch(setTableLength(filteredSelectedRowKeys));
    // },
  };
  const [inputType, setInputType] = useState("createdAt");
  const [sortType, setSortType] = useState("ascend");

  const skeletonBaseColor = theme ? "#1F242F" : "#EDEEF1";
  const skeletonHighlightColor = theme ? "#1F242F" : "#EDEEF1";
  const pageLimit = 10;
  const sentinelRef = useRef();
  const screenWidth = window.innerWidth;

  const getLearner = async () => {
    setFeildLoading(true);
    let url = `${Course.getCourseContent}learners`;
    const formValue = {
      limit: pageLimit,
      page: currentPage,
      _id: courseId,
      languageId: "66262d5fac4634a8113234ae",
      courseStatus: courseStatus, // ["all","completed","inProgress","notstarted"]
      userStatus: userStatus, // "all","active","InActive"
    };
    inputValue && (formValue["search"] = inputValue);
    try {
      const { data } = await axios.post(url, formValue);
      setPercentData(data.data);
      if (screenWidth < 789) {
        setFeildLoading(false);
        setUserList((courseList) => [...courseList, ...data.result?.docs]);
      } else {
        setFeildLoading(false);
        setUserList(data.result?.docs);
      }
      setTotalCount(data.result?.totalDocs);
    } catch (error) {
      setFeildLoading(false);
      // alert(error.response.data.message);
      console.log(error.response.data.message);
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
    }
  };

  useEffect(() => {
    if (currentPage && userStatus && courseStatus) getLearner(-1, "createdAt");
  }, [currentPage, inputValue, sortType, inputType, userStatus, courseStatus]);

  const numberOfPages = Math.ceil(totalCount / pageLimit);
  const handleClose = () => {
    setIsOpen(false);
    setIsOpens(false);
    setShowsucessSend(false);
    setShowsucessMail(false);
    setShowsucessDownload(false);
    setDeleteError({
      state: false,
      msg: "",
    });
  };
  const [text, setText] = React.useState("");

  const handleChangeText = (value) => {
    setText(value);
  };

  const handleMail = async (values) => {
    try {
      let url = Course.sendLearnerMail;
      let formValue = {
        ...values,
        courseStatus: courseStatus, // ["all","completed","inProgress","notstarted"]
        userStatus: userStatus, // "all","active","InActive"
        userSelectFilter: selectedRowKeys?.length > 0 ? "selected" : "all", //all ,selected
      };
      selectedRowKeys?.length > 0 && (formValue["users"] = selectedRowKeys);
      const { data } = await axios.post(url, formValue);
      setShowsucessMail(true);
      setIsOpen(false);
      setTimeout(() => {
        setShowsucessMail(false);
      }, 2000);
    } catch (error) {}
  };

  const handleLogin = async (values) => {
    console.log(values);
    try {
      let url = NotificationAPI.sendNotification;
      let formValue = {
        ...values,
        courseStatus: courseStatus, // ["all","completed","inProgress","notstarted"]
        userStatus: userStatus, // "all","active","InActive"
        userSelectFilter: selectedRowKeys?.length > 0 ? "selected" : "all", //all ,selected
      };
      selectedRowKeys?.length > 0 && (formValue["users"] = selectedRowKeys);
      const { data } = await axios.post(url, formValue);
      setShowsucessSend(true);
      setIsOpens(false);
      setTimeout(() => {
        setShowsucessSend(false);
      }, 2000);
    } catch (error) {
      console.log(error.response.data.message);
      let errObj = {};
      error.response.data.errors?.map((val) => {
        console.log(val.path, "error val");
        if (!errObj[val.path]) {
          errObj[val.path] = val.msg;
        }
      });
      setErrorObj(errObj);
    }
  };
  const handleReportDownload = async (values) => {
    setDownloadLoading(true);
    try {
      let url = Course.reportGenerate;
      let formValue = {
        courseStatus: courseStatus, // ["all","completed","inProgress","notstarted"]
        userStatus: userStatus, // "all","active","InActive"
        userSelectFilter: selectedRowKeys?.length > 0 ? "selected" : "all", //all ,selected
        _id: courseId,
      };
      selectedRowKeys?.length > 0 && (formValue["users"] = selectedRowKeys);
      // const { data } = await axios.post(url, formValue);
      const { data } = await axios({
        url: url,
        method: "POST",
        responseType: "blob", // Important: set response type as blob for binary data
        data: {
          ...formValue,
        },
      });
      const blob = new Blob([data], { type: "application/xlsx" });

      // Create a link element, set the download attribute and trigger a click
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "downloaded_file.xlsx"; // Set the name of the downloaded file
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link element
      link.parentNode.removeChild(link);
      setShowsucessDownload(true);
      setIsOpens(false);
      setTimeout(() => {
        setShowsucessDownload(false);
      }, 2000);
      setDownloadLoading(false);
    } catch (error) {
      setDownloadLoading(false);
      console.log(error.response.data.message);
    }
  };

  console.log(localState, "localState localState");

  const downloadCertificate = async (e) => {
    setDownloadLoadingTwo(true);
    try {
      let url = `${Course.downloadCertificate}${courseId}?userId=${e}`;
      const { data } = await axios({
        url: url,
        method: "GET",
        responseType: "blob", // Important: set response type as blob for binary data
      });
      const blob = new Blob([data], { type: "application/pdf" });

      // Create a link element, set the download attribute and trigger a click
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "downloaded_file.pdf"; // Set the name of the downloaded file
      document.body.appendChild(link);
      link.click();

      setDownloadLoadingTwo(false);
      // Clean up and remove the link element
      link.parentNode.removeChild(link);
    } catch (error) {
      setDownloadLoadingTwo(false);
      console.log(error.response.data.message);
    }
  };

  const handleCloses = () => {
    setOpens(false);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Process the file, e.g., upload to server, convert to base64, etc.
      console.log("File chosen:", file);
    }
  };

  return (
    <>
      <CDS.DetailContainer>
        {/* <CourseView courseId={courseId} theme={theme} localState={localState} /> */}
        <CDS.DetailCon themeColor={theme}>
          <CDS.DetailInnerCon>
            <MS.ManageLearnerBox>
              <MS.ManageHead>
                <MS.ManageComm style={{ gap: "0.8rem" }}>
                  <MS.SearchBox themeColor={theme}>
                    <img src={searchIcon} alt="" />
                    <input
                      type="text"
                      placeholder={selectLangJson.search_learner}
                      onChange={handleChange}
                    />
                  </MS.SearchBox>
                  <MS.DropContainer>
                    <Select
                      theme={theme}
                      options={statusOptions}
                      value={statusOptions?.find(
                        (val) => val.value === courseStatus
                      )}
                      onChange={(e) => {
                        setCourseStatus(e.value);
                      }}
                      placeholder={selectLangJson.select}
                    />
                  </MS.DropContainer>
                  <MS.DropContainer>
                    <Select
                      theme={theme}
                      options={activeOptions}
                      value={activeOptions?.find(
                        (val) => val.value === userStatus
                      )}
                      onChange={(e) => {
                        setUserStatus(e.value);
                      }}
                      placeholder={selectLangJson.select}
                    />
                  </MS.DropContainer>
                </MS.ManageComm>
                <MS.ManageComm style={{ gap: "0.8rem" }}>
                  <MS.ComIcon
                    themeColor={theme}
                    onClick={() => {
                      // setIsOpensTwo(true);
                      handleReportDownload();
                    }}
                  >
                    {downloadLoading ? (
                      <i class="fa fa-spinner fa-spin"></i>
                    ) : (
                      <img src={theme ? darkDownload : downloadIcon} alt="" />
                    )}
                  </MS.ComIcon>
                  <MS.ComIcon
                    onClick={() => {
                      setIsOpen(true);
                    }}
                    themeColor={theme}
                  >
                    <img src={theme ? darkEmail : mailIcon} alt="" />
                  </MS.ComIcon>
                  <MS.ComIcon
                    onClick={() => {
                      setIsOpens(true);
                    }}
                    themeColor={theme}
                  >
                    <img src={theme ? darkShare : shareIcon} alt="" />
                  </MS.ComIcon>
                  {get(percentData, "add_learner_button", false) ? (
                    <MS.AddCommonButton
                      onClick={() =>
                        navigate("/client-admin/courses-manage/add-learner")
                      }
                    >
                      <img src={plusIcon} alt="" />
                      {selectLangJson.add_learner}
                    </MS.AddCommonButton>
                  ) : (
                    <MS.AddCommonButton
                      onClick={() =>
                        navigate("/client-admin/renew", {
                          state: {
                            popUp: true,
                          },
                        })
                      }
                    >
                      <img src={plusIcon} alt="" />
                      {selectLangJson.renew_license}
                    </MS.AddCommonButton>
                  )}
                </MS.ManageComm>
              </MS.ManageHead>
              {feildLoading ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Skeleton
                    width={310}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={50}
                    style={{
                      borderRadius: "5px",
                      margin: "1rem 0",
                    }}
                  />
                  <Skeleton
                    width={310}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={50}
                    style={{
                      borderRadius: "5px",
                      margin: "1rem 0",
                    }}
                  />
                  <Skeleton
                    width={310}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={50}
                    style={{
                      borderRadius: "5px",
                      margin: "1rem 0",
                    }}
                  />
                  <Skeleton
                    width={310}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={50}
                    style={{
                      borderRadius: "5px",
                      margin: "1rem 0",
                    }}
                  />
                </div>
              ) : (
                <MS.ProgressRow themeColor={theme}>
                  <MS.ComProgress themeColor={theme}>
                    <div className="com-container">
                      <p className="head-text">
                        {get(percentData, "completed", 0)}%
                      </p>
                      <p className="desc-text">{selectLangJson.completed}</p>
                    </div>
                  </MS.ComProgress>
                  <MS.ComProgress themeColor={theme}>
                    <div className="com-container">
                      <p className="head-text">
                        {get(percentData, "pass", 0)}%
                      </p>
                      <p className="desc-text">{selectLangJson.passed}</p>
                    </div>
                  </MS.ComProgress>
                  <MS.ComProgress themeColor={theme}>
                    <div className="com-container">
                      <p className="head-text">
                        {get(percentData, "inProgress", 0)}%
                      </p>
                      <p className="desc-text">{selectLangJson.in_progress}</p>
                    </div>
                  </MS.ComProgress>
                  <MS.ComProgress themeColor={theme} style={{ border: "none" }}>
                    <div className="com-container">
                      <p className="head-text">
                        {get(percentData, "notSttarted", 0)}%
                      </p>
                      <p className="desc-text">{selectLangJson.not_started}</p>
                    </div>
                  </MS.ComProgress>
                </MS.ProgressRow>
              )}

              <MS.FormContainerLearner themeColor={theme}>
                {feildLoading ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Skeleton
                      width={310}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={30}
                      style={{
                        borderRadius: "5px",
                        margin: "1rem 0",
                      }}
                    />
                    <Skeleton
                      width={310}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={30}
                      style={{
                        borderRadius: "5px",
                        margin: "1rem 0",
                      }}
                    />
                    <Skeleton
                      width={310}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={30}
                      style={{
                        borderRadius: "5px",
                        margin: "1rem 0",
                      }}
                    />
                    <Skeleton
                      width={310}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={30}
                      style={{
                        borderRadius: "5px",
                        margin: "1rem 0",
                      }}
                    />
                  </div>
                ) : (
                  <MS.TotalCountRow themeColor={theme}>
                    <div className="com-container">
                      <p className="desc-text">{selectLangJson.total_learners}:</p>
                      <p className="head-text">
                        {get(percentData, "total_learners", 0)}
                      </p>
                    </div>
                    <div className="com-container">
                      <p className="desc-text">
                        {selectLangJson.license_purchased}:
                      </p>
                      <p className="head-text">
                        {get(percentData, "license_purchased", 0)}
                      </p>
                    </div>
                    <div className="com-container">
                      <p className="desc-text">{selectLangJson.assigned}:</p>
                      <p className="head-text">
                        {get(percentData, "Assigned", 0)}
                      </p>
                    </div>
                    <div className="com-container" style={{ border: "none" }}>
                      <p className="desc-text">{selectLangJson.available}:</p>
                      <p className="head-text">
                        {get(percentData, "Available", 0)}
                      </p>
                    </div>
                  </MS.TotalCountRow>
                )}
                {feildLoading ? (
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <Skeleton
                      width={"100%"}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={50}
                      style={{
                        borderRadius: "5px",
                        margin: "0.3rem 0",
                      }}
                    />
                    <Skeleton
                      width={"100%"}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={50}
                      style={{
                        borderRadius: "5px",
                        margin: "0.3rem 0",
                      }}
                    />
                    <Skeleton
                      width={"100%"}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={50}
                      style={{
                        borderRadius: "5px",
                        margin: "0.3rem 0",
                      }}
                    />
                    <Skeleton
                      width={"100%"}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={50}
                      style={{
                        borderRadius: "5px",
                        margin: "0.3rem 0",
                      }}
                    />
                    <Skeleton
                      width={"100%"}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighlightColor}
                      height={50}
                      style={{
                        borderRadius: "5px",
                        margin: "0.3rem 0",
                      }}
                    />
                  </div>
                ) : userList?.length > 0 ? (
                  <SEC.ParentTable>
                    <SEC.TableBox
                      checkCon={selectedRowKeys?.length === userList?.length}
                      themeColor={theme}
                    >
                      <Table
                        rowKey={(record) => record._id}
                        style={{
                          width: "100%",
                          minHeight: "500px",
                        }}
                        rowSelection={rowSelection}
                        columns={columnsLearner}
                        className={
                          theme
                            ? "dark-table border-border"
                            : "light-table border-border"
                        }
                        dataSource={userList}
                        scroll={{ x: true }}
                        pagination={{
                          current: currentPage,
                          pageSize: pageLimitLearner,
                          total: totalCount,
                          onChange: (page) => {
                            console.log(page);
                            setCurrentPage(page);
                          },
                          // You can use this property to customize the rendered pagination component
                          itemRender: (current, type, originalElement) => {
                            const isDisabled = currentPage === 1;
                            if (type === "prev") {
                              return (
                                <SEC.PagiButton
                                  themeColor={theme}
                                  disabled={isDisabled}
                                  onClick={() => {
                                    !isDisabled &&
                                      setCurrentPage(currentPage - 1);
                                  }}
                                >
                                  <img
                                    src={theme ? lightLeft : darkArrow}
                                    style={{ marginRight: "8px" }}
                                    alt=""
                                  />{" "}
                                  <p>{selectLangJson.previous}</p>
                                </SEC.PagiButton>
                              );
                            }
                            if (type === "next") {
                              const isDisabled = currentPage === numberOfPages;
                              return (
                                <SEC.PagiButtonNext
                                  disabled={isDisabled}
                                  themeColor={theme}
                                  onClick={() => {
                                    !isDisabled &&
                                      setCurrentPage(currentPage + 1);
                                  }}
                                >
                                  <p>{selectLangJson.next}</p>
                                  <img
                                    src={theme ? lighRight : darkRight}
                                    style={{ marginLeft: "8px" }}
                                    alt=""
                                  />
                                </SEC.PagiButtonNext>
                              );
                            }
                            return originalElement;
                          },
                        }}
                      />
                    </SEC.TableBox>
                    {userList?.map((val, ind) => {
                      const isChecked = selectedRowKeys.includes(val._id);
                      return (
                        <SEC.TableCard themeColor={theme} key={ind}>
                          {console.log(val, "val")}
                          <SEC.TableTitleRow>
                            {selectedRowKeys?.length > 0 ? (
                              <SEC.CheckRowBox>
                                <CustomCheckbox
                                  checked={isChecked}
                                  onChange={(newChecked) => {
                                    handleCheckboxChange(val._id, newChecked);
                                  }}
                                />
                              </SEC.CheckRowBox>
                            ) : (
                              ""
                            )}
                            <SEC.TitleRowFirst
                              wiDth={
                                selectedRowKeys?.length > 0 ? "85%" : "90%"
                              }
                              style={{ flexDirection: "row" }}
                            >
                              <SEC.BoxesContainer>
                                <SEC.FirstRowBox>
                                  <SEC.TitleRowInnerFirst>
                                    <SEC.TitleRowFirstTitle
                                      style={{ fontWeight: "500" }}
                                    >
                                      {val.userName}
                                    </SEC.TitleRowFirstTitle>
                                    <SEC.TitleRowFirstDateBlack>
                                      {val.email}
                                    </SEC.TitleRowFirstDateBlack>
                                  </SEC.TitleRowInnerFirst>
                                  <CS.StatusButtons
                                    themeColor={theme}
                                    isActive={val.active === 1 ? true : false}
                                  >
                                    {val.active === 0
                                      ? selectLangJson.InActive
                                      : selectLangJson.active}
                                  </CS.StatusButtons>
                                </SEC.FirstRowBox>
                                <SEC.FirstRowBox>
                                  <SEC.TitleRowInnerFirst>
                                    <SEC.TitleRowFirstDateBlack
                                      style={{ fontWeight: "500" }}
                                    >
                                      {selectLangJson.mobile_no}
                                    </SEC.TitleRowFirstDateBlack>
                                    <SEC.TitleRowFirstDescGrey>
                                      {val.mobileNumberCountryCode}{" "}
                                      {val.mobileNumber}
                                    </SEC.TitleRowFirstDescGrey>
                                  </SEC.TitleRowInnerFirst>
                                  <SEC.TitleRowInnerFirst>
                                    <SEC.TitleRowFirstDateBlack
                                      style={{ fontWeight: "500" }}
                                    >
                                      {selectLangJson.last_login}
                                    </SEC.TitleRowFirstDateBlack>
                                    <SEC.TitleRowFirstDescGrey>
                                      {moment(val.createdAt).format("ll")}
                                    </SEC.TitleRowFirstDescGrey>
                                  </SEC.TitleRowInnerFirst>
                                </SEC.FirstRowBox>
                                <SEC.FirstRowBox>
                                  <SEC.TitleRowInnerFirst>
                                    <SEC.TitleRowFirstDateBlack
                                      style={{ fontWeight: "500" }}
                                    >
                                      {selectLangJson.department}
                                    </SEC.TitleRowFirstDateBlack>
                                    <SEC.TitleRowFirstDescGrey>
                                      {val.department}
                                    </SEC.TitleRowFirstDescGrey>
                                  </SEC.TitleRowInnerFirst>
                                  <SEC.TitleRowInnerFirst>
                                    <SEC.TitleRowFirstDateBlack
                                      style={{ fontWeight: "500" }}
                                    >
                                      {selectLangJson.reporting_manager}
                                    </SEC.TitleRowFirstDateBlack>
                                    <SEC.TitleRowFirstDescGrey>
                                      {val.reportingManager}
                                    </SEC.TitleRowFirstDescGrey>
                                  </SEC.TitleRowInnerFirst>
                                </SEC.FirstRowBox>
                              </SEC.BoxesContainer>
                            </SEC.TitleRowFirst>
                            <SEC.TitleRowSecond style={{ width: "2%" }}>
                              <div
                                className="dot-button"
                                onClick={() => {
                                  setDropdownOpen(
                                    dropdownOpen === ind ? false : ind
                                  );
                                }}
                              >
                                <img src={dotImage} alt="" />
                              </div>
                              <SEC.Message
                                isHeight={"105px"}
                                dropdownOpen={dropdownOpen === ind}
                              >
                                {isLoading ? (
                                  ""
                                ) : (
                                  <SEC.MenuRow
                                    onClick={() => {
                                      setSelectedRowKeys([
                                        ...selectedRowKeys,
                                        val._id,
                                      ]);
                                      dispatch(
                                        setTableLength([
                                          ...selectedRowKeys,
                                          val._id,
                                        ])
                                      );

                                      setDropdownOpen(false);
                                    }}
                                  >
                                    <img src={delIcon} alt="" />{" "}
                                    {selectLangJson.select}
                                  </SEC.MenuRow>
                                )}
                                {
                                  <SEC.MenuRow>
                                    <img src={editIcon} alt="" />{" "}
                                    {selectLangJson.edit}
                                  </SEC.MenuRow>
                                }

                                {isLoading ? (
                                  ""
                                ) : (
                                  <SEC.MenuRow
                                    onClick={() => {
                                      if (val.deletePopupStatus) {
                                        setOpen(true);
                                        setDropdownOpen(false);
                                      } else {
                                        setOpens(true);
                                        setListId(val._id);
                                        setDropdownOpen(false);
                                      }
                                    }}
                                  >
                                    <img src={delIcon} alt="" />{" "}
                                    {selectLangJson.delete}
                                  </SEC.MenuRow>
                                )}
                              </SEC.Message>
                            </SEC.TitleRowSecond>
                          </SEC.TableTitleRow>
                          <SEC.TableTitleRowDusra></SEC.TableTitleRowDusra>
                        </SEC.TableCard>
                      );
                    })}
                  </SEC.ParentTable>
                ) : (
                  ""
                )}
                {selectedRowKeys.length > 0 ? (
                  <TS.DeleteRow>
                    <TS.DeleteInner themeColor={theme}>
                      <TS.SelectedText paDD={selectedRowKeys.length > 9}>
                        <div className="roundTxt">
                          {selectedRowKeys?.length}
                        </div>{" "}
                        {selectLangJson.selected}
                      </TS.SelectedText>
                      <TS.DeleteButton onClick={() => setOpens(true)}>
                        <img src={binTable} alt="" />
                        {selectLangJson.delete}
                      </TS.DeleteButton>
                    </TS.DeleteInner>
                  </TS.DeleteRow>
                ) : (
                  ""
                )}
              </MS.FormContainerLearner>
            </MS.ManageLearnerBox>
          </CDS.DetailInnerCon>
        </CDS.DetailCon>
      </CDS.DetailContainer>
      <Modal
        theme={theme}
        // maxWidth={"750px"}
        isOpen={isOpen}
        isClose={handleClose}
        component={
          <SRS.SendMailBox themeColor={theme}>
            <Formik
              enableReinitialize
              initialValues={mailLoginVlaues}
              validateOnChange
              validate={EmailSendValidator}
              onSubmit={(values) => handleMail(values, false)}
            >
              {(formikBag) => {
                return (
                  <Form style={{ width: "100%" }}>
                    {/* <SRS.PublicRow style={{ marginBottom: "0.7rem" }}>
                      <SRS.LabelBox themeColor={theme}>
                        <p>{selectLangJson.from} :</p>
                      </SRS.LabelBox>
                      <SRS.InputBox style={{ width: "100%" }}>
                        <Field name="from">
                          {({ field }) => (
                            <SelectLabel
                              {...field}
                              theme={theme}
                              options={locationList}
                              value={locationList?.filter(
                                (val) => val.value === formikBag.values.from
                              )}
                              placeholder="Select Course"
                              onChange={(e) => {}}
                              error={
                                formikBag.touched.from &&
                                formikBag.errors.from
                                  ? formikBag.errors.from
                                  : null
                              }
                            />
                          )}
                        </Field>
                      </SRS.InputBox>
                    </SRS.PublicRow> */}
                    {/* <SRS.PublicRow>
                      <SRS.LabelBox themeColor={theme}>
                        <p>{selectLangJson.send_to} :</p>
                      </SRS.LabelBox>
                      <SRS.InputBox>
                        <Field name="users">
                          {({ field }) => (
                            <Input
                              {...field}
                              theme={theme}
                              placeholder="Send To"
                              value={formikBag.values.users}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "users",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.users &&
                                formikBag.errors.users
                                  ? formikBag.errors.users
                                  : null
                              }
                            />
                          )}
                        </Field>
                      </SRS.InputBox>
                    </SRS.PublicRow> */}
                    <SRS.PublicRow>
                      <SRS.LabelBox themeColor={theme}>
                        <p>{selectLangJson.subject} :</p>
                      </SRS.LabelBox>
                      <SRS.InputBox>
                        <Field name="subject">
                          {({ field }) => (
                            <Input
                              {...field}
                              placeholder={selectLangJson.subject}
                              value={formikBag.values.subject}
                              theme={theme}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "subject",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.subject &&
                                formikBag.errors.subject
                                  ? formikBag.errors.subject
                                  : null
                              }
                            />
                          )}
                        </Field>
                      </SRS.InputBox>
                    </SRS.PublicRow>
                    <SRS.EmailEditor
                      error={
                        formikBag.touched.body && formikBag.errors.body
                          ? formikBag.errors.body
                          : null
                      }
                      themeColor={theme}
                    >
                      <ReactQuill
                        ref={reactQuillRef}
                        value={formikBag.values.body}
                        placeholder="Text Here..."
                        onChange={(e) => {
                          // setErrorObj({});
                          formikBag.setFieldValue("body", e);
                        }}
                        modules={modules}
                      />
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                      {/* <div className="my-editing-area"></div> */}
                    </SRS.EmailEditor>
                    <p
                      style={{
                        paddingTop: 10,
                        fontSize: 11,
                        color: "red ",
                      }}
                    >
                      {(formikBag.touched.body && formikBag.errors.body) ||
                      errorObj.body
                        ? formikBag.errors.body || errorObj.body
                        : null}
                    </p>
                    <CDD.ButtonGroup
                      style={{ marginTop: "5rem" }}
                      themeColor={theme}
                    >
                      <CDD.SaveButton
                        cursor={isLoading}
                        disabled={isLoading}
                        className="buttonload"
                        type="submit"
                      >
                        {isLoading ? (
                          <i class="fa fa-spinner fa-spin"></i>
                        ) : (
                          selectLangJson.send
                        )}
                      </CDD.SaveButton>
                    </CDD.ButtonGroup>
                  </Form>
                );
              }}
            </Formik>
          </SRS.SendMailBox>
        }
      />
      <Modal
        theme={theme}
        // maxWidth={"750px"}
        isOpen={isOpens}
        isClose={handleClose}
        component={
          <SRS.SendMailBox themeColor={theme}>
            <Formik
              enableReinitialize
              initialValues={loginValues}
              validate={notiValidator}
              validateOnChange
              onSubmit={(values) => handleLogin(values, false)}
            >
              {(formikBag) => {
                return (
                  <Form style={{ width: "100%" }}>
                    <UE.InputBoxContainer>
                      <UE.InputBox wiDth={"49%"}>
                        <Field name="title">
                          {({ field }) => (
                            <Input
                              {...field}
                              placeholder={selectLangJson.notification_title}
                              label="Notification Title"
                              theme={theme}
                              value={formikBag.values.title}
                              feildLoading={false}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "title",
                                  e.target.value
                                );
                                formikBag.setFieldTouched("title", true, false);
                                setErrorObj({});
                              }}
                              error={
                                formikBag.touched.title &&
                                formikBag.errors.title
                                  ? formikBag.errors.title
                                  : null
                              }
                              erroros={errorObj.title}
                            />
                          )}
                        </Field>
                      </UE.InputBox>
                      <UE.InputBox wiDth={"49%"}>
                        <Field name="type">
                          {({ field }) => (
                            <SelectNoti
                              {...field}
                              placeholder={selectLangJson.notification_type}
                              label="Notification Type"
                              options={notiType}
                              theme={theme}
                              value={notiType?.find(
                                (val) => val.value === formikBag.values.type
                              )}
                              feildLoading={false}
                              onChange={(e) => {
                                formikBag.setFieldValue("type", e.value);
                                formikBag.setFieldTouched("type", true, false);
                                setErrorObj({});
                              }}
                              error={
                                formikBag.touched.type && formikBag.errors.type
                                  ? formikBag.errors.type
                                  : null
                              }
                              erroros={errorObj.type}
                            />
                          )}
                        </Field>
                      </UE.InputBox>
                      <UE.InputBox wiDth={"100%"}>
                        <Field name="body">
                          {({ field }) => (
                            <TextArea
                              {...field}
                              placeholder={selectLangJson.text_here}
                              label="Message"
                              rows="6"
                              optional={false}
                              feildLoading={false}
                              value={formikBag.values.body}
                              onChange={(e) => {
                                formikBag.setFieldValue("body", e.target.value);

                                formikBag.setFieldTouched("body", true, false);
                                setErrorObj({});
                              }}
                              error={
                                formikBag.touched.body && formikBag.errors.body
                                  ? formikBag.errors.body
                                  : null
                              }
                              erroros={errorObj.body}
                            />
                          )}
                        </Field>
                      </UE.InputBox>
                    </UE.InputBoxContainer>
                    <CDD.ButtonGroup
                      style={{ marginTop: "0rem" }}
                      themeColor={theme}
                    >
                      <CDD.SaveButton
                        cursor={isLoading}
                        disabled={isLoading}
                        className="buttonload"
                        type="submit"
                      >
                        {isLoading ? (
                          <i class="fa fa-spinner fa-spin"></i>
                        ) : (
                          selectLangJson.send
                        )}
                      </CDD.SaveButton>
                    </CDD.ButtonGroup>
                  </Form>
                );
              }}
            </Formik>
          </SRS.SendMailBox>
        }
      />
      <Modal
        theme={theme}
        // maxWidth={"750px"}
        isOpen={isOpensTwo}
        component={
          <CDS.DownloadContainer>
            <img src={downloadIconOragnge} alt="" />
            <p className="title">{selectLangJson.download_selected_item}.</p>
            <MS.DownloadButton
              onClick={() => {
                setIsOpensTwo(false);
              }}
            >
              <img src={whiteDownload} alt="" />
              {selectLangJson.download}
            </MS.DownloadButton>
          </CDS.DownloadContainer>
        }
      />

      <PopUp
        isOpen={opens}
        onClose={handleCloses}
        maxWidth="400px"
        title={
          <>
            <SEC.WarnIconBox>
              <img src={warnIcon} alt="danger" />
            </SEC.WarnIconBox>
          </>
        }
        content={
          <>
            <SEC.InnerSection themeColor={theme}>
              <SEC.Title style={{ textAlign: "center" }} themeColor={theme}>
                {selectLangJson.are_you_sure}!
              </SEC.Title>
              {/* <SEC.TitleDesc themeColor={theme}>
                (Category contains associated content or)
              </SEC.TitleDesc> */}
              <SEC.Subtitle style={{ textAlign: "center" }} themeColor={theme}>
                “
                {
                  selectLangJson.deleting_this_learner_will_remove_all_associated_data_and_cannot_be_reversed
                }
                ”.
              </SEC.Subtitle>
              <SEC.BtnGroup>
                <SEC.ComButton
                  themeColor={theme}
                  onClick={() => setOpens(false)}
                  type="button"
                >
                  {selectLangJson.cancel}
                </SEC.ComButton>
                <SEC.ComButton
                  themeColor={theme}
                  color={"#FFFFFF"}
                  borderColor={"#B3B9C6"}
                  backColor={"#E0271A"}
                  onClick={() => {
                    deleteComit(
                      selectedRowKeys.length > 0 ? selectedRowKeys : [listId]
                    );
                  }}
                >
                  {selectLangJson.delete}
                </SEC.ComButton>
              </SEC.BtnGroup>
            </SEC.InnerSection>
          </>
        }
      />

      <PopUp
        isOpen={showsucessDownload}
        onClose={handleClose}
        maxWidth="400px"
        title={
          <>
            <SEC.SuccessIconBoxTwo>
              <img src={theme ? successModal : successModal} alt="danger" />
            </SEC.SuccessIconBoxTwo>
          </>
        }
        content={
          <SEC.InnerSection>
            <SEC.TitleTwo themeColor={theme}>{selectLangJson.great}!</SEC.TitleTwo>

            <SEC.Subtitle style={{ textAlign: "center" }} themeColor={theme}>
              {selectLangJson.your_excel_has_been_downloaded_successfully}.
            </SEC.Subtitle>
            <SEC.GreenContinue
              onClick={() => {
                setShowsucessDownload(false);
              }}
            >
              {selectLangJson.continue}
            </SEC.GreenContinue>
          </SEC.InnerSection>
        }
      />
      <PopUp
        isOpen={showsucessSend}
        onClose={handleClose}
        maxWidth="400px"
        title={
          <>
            <SEC.SuccessIconBoxTwo>
              <img src={theme ? successModal : successModal} alt="danger" />
            </SEC.SuccessIconBoxTwo>
          </>
        }
        content={
          <SEC.InnerSection>
            <SEC.TitleTwo themeColor={theme}>{selectLangJson.great}!</SEC.TitleTwo>

            <SEC.Subtitle themeColor={theme}>
              {selectLangJson.notification_has_been_sent_successfully}.
            </SEC.Subtitle>
            <SEC.GreenContinue
              onClick={() => {
                setShowsucessDownload(false);
              }}
            >
              {selectLangJson.continue}
            </SEC.GreenContinue>
          </SEC.InnerSection>
        }
      />
      <PopUp
        isOpen={showsucessMail}
        onClose={handleClose}
        maxWidth="400px"
        title={
          <>
            <SEC.SuccessIconBoxTwo>
              <img src={theme ? successModal : successModal} alt="danger" />
            </SEC.SuccessIconBoxTwo>
          </>
        }
        content={
          <SEC.InnerSection>
            <SEC.TitleTwo themeColor={theme}>{selectLangJson.great}!</SEC.TitleTwo>

            <SEC.Subtitle themeColor={theme} style={{ textAlign: "center" }}>
              {selectLangJson.mail_has_been_sent_successfully}.
            </SEC.Subtitle>
            <SEC.GreenContinue
              onClick={() => {
                setShowsucessMail(false);
              }}
            >
              {selectLangJson.continue}
            </SEC.GreenContinue>
          </SEC.InnerSection>
        }
      />
      <PopUp
        isOpen={deleteError.state}
        onClose={handleClose}
        maxWidth="400px"
        title={
          <>
            <SEC.SuccessIconBoxTwo>
              <img src={theme ? warnIcon : warnIcon} alt="danger" />
            </SEC.SuccessIconBoxTwo>
          </>
        }
        content={
          <SEC.InnerSection>
            <SEC.TitleTwo themeColor={theme}>{selectLangJson.oops}!</SEC.TitleTwo>

            <SEC.Subtitle themeColor={theme}>{deleteError.msg}</SEC.Subtitle>
            <SEC.GreenContinue
              onClick={() => {
                setDeleteError({
                  state: false,
                  msg: "",
                });
              }}
            >
              {selectLangJson.back}
            </SEC.GreenContinue>
          </SEC.InnerSection>
        }
      />
      <PopUp
        isOpen={showsucess}
        onClose={handleClose}
        maxWidth="400px"
        title={
          <>
            <SEC.SuccessIconBoxTwo>
              <img src={theme ? successModal : successModal} alt="danger" />
            </SEC.SuccessIconBoxTwo>
          </>
        }
        content={
          <SEC.InnerSection>
            <SEC.TitleTwo themeColor={theme}>{selectLangJson.great}!</SEC.TitleTwo>

            <SEC.Subtitle themeColor={theme} style={{ textAlign: "center" }}>
              {selectLangJson.your_data_has_been_successfully_saved}.
            </SEC.Subtitle>
            <SEC.GreenContinue
              onClick={() => {
                setShowsucess(false);
              }}
            >
              {selectLangJson.continue}
            </SEC.GreenContinue>
          </SEC.InnerSection>
        }
      />
    </>
  );
};

export default CourseDetail;
