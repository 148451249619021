import React, { useState } from "react";
import styled from "styled-components/macro";
import errorImage from "../assets/images/error.png";
import downArrowIcon from "../assets/images/downArrow.png";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const InputBorder = styled.div`
  border: ${(props) =>
    props.error
      ? "1px solid red"
      : props.themeColor
      ? "1px solid #333741"
      : "1px solid #D7DAE0"};
  border-radius: 10px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  height: auto;
  padding: 14px 10px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};

  img {
    height: 18px;
    width: 18px;
  }

  input {
    font: 1.1rem;
    width: 100%;
  }
  input::placeholder {
    font-size: 0.8rem !important;
    color: ${(props) => (props.themeColor ? "#94969C" : "#8A94A6")};
    text-transform: capitalize;
  }
`;
export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.7rem;
`;
export const InputLable = styled.label`
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 0.3rem;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
  margin-bottom: 0.5rem;
  text-transform: capitalize;

  @media (max-width: 351px) {
    font-size: 0.8rem;
  }
`;
export const BottomText = styled.label`
  font-size: 0.7rem;
  margin-top: 0.3rem;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#667085")};
  text-transform: capitalize;

  @media (max-width: 351px) {
    font-size: 0.8rem;
  }
`;
export const InBox = styled.div`
  display: flex;
  color: ${(props) => (props.themeColor ? "#94969C" : "#8A94A6")};
  font-size: 0.8rem;
  align-items: center;
  width: 10%;

  img {
    height: 9px;
    width: 9px;
    object-fit: contain;
    margin-left: 4px;
  }
`;
export default function Input(props) {
  const {
    error,
    type,
    icon,
    noBorderBottom,
    label,
    star,
    optional,
    bottomText,
    erroros,
    value,
    feildLoading,
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const theme = useSelector((state) => state?.isDark);

  const skeletonBaseColor = theme ? "#1F242F" : "#EDEEF1";
  const skeletonHighlightColor = theme ? "#1F242F" : "#EDEEF1";
  return (
    <>
      <InputBox>
        {feildLoading ? (
          <Skeleton
            width={80}
            height={18}
            style={{ marginBottom: "5px" }}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
          />
        ) : (
          <InputLable themeColor={theme}>
            {label}
            {star ? <span style={{ color: "#F6591C" }}>*</span> : ""}
            {optional ? (
              <span style={{ color: "#667085" }}> (Optional)</span>
            ) : (
              ""
            )}
          </InputLable>
        )}
        {feildLoading ? (
          <Skeleton
            width={"100%"}
            height={120}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
            style={{ marginBottom: "5px" }}
          />
        ) : (
          <InputBorder error={error || erroros} themeColor={theme}>
            <textarea
              {...props}
              type={type ? (showPassword ? "text" : type) : "text"}
              style={{
                textAlign: "left",
                border: "none",
                outline: "none",
                width: "100%",
                backgroundColor: "transparent",
                color: theme ? "#FFFFFF" : "#161b26",
              }}
            />
            {type === "password" ? (
              <i
                onClick={() => setShowPassword(!showPassword)}
                className={`fa ${
                  !showPassword ? "ph ph-eye-slash" : "ph ph-eye"
                }`}
              ></i>
            ) : (
              ""
            )}
            {/* {error ? <img src={errorImage} alt="" /> : ""} */}
          </InputBorder>
        )}
        {feildLoading ? (
          <Skeleton
            width={"10%"}
            height={18}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
            style={{ marginBottom: "5px" }}
          />
        ) : bottomText ? (
          <BottomText>{bottomText} characters only</BottomText>
        ) : (
          ""
        )}

        {error || erroros ? (
          <p
            style={{
              paddingTop: 5,
              fontSize: 11,
              color: "red",
              marginBottom: erroros ? 10 : 0,
            }}
          >
            {error || erroros}
          </p>
        ) : null}
      </InputBox>
    </>
  );
}
