import styled from "styled-components/macro";
import {
  commonStyles,
  colorProvider,
  fontProvider,
  fontWeight,
} from "../utils/StylePropertiy";

export const ModalContainer = styled.div`
  height: ${commonStyles.het};
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  background-color: ${(props) =>
    props.themeColor ? colorProvider.darkBlue : colorProvider.transColor};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  overflow: auto;
`;
export const InnerBox = styled.div`
  width: ${commonStyles.wid};
  max-width: ${(props) => props.maxWidth};
  min-width: 400px;
  min-height: 300px;
  background-color: ${(props) =>
    props.backColor
      ? "transparent"
      : props.themeColor
      ? colorProvider.darkBlue
      : colorProvider.white};
  border-radius: ${commonStyles.sec_br};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_fs};
  flex-direction: column;
  overflow-y: hidden;
  padding: 1.5rem;
  margin-top: 6rem;
  @media (max-width: 400px) {
    min-width: auto;
  }
`;
export const InnerBoxEPCom = styled.div`
  width: ${commonStyles.wid};
  max-width: ${(props) => props.maxWidth};
  min-width: 400px;
  min-height: 300px;

  background-color: ${(props) =>
    props.themeColor ? colorProvider.darkBlue : colorProvider.white};
  border-radius: ${commonStyles.sec_br};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_fs};
  flex-direction: column;
  overflow-y: hidden;
  padding: 1.5rem;
  margin-top: 6rem;
  @media (max-width: 400px) {
    min-width: auto;
  }
`;
export const CrossRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props) => (props.title ? "space-between" : "flex-end")};
  align-items: center;
  height: 20px;
  background-color: ${(props) =>
    props.themeColor ? colorProvider.darkBlue : colorProvider.white};
  padding-bottom: ${(props) => (props.title ? "1rem" : "0")};
  margin-bottom: 1rem;
  border-bottom: ${(props) =>
    props.title
      ? props.themeColor
        ? "1px solid #333741"
        : "1px solid #d7dae0"
      : "0"};

  p {
    font-size: ${fontProvider.zeroNin};
    font-weight: ${fontWeight.six};
    color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
    text-transform: capitalize;
  }
  img {
    height: 17px;
    width: 17px;
    border-radius: 50%;
    cursor: pointer;
  }
`;
export const LeaderContainer = styled.div`
  background-color: ${(props) =>
    props.themeColor ? colorProvider.darkBlue : colorProvider.white};
  display: ${commonStyles.ds_fx};
  width: ${commonStyles.wid};
  flex-direction: ${commonStyles.fd_col};
  padding: ${fontProvider.oneTwo};
  border-radius: ${commonStyles.sec_br};
  box-shadow: 0px 1px 2px 0px #0000000d;
`;
export const LeaderContainerModal = styled.div`
  background-color: ${(props) =>
    props.themeColor ? colorProvider.darkBlue : colorProvider.white};
  display: ${commonStyles.ds_fx};
  width: ${commonStyles.wid};
  flex-direction: ${commonStyles.fd_col};
  padding: ${fontProvider.oneTwo};
  border-radius: ${commonStyles.sec_br};
  box-shadow: 0px 1px 2px 0px #0000000d;
  min-width: 900px;
  height: 600px;

  .rpv-core__inner-page {
    overflow: hidden !important;
  }
`;
export const LeaderHeader = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.jc_c};
  border-bottom: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackTwo}`
      : commonStyles.com_b};
  padding-bottom: ${fontProvider.zeroEig};
`;
export const HeaderBox = styled.div`
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};

  .head-title {
    font-size: ${fontProvider.oneThree};
    font-weight: ${fontWeight.six};
    text-align: ${commonStyles.jc_l};
    color: ${(props) =>
      props.themeColor ? colorProvider.white : colorProvider.blackCol};
    font-family: EB Garamond;
    @media (max-width: 400px) {
      font-size: ${fontProvider.oneTwo};
    }
  }
  .head-desc {
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.five};
    text-align: ${commonStyles.jc_l};
    color: ${(props) =>
      props.themeColor ? colorProvider.greyOne : colorProvider.dateColor};
    @media (max-width: 400px) {
      font-size: ${fontProvider.zeroSev};
    }
  }
`;
export const CrossButton = styled.div`
  cursor: ${commonStyles.pointer};
  img {
    height: 12px;
    width: 12px;
    object-fit: ${commonStyles.ob_con};
  }
`;
export const TopRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.jc_fe};
`;
export const NoData = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;

  p {
    font-size: 1rem;
    font-weight: 500;
    color: ${(props) =>
      props.activeColor ? colorProvider.white : colorProvider.whiteTwo};
  }
`;
export const CommonTopper = styled.div`
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.jc_c};
  flex-direction: ${commonStyles.fd_col};
  margin-top: ${fontProvider.oneTwo};
`;
export const ComLearnerBoard = styled.div`
  height: 110px;
  width: 110px;
`
export const CustProfile = styled.div`
  /* This creates a larger "background" that can show through as a border. Adjust padding to control "border" size */
  /* padding: 2px; */
  border-radius: 50%;

  /* Gradient background */
  background-image: linear-gradient(156.58deg, #fcfcfd 17.17%, #b7b8be 84.45%);

  /* Ensuring the gradient background only shows within the border area */
  background-clip: padding-box;

  /* Cover up the excess background to only show it as a border */
  box-shadow: 0 0 0 2px white; /* Adjust the color to match your component's background */

  img {
    height: 110px;
    width: 110px;
    border-radius: 50%;
    display: block; /* This ensures the img occupies the full size without extra space */
    object-fit: cover; /* Adjusts the image's fit within the container without stretching it */
    @media (max-width: 500px) {
      width: 85px;
      height: 85px;
    }
    @media (max-width: 400px) {
      width: 65px;
      height: 65px;
    }
  }
`;
export const Crown = styled.div`
  margin-bottom: 5px;

  img {
    height: auto;
    width: 60px;
    @media (max-width: 500px) {
      width: 40px;
    }
    @media (max-width: 400px) {
      width: 30px;
    }
  }
`;
export const CustProfileGold = styled.div`
  /* This creates a larger "background" that can show through as a border. Adjust padding to control "border" size */
  /* padding: 2px; */

  /* Gradient background */

  /* Create space for the "gradient border" inside the solid border */

  /* Apply the gradient as a background */
  background-image: linear-gradient(
      293.93deg,
      #b98d5f 21.63%,
      #ffffff 32.3%,
      #d3b790 69.28%,
      #f7e1c2 75.48%,
      #d3b790 80.66%
    ),
    linear-gradient(
      258.85deg,
      #cfac82 59.85%,
      #fcedd7 66.98%,
      #f8edc0 71.9%,
      #cfac82 73.96%,
      #cfac82 82.85%
    );

  /* Ensure the background (gradient) is clipped to the padding box */
  background-clip: content-box;

  /* Optionally, if you want rounded borders */
  border-radius: 50%;

  /* Adjustments for the inner content */
  display: flex;
  justify-content: center;
  align-items: center;
  /* Height and width as needed */
  height: 135px;
  width: 135px;
  @media (max-width: 500px) {
    width: 110px;
    height: 110px;
  }
  @media (max-width: 400px) {
    width: 90px;
    height: 90px;
  }

  /* Ensuring the gradient background only shows within the border area */
  /* background-clip: padding-box; */

  /* Cover up the excess background to only show it as a border */
  /* box-shadow: 0 0 0 2px white;  */
  /* Adjust the color to match your component's background */

  img {
    height: 130px;
    width: 130px;
    border-radius: 50%;
    display: block; /* This ensures the img occupies the full size without extra space */
    object-fit: cover; /* Adjusts the image's fit within the container without stretching it */
    @media (max-width: 500px) {
      width: 105px;
      height: 105px;
    }
    @media (max-width: 400px) {
      width: 85px;
      height: 85px;
    }
  }
`;
export const NumberBox = styled.div`
  margin-top: -1.4rem;
  img {
    height: 40px;
    width: 40px;
    object-fit: ${commonStyles.ob_con};
  }
`;
export const ProfileTitle = styled.p`
  font-size: ${fontProvider.zeroEig};
  font-weight: ${fontWeight.six};
    color: ${(props) =>
      props.themeColor ? colorProvider.white : colorProvider.blackCol};
  margin-top: ${fontProvider.zeroFive};
`;
export const UserList = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  /* justify-content: ${commonStyles.jc_sb}; */
  align-items: ${commonStyles.jc_c};
  flex-direction: ${commonStyles.fd_col};
  margin-top: ${fontProvider.oneTwo};
  gap: ${fontProvider.zeroSix};
`;
export const UserRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.jc_c};
  background-color: ${colorProvider.blackThree};
  border-radius: ${commonStyles.sec_br};
  padding: ${fontProvider.zeroSev} ${fontProvider.zeroTen};
  border-bottom: ${(props) =>
    props.activeColor ? "1px solid #F05D24" : "none"};
`;
export const ProfileNameBox = styled.div`
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.jc_c};
  gap: ${fontProvider.zeroSix};

  img {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: 1.5px solid ${colorProvider.blackTwo};
    object-fit: ${commonStyles.ob_con};
  }

  p {
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.five};
    color: ${colorProvider.whiteTwo};
    @media (max-width: 780px) {
      font-size: ${fontProvider.zeroSev};
    }
  }
`;
export const CicleNumber = styled.div`
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_c};
  background-color: ${(props) =>
    props.activeColor ? colorProvider.navLink : colorProvider.blackTwo};
  border-radius: 50%;
  padding: 5px 10px;

  p {
    font-size: ${fontProvider.zeroTen};
    font-weight: ${fontWeight.five};
    color: ${(props) =>
      props.activeColor ? colorProvider.white : colorProvider.whiteTwo};

    @media (max-width: 780px) {
      font-size: ${fontProvider.zeroEig};
    }
  }
`;
export const LeaderDesc = styled.div`
  max-height: 400px;
  overflow-y: auto;
  margin: 1rem 0;
  p {
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.four};
    color: ${(props) =>
      props.theme ? colorProvider.whiteTwo : colorProvider.dateColor};
    line-height: 20px;
  }
`;
export const ViewCon = styled.div`
  width: 100%;
  overflow: auto;
  max-height: 550px;

  ::-webkit-scrollbar {
    height: 6px;
    background-color: #f1f1f1;
  }

  @media print {
    display: none;
  }
`;
