import React, { useState } from "react";
import styled from "styled-components/macro";
import leftArr from "../assets/images/leftArr.png";
import rightArr from "../assets/images/rightArr.png";
import { useSelector } from "react-redux";
import { Field } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const InputBorder = styled.div`
  border-radius: 10px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  margin-top: ${(props) => (props.error ? "0.7rem" : "0")};
  position: relative;

  img {
    height: 18px;
    width: 18px;
  }

  input {
    font: 1.1rem;
    visibility: hidden !important;
  }
  input::placeholder {
    font-size: 0.8rem !important;
    color: ${(props) => (props.themeColor ? "#94969C" : "#8A94A6")};
  }

  .react-datepicker-wrapper {
    width: 100% !important;
  }
`;
export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.7rem;

  .react-datepicker {
    background-color: ${(props) =>
      props.themeColor ? "#0c111d" : "#fff"} !important;
    /* background-color: #ffffff !important; */
    border: none !important;

    box-shadow: ${(props) =>
      props.themeColor
        ? "8px 3px 22px 10px #0000004d"
        : "8px 3px 22px 10px #9696961c"} !important;
    padding: 0.8rem 1rem !important;
    border-radius: 16px !important;
  }

  .react-datepicker__header {
    background-color: ${(props) =>
      props.themeColor ? "#0c111d" : "#fff"} !important;
    /* background-color: #ffffff !important; */
    border-bottom: none !important;
  }
  .react-datepicker__navigation {
    margin-top: 1.5rem !important;
    &::before {
      content: none;
    }
  }
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle {
    display: none !important;
  }

  .react-datepicker__current-month {
    border-bottom: 1px solid #d7dae0 !important;
    margin-bottom: 5px !important;
    padding-bottom: 6px !important;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-weight: 400 !important;
  }

  .react-datepicker__day,
  .react-datepicker__time-name {
    color: ${({ themeColor }) =>
      themeColor ? "#CECFD2" : "#0d2344"} !important;
    text-transform: uppercase !important;
    margin: 5px !important;
  }
  .react-datepicker__day-name {
    color: ${({ themeColor }) =>
      themeColor ? "#85888E" : "#0d2344"} !important;
    text-transform: uppercase !important;
    margin: 5px !important;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: #f05d24 !important;
    color: #ffffff !important; // White text
    border-radius: 50% !important;
  }
  .react-datepicker__navigation--previous {
    /* Add new arrow icon for the previous button */
    &::after {
      content: url(${leftArr}) !important;
    }
  }

  .react-datepicker__navigation--next {
    /* Add new arrow icon for the next button */
    &::after {
      content: url(${rightArr}) !important;
    }
  }
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--keyboard-selected:hover {
    border-radius: 50% !important;
    background-color: #f05d24 !important; // Darker blue on hover
  }

  .react-datepicker__day--disabled {
    /* color: #d3d3d3 !important; Grey color for disabled dates */
    color: ${({ themeColor }) =>
      themeColor ? "#85888E" : "#d3d3d3"} !important;
    pointer-events: none;
    /* background-color: #f9f9f9 !important; Light grey background for disabled dates */
    background-color: ${(props) =>
      props.themeColor ? "#0c111d" : "#fff"} !important;
  }

  /* Optional: Custom styling to highlight disabled dates in red */
  .react-datepicker__day--disabled:hover {
    background-color: #ffcccc !important; /* Light red background on hover for disabled dates */
  }
  .react-datepicker__day:hover {
    border-radius: 0.3rem;
    /* background-color: #f0f0f0; */
    background-color: ${(props) => (props.themeColor ? "#F05D24" : "#f0f0f0")};
  }
`;
export const InputLable = styled.label`
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 0.3rem;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
  margin-bottom: 0.5rem;
  text-transform: capitalize;

  @media (max-width: 351px) {
    font-size: 0.8rem;
  }
`;
export const InBox = styled.div`
  display: flex;
  color: ${(props) => (props.themeColor ? "#94969C" : "#8A94A6")};
  font-size: 0.8rem;
  align-items: center;
  width: 7%;
  /* z-index: -2; */

  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
    margin-left: 4px;
  }
`;
export const CustomDatePicker = styled(DatePicker)`
  border: none;
  outline: none;
  padding: 6px 5px;
  width: 100%;
  background-color: transparent !important;
  color: ${(props) => (props.themeColor ? "#FFFFFF" : "#161b26")};
  /* background-color: ${(props) =>
    props.themeColor ? "#161b26 !important" : "#ffffff !important"}; */
`;

export const HeadRow = styled.div`
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.8px solid #d7dae0;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;

  button {
    border: none;
    background-color: transparent;
    outline: none;
  }

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
  }

  span {
    color: ${({ themeColor }) =>
      themeColor ? "#CECFD2" : "#0D2344"} !important;
    font-weight: 400;
    font-size: 1rem;
  }
`;
export default function YearInput(props) {
  const { error, type, icon, noBorderBottom, label, star, optional, theme } =
    props;
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <>
      <InputBox themeColor={theme}>
        <InputBorder error={error} themeColor={theme}>
          <CustomDatePicker
            themeColor={theme}
            dateFormat="dd/MM/yyyy"
            formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 3)}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <HeadRow themeColor={theme}>
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  <img src={leftArr} alt="" />
                </button>
                <span>
                  {date.toLocaleString("default", { month: "long" })}{" "}
                  {date.getFullYear()}
                </span>
                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  <img src={rightArr} alt="" />
                </button>
              </HeadRow>
            )}
            style={{ textAlign: "right", width: "100%" }}
            onCalendarOpen={() => setOpen(true)}
            onClickOutside={() => setOpen(false)}
            onCalendarClose={() => setOpen(false)}
            open={open}
            autoComplete="off"
            {...props}
          />
          {icon ? (
            <InBox themeColor={theme} onClick={() => setOpen(!open)}>
              <img src={icon} alt="" />
            </InBox>
          ) : (
            ""
          )}

          {/* {error ? <img src={errorImage} alt="" /> : ""} */}
        </InputBorder>
        {error ? (
          <p
            style={{
              paddingTop: 5,
              fontSize: 11,
              color: "red",
            }}
          >
            {error}
          </p>
        ) : null}
      </InputBox>
    </>
  );
}
