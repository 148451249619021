import React, { useState } from "react";
import styled from "styled-components/macro";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const InputBorder = styled.div`
  border: ${(props) =>
    props.error
      ? "1px solid red"
      : props.themeColor
      ? "1px solid #333741"
      : "1px solid #D7DAE0"};
  /* margin-top: ${(props) => (props.error ? "10px" : "0")}; */
  border-radius: 10px;
  opacity: 1;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  padding: 2px;
  /* justify-content: space-between; */
  width: auto;
  height: auto;
  /* padding: ${(props) => (props.backColor ? "0" : "12px 10px")}; */
  border-radius: 8px;
  width: 100%;
  display: flex;
  background-color: ${(props) =>
    props.themeColor ? "#0C111D" : props.backColor ? "#F6F7F9" : "#ffffff"};

  img {
    height: 18px;
    width: 18px;
  }

  input {
    font: 1.1rem;
    background-color: ${(props) =>
      props.themeColor ? "transparent" : "#ffffff"};
  }
  input::placeholder {
    font-size: 0.8rem !important;
    color: ${(props) => (props.themeColor ? "#94969C" : "#8A94A6")};
    text-transform: capitalize;
  }
`;
export const LoaderBox = styled.div`
  width: 100%;
  padding: 12px 10px;
`;
export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.7rem;
`;
export const InputLable = styled.label`
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 0.3rem;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
  margin-bottom: 0.5rem;
  text-transform: capitalize;

  @media (max-width: 351px) {
    font-size: 0.8rem;
  }
`;
export const InBox = styled.div`
  display: flex;
  color: ${(props) => (props.themeColor ? "#94969C" : "#8A94A6")};
  font-size: 0.8rem;
  align-items: center;
  width: 5%;

  img {
    height: 7px;
    width: 7px;
    object-fit: contain;
    margin-left: 4px;
  }
`;
export const BottomText = styled.label`
  font-size: 0.7rem;
  margin-top: 0.3rem;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#667085")};
  text-transform: capitalize;

  @media (max-width: 351px) {
    font-size: 0.8rem;
  }
`;

const StyledPhoneInput = styled(PhoneInput)`
  .form-control {
    border: none !important;
    padding: 1rem 2.6rem !important;
    background-color: transparent !important;
    color: ${(props) => (props.themeColor ? "#fff" : "#000")} !important;
  }
  
  .flag-dropdown {
    background-color: transparent !important;
    border: none !important;
    border-right: 1px solid #cacaca !important;
    height: auto !important;
  }

  .form-control:focus {
    border-color: green !important;
  }
`;
export default function InputMobile(props) {
  const {
    error,
    type,
    icon,
    noBorderBottom,
    label,
    star,
    optional,
    erroros,
    cursorNot,
    backColor,
    bottomText,
    value,
    feildLoading,
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const theme = useSelector((state) => state?.isDark);

  const skeletonBaseColor = theme ? "#1F242F" : "#EDEEF1";
  const skeletonHighlightColor = theme ? "#1F242F" : "#EDEEF1";

  // const value = null;
  return (
    <>
      <InputBox>
        {feildLoading ? (
          <Skeleton
            width={80}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
            height={18}
            style={{ marginBottom: "5px" }}
          />
        ) : (
          <InputLable themeColor={theme}>
            {label}
            {star ? <span style={{ color: "#F6591C" }}>*</span> : ""}
            {optional ? (
              <span style={{ color: "#667085" }}> (Optional)</span>
            ) : (
              ""
            )}
          </InputLable>
        )}

        {feildLoading ? (
          <Skeleton
            width={"100%"}
            height={40}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
            style={{ marginBottom: "5px" }}
          />
        ) : (
          <InputBorder
            error={error || erroros}
            themeColor={theme}
            backColor={backColor}
            cursorNot={cursorNot}
            style={{
              backgroundColor: theme
                ? cursorNot
                  ? "#161B26"
                  : "#0C111D"
                : cursorNot
                ? "#F6F7F9"
                : "#ffffff",
            }}
          >
            {/* <Field
              {...props}
              type={type ? (showPassword ? "text" : type) : "text"}
              style={{
                textAlign: "left",
                border: "none",
                outline: "none",
                width: backColor ? "100%" : "100%",
                backgroundColor: theme
                  ? cursorNot
                    ? "#161B26"
                    : "#0C111D"
                  : cursorNot
                  ? "#F6F7F9"
                  : "#ffffff",
                color: theme ? "#FFFFFF" : "#161b26",
                cursor: cursorNot ? "not-allowed" : "",
                opacity: cursorNot ? "0.7" : "1",
              }}
              disabled={cursorNot}
              autoComplete="off"
            /> */}
            <StyledPhoneInput
              {...props}
              country="in"
              type="phone"
              // className=''
              countryCodeEditable={false}
              themeColor={theme}
              disabled={cursorNot}
            />
          </InputBorder>
        )}

        <BottomText>{bottomText}</BottomText>
        {error || erroros ? (
          <p
            style={{
              paddingTop: 5,
              fontSize: 11,
              color: "red",
              marginBottom: erroros ? 10 : 0,
            }}
          >
            {error || erroros}
          </p>
        ) : null}
      </InputBox>
    </>
  );
}
