export const RMlogin = {
  login_url: "client/login",
  otp_verification: "client/otpVerification",
  forget_password: "client/forgetPassword",
  reset_password: "client/private/passwordChange",
  login_mobile: "client/loginMobile",
  login_azure: "azure/clientLogin",
  getBanner: "admin/banner/public/exitsBannerDetails",
};
export const Users = {
  userList: "client/userList",
  userRegister: "client/register",
  editRegister: "client/userUpdate",
  detailUser: "client/userDetails/",
};
export const Course = {
  getMyCourses: "client/private/my_course",
  getCourseContent: "client/private/my_course/",
  getLearner: "client/private/course/learners",
  getLearnerDetail: "client/private/my_course/learner/details",
  getDepartment: "admin/department/list",
  getDesignation: "admin/designation/list",
  updateLanguage: "client/private/my_course/learner/edit/language",
  updateProfileImage: "client/private/my_course/learner/edit/profileImage",
  learnerUpdate: "client/private/my_course/learner/details/update",
  learnerAdd: "client/private/my_course/learners/add",
  courseMightInterest: "client/private/courseMightInterest",
  categoryMightInterest: "client/private/categorieMightInterest",
  getLeaderBoard: "client/private/my_course/leaderBoard",
  getBadges: "client/private/my_course/badge/",
  numberLicencePurchases: "client/private/my_course/numberofLicensesPurchased",
  numberOfBatches: "client/private/my_course/noOfBatches",
  coureActivated: "client/private/my_course/courseActivated",
  downloadCertificate: "client/private/my_course/genrateCerficate/",
  getPolicy: "client/private/my_course/policyDocument/",
  getInfo: "client/private/my_course/info/details/",
  createPolicy: "client/private/my_course/createPolicyDocument",
  getLearnerReports: "client/private/my_course/learner/learnerReportGenrate",
  reportGenerate: "client/private/my_course/learners/reportGenrate",
  deleteLearner: "client/private/my_course/learners/delete",
  deletePolicy: "client/private/my_course/policyDocument/delete",
  sendLearnerMail: "client/private/my_course/sendEmail",
  getImpersonateLink: "client/private/my_course/learner/impersonateRedirect",
};
export const HomePageAPI = {
  getDashboardInfo: "client/private/dashboard/info",
};
export const ProfileInfo = {
  getProfileDetails: "client/private/profile/details",
  updateProfile: "client/private/profile/update",
  changePassword: "client/private/profile/changePassword",
  getCompanyDetails: "client/private/companyDetails",
  getNotification: "client/private/listNotifications",
};
export const NotificationAPI = {
  sendNotification: "client/private/my_course/learners/send/Notification",
  sendIndividual: "client/private/my_course/learner/send_notification",
};

export const Categorys = {
  getCategory: "client/private/my_course/categories/list",
};
export const Commiittee = {
  getCommiittee: "client/private/my_course/commitee/list",
  deleteCommiittee: "client/private/my_course/commitee/delete",
  addCommiittee: "client/private/my_course/commitee/create",
  getStatus: "client/private/my_course/commitee/statusInfo/",
  statusUpdate: "client/private/my_course/commitee/statusUpdate",
};
export const ComMember = {
  getComMember: "client/private/my_course/commitee/member/list",
  addComMember: "client/private/my_course/commitee/member/create",
  delComMember: "client/private/my_course/commitee/member/delete",
};
export const SurveyForm = {
  getClientSurevyList: "/clientSurevyList/",
  surveyCreate: "/clientSurevyCreate",
};

export const Feedback = {};
export const ClientGroups = {};
export const Client = {};
export const Courses = {
  getCategoryCourse: "client/private/my_course/course",
};
export const Reports = {
  // getCourseReport: "client/report/courseWise",
  getCourseReport: "client/private/my_course/report",
  getEntryWise: "client/private/my_course/learners/reportGenrate",
};
