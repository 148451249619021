import styled from "styled-components/macro";

export const DeleteRow = styled.div`
  width: 100%;
  height: 100%;
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;

  /* margin-top: -10rem; */

  @media (max-width: 769px) {
    display: none;
  }
`;
export const DeleteInner = styled.div`
  /* width: 100%;
    height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 50px;
  border: ${(props) =>
    props.themeColor ? "1px solid transparent" : "1px solid #d7dae0"};
  border-radius: 8px;
  padding: 8px 10px;
  background-color: ${(props) => (props.themeColor ? "#333741" : "#ffffff")};
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  /* top: 0; */
  /* z-index: 999; */
`;
export const SelectedBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const SelectedText = styled.div`
  font-weight: 500;
  font-size: 0.8rem;
  color: #667085;
  display: flex;
  align-items: center;
  margin-right: 10px;

  .roundTxt {
    border-radius: 50%;
    background-color: #f6591c;
    padding: ${(props) => (props.paDD ? "8px 10px" : "5px 10px")};
    color: ${(props) => (props.themeColor ? "#94969C" : "#ffffff")};
    margin-right: 5px;
  }
`;
export const DeleteButton = styled.div`
  font-weight: 500;
  font-size: 0.8rem;
  color: #e0271a;
  display: flex;
  align-items: center;
  border-left: 2px solid #b3b9c6;
  cursor: pointer;
  /* margin-left: 10px; */

  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
    margin: 0 7px 0 15px;
  }
`;
