import React, { useState, useEffect, useCallback, useRef } from "react";
import * as CDS from "../../../styles/CourseDetailStyled";
import * as CDD from "../../../styles/CourseDetStyled";
import * as SEC from "../../../styles/SectionStyled";
import * as LS from "../../../styles/LearnerStyled";
import CourseView from "./CourseView";
import downloadIcon from "../../../assets/images/downloadIcon.png";
import mailIcon from "../../../assets/images/mailIcon.png";
import shareIcon from "../../../assets/images/shareIcon.png";
import comBadge from "../../../assets/images/comBadge.png";
import Avatar from "../../../assets/images/Avatar.png";
import orangeCloud from "../../../assets/images/orangeCloud.png";
import Input from "../../../components/InputAdd";
import InputAddTwo from "../../../components/Inputs/InputAddTwo";
import Switch from "../../../components/Switch/Switch";
import darkCheckboxBaseIcon from "../../../assets/images/dark_base.png";
import darkcheckIcon from "../../../assets/images/darkcheckIcon.png";
import checkboxIcon from "../../../assets/images/Checkbox.png";
import checkboxBaseIcon from "../../../assets/images/_Checkbox_base.png";
import binTable from "../../../assets/images/binTable.png";
import binTwo from "../../../assets/images/binTwo.png";
import darkDownload from "../../../assets/images/darksDownload.png";
import darkEmail from "../../../assets/images/darkEmail.png";
import darkShare from "../../../assets/images/darkShare.png";
import darkBin from "../../../assets/images/darkBin.png";
import axios from "../../../utils/axios";
import { useSelector, useDispatch } from "react-redux";
import { UploadFile } from "../../../utils/uploadImage";
import { Course, NotificationAPI } from "../../../utils/api";
import { get } from "lodash";
import { Formik, Field, Form } from "formik";
import PageSelect from "../../../components/PageSelect";
import UploadTrailerOnButton from "../../../components/UploadTrailerOnButton";
import { Modal } from "../../../components/SuccessModal/Modal";
import ModalThree from "../../../components/Modal/Modal";
import ModalTwo from "../../../components/Modal/Modal";
import success from "../../../assets/images/success.gif";
import darkSuccess from "../../../assets/images/success-dark.gif";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import profileDemo from "../../../assets/images/profileDemo.png";
import { PopUp } from "../../../components/PopUp/PopUp";
import successModal from "../../../assets/images/successModal.png";
import * as SRS from "../../../styles/SurveyStyled";
import * as UE from "../../../styles/UserElement";
import { EmailSendValidator, notiValidator } from "../../../utils/validator";
import SelectNoti from "../../../components/SelectNoti";
import TextArea from "../../../components/InputDesciption";
import moment from "moment";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import bold from "../../../assets/images/editor/bold.png";
import dot_list from "../../../assets/images/editor/dot_list.png";
import head from "../../../assets/images/editor/head.png";
import editLight from "../../../assets/images/editLight.png";
import image from "../../../assets/images/editor/image.png";
import italic from "../../../assets/images/editor/italic.png";
import link from "../../../assets/images/editor/link.png";
import num_list from "../../../assets/images/editor/num_list.png";
import quote from "../../../assets/images/editor/quote.png";

const notiType = [
  { value: "warning", label: "Warning" },
  { value: "info", label: "Info" },
];
const CourseDetail = ({ navigate, theme, location, localState }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [stateData, setStateData] = useState(location?.state);
  console.log(stateData, "stateData stateData");
  const courseId = useSelector((state) => state?.courseId);
  const [learnerData, setLearnerData] = useState(null);
  const [departmentList, setDepartmentList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [currentPageDepart, setCurrentPageDepart] = useState(1);
  const [currentPageDesig, setCurrentPageDesig] = useState(1);
  const [errorObj, setErrorObj] = useState({});
  const [profileImage, setProfileImage] = useState(Avatar);
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [showsucess, setShowsucess] = useState(false);
  const [feildLoading, setFeildLoading] = useState(false);
  const [learnerLang, setLeanrnerLang] = useState([]);
  const [isMailOpen, setIsMailOpen] = useState(false);
  const [badgeData, setBadgeData] = useState([]);
  const [showsucessMail, setShowsucessMail] = useState(false);
  const [learnerValues, setLearnerValues] = useState({
    department: "",
    designation: "",
    reportingManager: "",
    city: "",
    active: false,
    profileImage: "",
    _id: null,
  });
  const [loginValues, setLoginValues] = useState({
    body: "",
    title: "",
    type: "",
    _id: stateData,
  });
  const [mailLoginVlaues, setMailLoginValues] = useState({
    // from: "",
    _id: courseId,
    languageId: "66262d5fac4634a8113234ae",
    subject: "",
    body: "",
    users: [stateData],
  });
  const [isOpens, setIsOpens] = useState(false);
  const pageLimit = 10;
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Process the file, e.g., upload to server, convert to base64, etc.
      console.log("File chosen:", file);
    }
  };
  const CustomIcons = {
    bold: `<img src="${bold}" alt="Bold" style="width: 12px; height: 12px;">`,
    italic: `<img src="${italic}" alt="Bold" style="width: 12px; height: 12px;">`,
    header: `<img src="${head}" alt="Bold" style="width: 12px; height: 12px;">`,
    headerTwo: `<img src="${head}" alt="Bold" style="width: 11px; height: 11px;">`,
    blockquote: `<img src="${quote}" alt="Bold" style="width: 16px; height: 12px;">`,
    link: `<img src="${link}" alt="Bold" style="width: 24px; height: 12px;">`,
    image: `<img src="${image}" alt="Bold" style="width: 18px; height: 18px; margin-left: 13px;">`,
    listBullet: `<img src="${dot_list}" alt="Bold" style="width: 30px; height: 30px;">`,
    listOrdered: `<img src="${num_list}" alt="Bold" style="width: 30px; height: 30px;">`,
    // Add more icons as needed
  };
  const reactQuillRef = useRef(null);
  const fileInputRef = useRef(null);
  // Assign custom icons to Quill
  Quill.import("ui/icons").bold = CustomIcons.bold;
  Quill.import("ui/icons").italic = CustomIcons.italic;
  Quill.import("ui/icons").header["1"] = CustomIcons.header; // For header 1
  Quill.import("ui/icons").header["2"] = CustomIcons.headerTwo; // For header 1
  Quill.import("ui/icons").blockquote = CustomIcons.blockquote;
  Quill.import("ui/icons").link = CustomIcons.link;
  Quill.import("ui/icons").image = CustomIcons.image;
  Quill.import("ui/icons").bullet = CustomIcons.listBullet;
  Quill.import("ui/icons").ordered = CustomIcons.listOrdered;
  const imageHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      if (input !== null && input.files !== null) {
        const file = input.files[0];
        const url = await UploadFile(file, setUploadProgress);
        const quill = reactQuillRef.current;
        if (quill) {
          const range = quill.getEditorSelection();
          range && quill.getEditor().insertEmbed(range.index, "image", url);
        }
      }
    };
  }, []);
  const modules = {
    toolbar: {
      container: [
        ["bold", "italic"], // Toggled buttons
        [{ header: 1 }], // Custom button values
        [{ header: 2 }], // Custom button values
        ["blockquote"],
        ["link", "image"], // Adding link and image support
        [{ list: "bullet" }, { list: "ordered" }], // List options
        [{ size: ["small", false, "large", "huge"] }], // Custom dropdown for text size
      ],
      handlers: {
        image: imageHandler,
      },
    },
  };

  const getLearnerDetail = async () => {
    setFeildLoading(true);
    let url = Course.getLearnerDetail;
    try {
      const { data } = await axios.post(url, {
        _id: stateData,
        courseId: courseId,
      });
      if (data?.result) {
        setLearnerData(data?.result[0]);
        setLearnerValues({
          department: get(data, "result[0].department", ""),
          designation: get(data, "result[0].designation", ""),
          reportingManager: get(data, "result[0].reportingManager", ""),
          city: get(data, "result[0].city", ""),
          active: get(data, "result[0].active", 0) === 1 ? true : false,
          _id: get(data, "result[0]._id", null),
        });
        setFeildLoading(false);
        setProfileImage(get(data, "result[0].profileImage", ""));
        setLeanrnerLang(get(data, "result[0].languages", []));
      }
    } catch (error) {
      setFeildLoading(false);
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    if (stateData && courseId) {
      getLearnerDetail();
    }
  }, []);
  const languageUpdate = async (e, action) => {
    let url = Course.updateLanguage;
    try {
      const { data } = await axios.post(url, {
        _id: stateData,
        courseId: courseId,
        languageId: e,
        action: action,
      });
      if (learnerLang?.length > 0) {
        setLeanrnerLang((prevValues) =>
          prevValues.map((item) =>
            item._id === e
              ? { ...item, select: action === "remove" ? false : true }
              : item
          )
        );
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  const updateProfileImage = async (e) => {
    let url = Course.updateProfileImage;
    try {
      const { data } = await axios.post(url, {
        _id: stateData,
        profileImage: e,
      });
      getLearnerDetail();
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  const handleLogin = async (values) => {
    let url = Course.learnerUpdate;
    setIsLoading(true);
    const formValues = {
      _id: values._id,
      active: values.active ? 1 : 0,
      courseId: courseId,
    };

    values.reportingManager &&
      (formValues["reportingManager"] = values.reportingManager);
    values.department && (formValues["department"] = values.department);
    values.designation && (formValues["designation"] = values.designation);
    values.city && (formValues["city"] = values.city);
    try {
      const { data } = await axios.post(url, formValues);
      setIsLoading(false);
      setShowsucess(true);
      setTimeout(() => {
        setShowsucess(false);
        navigate("/client-admin/manage-learners");
      }, 2000);
    } catch (error) {
      let errObj = {};
      error.response.data.errors?.map((val) => {
        console.log(val.path, "error val");
        if (!errObj[val.path]) {
          errObj[val.path] = val.msg;
        }
      });
      setErrorObj(errObj);
      setIsLoading(false);
    }
  };
  const handleClose = () => {
    setShowsucess(false);
    setIsOpens(false);
    setIsMailOpen(false);
    setShowsucessMail(false);
  };

  const skeletonBaseColor = theme ? "#1F242F" : "#EDEEF1";
  const skeletonHighlightColor = theme ? "#1F242F" : "#EDEEF1";
  const handleMail = async (values) => {
    try {
      let url = Course.sendLearnerMail;
      let formValue = {
        ...values,
        courseStatus: "all", // ["all","completed","inProgress","notstarted"]
        userStatus: "all", // "all","active","InActive"
        userSelectFilter: "selected",
      };

      const { data } = await axios.post(url, formValue);
      setShowsucessMail(true);
      setIsMailOpen(false);
      setTimeout(() => {
        setShowsucessMail(false);
      }, 2000);
    } catch (error) {}
  };
  const handleNotification = async (values) => {
    try {
      let url = NotificationAPI.sendIndividual;
      let formValue = {
        ...values,
      };
      console.log(formValue, "formValue formValue");
      const { data } = await axios.post(url, formValue);
      setShowsucess(true);
      setIsOpens(false);
      setTimeout(() => {
        setShowsucess(false);
      }, 2000);
    } catch (error) {
      console.log(error.response.data.message);
      let errObj = {};
      error.response.data.errors?.map((val) => {
        console.log(val.path, "error val");
        if (!errObj[val.path]) {
          errObj[val.path] = val.msg;
        }
      });
      setErrorObj(errObj);
    }
  };
  const downloadCertificate = async (e) => {
    setDownloadLoading(true);
    try {
      let url = `${Course.getLearnerReports}?learnerId=${stateData}&courseId=${courseId}`;
      const { data } = await axios({
        url: url,
        method: "GET",
        responseType: "blob", // Important: set response type as blob for binary data
      });
      const blob = new Blob([data], { type: "application/xlsx" });

      // Create a link element, set the download attribute and trigger a click
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "downloaded_file.xlsx"; // Set the name of the downloaded file
      document.body.appendChild(link);
      link.click();

      setDownloadLoading(false);
      // Clean up and remove the link element
      link.parentNode.removeChild(link);
    } catch (error) {
      setDownloadLoading(false);
      console.log(error.response.data.message);
    }
  };
  const getBadges = async () => {
    try {
      let url = `${Course.getBadges}${courseId}`;
      const { data } = await axios.get(url);
      if (data?.data) {
        setBadgeData(data?.data?.badge);
      } else {
        setBadgeData([]);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  const handleImpersonate = async () => {
    try {
      let url = `${Course.getImpersonateLink}/${stateData}`;
      const { data } = await axios.get(url);
      window.open(data);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  useEffect(() => {
    if (courseId) {
      getBadges();
    }
  }, [courseId]);
  return (
    <>
      <CDS.DetailContainer>
        {/* <CourseView
          theme={theme}
          courseId={courseId}
          navigate={navigate}
          localState={localState}
        /> */}
        <CDS.DetailCon>
          <CDS.DetailInnerCon>
            <LS.LearnerBox>
              <LS.ComLearner themeColor={theme}>
                <LS.FirstLearner themeColor={theme}>
                  <p>{localState.learners_detail}</p>
                  <LS.LearnerIconROw themeColor={theme}>
                    <div
                      className="icon-box"
                      onClick={() => {
                        downloadCertificate();
                      }}
                    >
                      {downloadLoading ? (
                        <i class="fa fa-spinner fa-spin"></i>
                      ) : (
                        <img src={theme ? darkDownload : downloadIcon} alt="" />
                      )}
                    </div>
                    <div
                      className="icon-box"
                      onClick={() => {
                        setIsMailOpen(true);
                      }}
                    >
                      <img src={theme ? darkEmail : mailIcon} alt="" />
                    </div>
                    <div
                      className="icon-box"
                      onClick={() => {
                        setIsOpens(true);
                      }}
                    >
                      <img src={theme ? darkShare : shareIcon} alt="" />
                    </div>
                  </LS.LearnerIconROw>
                </LS.FirstLearner>
                <LS.ColumnContainer>
                  {feildLoading ? (
                    <div>
                      <Skeleton
                        width={200}
                        baseColor={skeletonBaseColor}
                        highlightColor={skeletonHighlightColor}
                        height={28}
                        style={{
                          borderRadius: "5px",
                          margin: "0.3rem 2rem 0.3rem 0",
                        }}
                      />
                      <Skeleton
                        width={200}
                        baseColor={skeletonBaseColor}
                        highlightColor={skeletonHighlightColor}
                        height={28}
                        style={{
                          borderRadius: "5px",
                          margin: "0.3rem 2rem 0.3rem 0",
                        }}
                      />
                      <Skeleton
                        width={200}
                        baseColor={skeletonBaseColor}
                        highlightColor={skeletonHighlightColor}
                        height={28}
                        style={{
                          borderRadius: "5px",
                          margin: "0.3rem 2rem 0.3rem 0",
                        }}
                      />
                      <Skeleton
                        width={200}
                        baseColor={skeletonBaseColor}
                        highlightColor={skeletonHighlightColor}
                        height={28}
                        style={{
                          borderRadius: "5px",
                          margin: "0.3rem 2rem 0.3rem 0",
                        }}
                      />
                      <Skeleton
                        width={200}
                        baseColor={skeletonBaseColor}
                        highlightColor={skeletonHighlightColor}
                        height={28}
                        style={{
                          borderRadius: "5px",
                          margin: "0.3rem 2rem 0.3rem 0",
                        }}
                      />
                      <Skeleton
                        width={200}
                        baseColor={skeletonBaseColor}
                        highlightColor={skeletonHighlightColor}
                        height={28}
                        style={{
                          borderRadius: "5px",
                          margin: "0.3rem 2rem 0.3rem 0",
                        }}
                      />
                    </div>
                  ) : (
                    <LS.ColumnOne themeColor={theme}>
                      <p>{localState.learners_name} :</p>
                      <p>{localState.learner_email} :</p>
                      <p>{localState.learner_mobile_number} :</p>
                      <p>{localState.passing_percentage} :</p>
                      <p>{localState.sub_entity} :</p>
                      <p>{localState.last_login} :</p>
                      <p>{localState.badges_earned} : </p>
                    </LS.ColumnOne>
                  )}
                  {feildLoading ? (
                    <div>
                      <Skeleton
                        width={200}
                        baseColor={skeletonBaseColor}
                        highlightColor={skeletonHighlightColor}
                        height={28}
                        style={{
                          borderRadius: "5px",
                          margin: "0.3rem 2rem 0.3rem 0",
                        }}
                      />
                      <Skeleton
                        width={200}
                        baseColor={skeletonBaseColor}
                        highlightColor={skeletonHighlightColor}
                        height={28}
                        style={{
                          borderRadius: "5px",
                          margin: "0.3rem 2rem 0.3rem 0",
                        }}
                      />
                      <Skeleton
                        width={200}
                        baseColor={skeletonBaseColor}
                        highlightColor={skeletonHighlightColor}
                        height={28}
                        style={{
                          borderRadius: "5px",
                          margin: "0.3rem 2rem 0.3rem 0",
                        }}
                      />
                      <Skeleton
                        width={200}
                        baseColor={skeletonBaseColor}
                        highlightColor={skeletonHighlightColor}
                        height={28}
                        style={{
                          borderRadius: "5px",
                          margin: "0.3rem 2rem 0.3rem 0",
                        }}
                      />
                      <Skeleton
                        width={200}
                        baseColor={skeletonBaseColor}
                        highlightColor={skeletonHighlightColor}
                        height={28}
                        style={{
                          borderRadius: "5px",
                          margin: "0.3rem 2rem 0.3rem 0",
                        }}
                      />
                      <Skeleton
                        width={200}
                        baseColor={skeletonBaseColor}
                        highlightColor={skeletonHighlightColor}
                        height={28}
                        style={{
                          borderRadius: "5px",
                          margin: "0.3rem 2rem 0.3rem 0",
                        }}
                      />
                    </div>
                  ) : (
                    <LS.ColumnTwo themeColor={theme}>
                      <p>
                        {learnerData?.userName
                          ? `${learnerData?.userName}`
                          : "-"}
                      </p>
                      <p>
                        {learnerData?.email ? `${learnerData?.email}` : "-"}
                      </p>
                      <p>
                        {get(learnerData, "mobileNumberCountryCode", "")}
                        {get(learnerData, "mobileNumber", "-")}
                      </p>
                      <p>
                        {learnerData?.PassingPercentage
                          ? `${learnerData?.PassingPercentage}%`
                          : "-"}
                      </p>
                      <p>
                        {learnerData?.subEntity
                          ? `${learnerData?.subEntity}`
                          : "-"}
                      </p>
                      <p>
                        {learnerData?.lastLogin
                          ? moment(learnerData?.lastLogin).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )
                          : "-"}
                      </p>

                      <LS.BadgeContainer themeColor={theme}>
                        {learnerData?.badges &&
                        learnerData?.badges?.length > 0 ? (
                          learnerData?.badges?.map((val, i) => {
                            return (
                              <div
                                key={val._id}
                                className="com-container"
                                style={i > 0 ? { marginLeft: "-0.5rem" } : {}}
                              >
                                <img src={val.url} alt="" />
                              </div>
                            );
                          })
                        ) : (
                          <p>-</p>
                        )}

                        {/* <div
                          className="com-container"
                          style={{ marginLeft: "-0.5rem" }}
                        >
                          <img src={comBadge} alt="" />
                        </div>
                        <div
                          className="com-container"
                          style={{ marginLeft: "-0.5rem" }}
                        >
                          <img src={comBadge} alt="" />
                        </div> */}
                      </LS.BadgeContainer>
                    </LS.ColumnTwo>
                  )}
                </LS.ColumnContainer>
                <div style={{ width: "15%" }}>
                  <CDD.SaveButton
                    // cursor={isLoading}
                    // disabled={isLoading}
                    className="buttonload"
                    // type="submit"
                    onClick={() => handleImpersonate()}
                  >
                    {isLoading ? (
                      <i class="fa fa-spinner fa-spin"></i>
                    ) : (
                      localState.impersonate
                    )}
                  </CDD.SaveButton>
                </div>
              </LS.ComLearner>
              <Formik
                enableReinitialize
                initialValues={learnerValues}
                // validate={userValidator}
                validateOnChange
                onSubmit={(values, { setFieldTouched, setSubmitting }) => {
                  // Mark permissionsIds as touched on submit to trigger error display
                  handleLogin(values);
                  setFieldTouched("permissionsIds", true); // false to not revalidate other fields immediately

                  // Assume your submit logic here
                }}
              >
                {(formikBag) => {
                  return (
                    <Form style={{ width: "100%", overflowY: "scroll" }}>
                      <LS.ComLearner
                        themeColor={theme}
                        style={{ marginBottom: "1rem" }}
                      >
                        <LS.FirstLearner themeColor={theme}>
                          <p>{localState.edit_learners_detail}</p>
                        </LS.FirstLearner>
                        <LS.ColumnContainerProfile>
                          <LS.ProfileRow>
                            <LS.ProfileBox>
                              {isLoadingUpload ? (
                                <span>
                                  <i class="fa fa-spinner fa-spin"></i>
                                </span>
                              ) : feildLoading ? (
                                <Skeleton
                                  width={100}
                                  baseColor={skeletonBaseColor}
                                  highlightColor={skeletonHighlightColor}
                                  height={100}
                                  style={{
                                    borderRadius: "50%",
                                  }}
                                />
                              ) : (
                                <img
                                  className="profile-picture"
                                  src={profileImage || profileDemo}
                                  alt=""
                                />
                              )}

                              <LS.DelIcon
                                themeColor={theme}
                                onClick={() => {
                                  // formikBag.setFieldValue(
                                  //   "profileImage",
                                  //   ""
                                  // );
                                  setProfileImage(null);
                                }}
                              >
                                <img src={theme ? darkBin : binTwo} alt="" />
                              </LS.DelIcon>
                            </LS.ProfileBox>
                            <LS.ProfileContent themeColor={theme}>
                              {/* <button>
                                <img src={orangeCloud} alt="" />
                                Upload Photo
                              </button> */}
                              <UploadTrailerOnButton
                                type="file"
                                id="picos"
                                accept="image/jpeg, image/png, image/gif"
                                accepTed="image/"
                                alertMsg={localState.no_csv_found}
                                description="jpg, png (Max. 5 MB)"
                                star={false}
                                onChange={async (e) => {
                                  setIsLoadingUpload(true);
                                  var image = await UploadFile(
                                    e.target.files[0],
                                    setUploadProgress
                                  );
                                  if (image) {
                                    setIsLoadingUpload(false);
                                    setProfileImage(image);
                                    updateProfileImage(image);
                                  }
                                  e.target.value = "";
                                }}
                                error={
                                  formikBag.touched.logoImage &&
                                  formikBag.errors.logoImage
                                    ? formikBag.errors.logoImage
                                    : null
                                }
                              />
                              <p>SVG, PNG, JPG (max. 800x400px)</p>
                            </LS.ProfileContent>
                          </LS.ProfileRow>
                          <LS.DetailBox>
                            <LS.DetailRow>
                              <LS.LabelBox themeColor={theme}>
                                <label>{localState.department}</label>
                              </LS.LabelBox>
                              <LS.InputBoxs>
                                <Field name="department">
                                  {({ field }) => (
                                    <Input
                                      {...field}
                                      theme={theme}
                                      feildLoading={feildLoading}
                                      placeholder={localState.department}
                                      label="Department"
                                      onChange={(e) => {
                                        formikBag.setFieldValue(
                                          "department",
                                          e.target.value
                                        );
                                      }}
                                      error={
                                        formikBag.touched.department &&
                                        formikBag.errors.department
                                          ? formikBag.errors.department
                                          : null
                                      }
                                      erroros={errorObj?.department}
                                    />
                                  )}
                                </Field>
                              </LS.InputBoxs>
                            </LS.DetailRow>
                            <LS.DetailRow>
                              <LS.LabelBox themeColor={theme}>
                                <label>{localState.reporting_manager}</label>
                              </LS.LabelBox>
                              <LS.InputBoxs>
                                <Field name={`reportingManager`}>
                                  {({ field, form, meta }) => (
                                    <Input
                                      {...field}
                                      cursorNot={false}
                                      disabled
                                      theme={theme}
                                      feildLoading={feildLoading}
                                      placeholder={localState.reporting_manager}
                                      label="Reporting Manager"
                                      onChange={(e) => {
                                        formikBag.setFieldValue(
                                          "reportingManager",
                                          e.target.value
                                        );
                                      }}
                                      error={
                                        formikBag.touched.reportingManager &&
                                        formikBag.errors.reportingManager
                                          ? formikBag.errors.reportingManager
                                          : null
                                      }
                                      erroros={errorObj?.reportingManager}
                                    />
                                  )}
                                </Field>
                              </LS.InputBoxs>
                            </LS.DetailRow>
                            <LS.DetailRow>
                              <LS.LabelBox themeColor={theme}>
                                <label>{localState.city}</label>
                              </LS.LabelBox>
                              <LS.InputBoxs>
                                <Field name={`city`}>
                                  {({ field, form, meta }) => (
                                    <Input
                                      {...field}
                                      cursorNot={false}
                                      disabled
                                      theme={theme}
                                      feildLoading={feildLoading}
                                      placeholder={localState.enter_city}
                                      label="City"
                                      onChange={(e) => {
                                        formikBag.setFieldValue(
                                          "city",
                                          e.target.value
                                        );
                                      }}
                                      error={
                                        formikBag.touched.city &&
                                        formikBag.errors.city
                                          ? formikBag.errors.city
                                          : null
                                      }
                                      erroros={errorObj?.city}
                                    />
                                  )}
                                </Field>
                              </LS.InputBoxs>
                            </LS.DetailRow>
                            <LS.DetailRow>
                              <LS.LabelBox themeColor={theme}>
                                <label>{localState.language}</label>
                              </LS.LabelBox>
                              <LS.InputBoxs>
                                <LS.InputBoxContainers
                                  style={{
                                    justifyContent: "flex-start",
                                    marginTop: "0.7rem",
                                  }}
                                >
                                  {learnerLang?.map((val, ind) => {
                                    return (
                                      <LS.ComFeedbackRowssUser key={ind}>
                                        <LS.CheckBoxs>
                                          <img
                                            onClick={() => {
                                              languageUpdate(
                                                val._id,
                                                val.select === true
                                                  ? "remove"
                                                  : "add"
                                              );
                                            }}
                                            src={
                                              val.select
                                                ? theme
                                                  ? darkcheckIcon
                                                  : checkboxIcon
                                                : theme
                                                ? darkCheckboxBaseIcon
                                                : checkboxBaseIcon
                                            }
                                            alt=""
                                          />
                                        </LS.CheckBoxs>
                                        <LS.FeedbackOrangeTextss
                                          themeColor={theme}
                                        >
                                          {val.title}
                                        </LS.FeedbackOrangeTextss>
                                      </LS.ComFeedbackRowssUser>
                                    );
                                  })}
                                </LS.InputBoxContainers>
                              </LS.InputBoxs>
                            </LS.DetailRow>
                            <LS.DetailRow>
                              <LS.LabelBox themeColor={theme}>
                                <label>{localState.designation}</label>
                              </LS.LabelBox>
                              <LS.InputBoxs>
                                <Field name="designation">
                                  {({ field }) => (
                                    <Input
                                      {...field}
                                      theme={theme}
                                      feildLoading={feildLoading}
                                      placeholder={localState.designation}
                                      label="Designation"
                                      onChange={(e) => {
                                        formikBag.setFieldValue(
                                          "designation",
                                          e.target.value
                                        );
                                      }}
                                      error={
                                        formikBag.touched.designation &&
                                        formikBag.errors.designation
                                          ? formikBag.errors.designation
                                          : null
                                      }
                                      erroros={errorObj?.designation}
                                    />
                                  )}
                                </Field>
                              </LS.InputBoxs>
                            </LS.DetailRow>
                            <LS.DetailRow>
                              <LS.LabelBox themeColor={theme}>
                                <label>{localState.active_InActive}</label>
                              </LS.LabelBox>
                              <LS.InputBox>
                                <LS.DefaultToggle>
                                  <Switch
                                    rounded={true}
                                    isToggled={formikBag.values.active}
                                    bgColorChecked="#F05D24"
                                    roundColorChecked="#ffffff"
                                    border={
                                      theme
                                        ? "1px solid transparent"
                                        : "1px solid #D7DAE0"
                                    }
                                    marLeft={true}
                                    bgColorUnChecked={
                                      theme ? "#1F242F" : "#D7DAE0"
                                    }
                                    roundColorUnChecked="#ffffff"
                                    onToggle={() => {
                                      formikBag.setFieldValue(
                                        "active",
                                        !formikBag.values.active
                                      );
                                    }}
                                  />
                                </LS.DefaultToggle>
                              </LS.InputBox>
                            </LS.DetailRow>
                          </LS.DetailBox>
                        </LS.ColumnContainerProfile>
                      </LS.ComLearner>
                      <CDD.ButtonGroup themeColor={theme}>
                        <CDD.CancelButton
                          type="button"
                          themeColor={theme}
                          onClick={() => navigate(-1)}
                        >
                          {localState.cancel}
                        </CDD.CancelButton>
                        <CDD.SaveButton
                          cursor={isLoading}
                          disabled={isLoading}
                          className="buttonload"
                          type="submit"
                        >
                          {isLoading ? (
                            <i class="fa fa-spinner fa-spin"></i>
                          ) : (
                            localState.save_changes
                          )}
                        </CDD.SaveButton>
                      </CDD.ButtonGroup>
                    </Form>
                  );
                }}
              </Formik>
            </LS.LearnerBox>
          </CDS.DetailInnerCon>
        </CDS.DetailCon>
      </CDS.DetailContainer>
      {/* <Modal
        isOpen={showsucess}
        onClose={handleClose}
        maxWidth="400px"
        title={
          <>
            <SEC.SuccessIconBox>
              <img src={theme ? success : darkSuccess} alt="danger" />
            </SEC.SuccessIconBox>
          </>
        }
        content={
          <>
            <SEC.InnerSectionSuccess>
              <SEC.MomoTitle themeColor={theme?.isDark}>
                {localState.success}!
              </SEC.MomoTitle>
            </SEC.InnerSectionSuccess>
          </>
        }
      /> */}
      <PopUp
        isOpen={showsucess}
        onClose={handleClose}
        maxWidth="400px"
        title={
          <>
            <SEC.SuccessIconBoxTwo>
              <img src={theme ? successModal : successModal} alt="danger" />
            </SEC.SuccessIconBoxTwo>
          </>
        }
        content={
          <SEC.InnerSection>
            <SEC.TitleTwo themeColor={theme}>Great!</SEC.TitleTwo>

            <SEC.Subtitle themeColor={theme}>
              Your data has been successfully saved.
            </SEC.Subtitle>
            <SEC.GreenContinue
              onClick={() => {
                setShowsucess(false);
                navigate("/client-admin/manage-learners");
              }}
            >
              Continue
            </SEC.GreenContinue>
          </SEC.InnerSection>
        }
      />
      <ModalTwo
        theme={theme}
        // maxWidth={"750px"}
        isOpen={isOpens}
        isClose={handleClose}
        component={
          <SRS.SendMailBox themeColor={theme}>
            <Formik
              enableReinitialize
              initialValues={loginValues}
              validate={notiValidator}
              validateOnChange
              onSubmit={(values) => handleNotification(values, false)}
            >
              {(formikBag) => {
                return (
                  <Form style={{ width: "100%" }}>
                    <UE.InputBoxContainer>
                      <UE.InputBox wiDth={"49%"}>
                        <Field name="title">
                          {({ field }) => (
                            <InputAddTwo
                              {...field}
                              placeholder="Notification Title"
                              label="Notification Title"
                              theme={theme}
                              value={formikBag.values.title}
                              feildLoading={false}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "title",
                                  e.target.value
                                );
                                formikBag.setFieldTouched("title", true, false);
                                setErrorObj({});
                              }}
                              error={
                                formikBag.touched.title &&
                                formikBag.errors.title
                                  ? formikBag.errors.title
                                  : null
                              }
                              erroros={errorObj.title}
                            />
                          )}
                        </Field>
                      </UE.InputBox>
                      <UE.InputBox wiDth={"49%"}>
                        <Field name="type">
                          {({ field }) => (
                            <SelectNoti
                              {...field}
                              placeholder="Notification Type"
                              label="Notification Type"
                              options={notiType}
                              theme={theme}
                              value={notiType?.find(
                                (val) => val.value === formikBag.values.type
                              )}
                              feildLoading={false}
                              onChange={(e) => {
                                formikBag.setFieldValue("type", e.value);
                                formikBag.setFieldTouched("type", true, false);
                                setErrorObj({});
                              }}
                              error={
                                formikBag.touched.type && formikBag.errors.type
                                  ? formikBag.errors.type
                                  : null
                              }
                              erroros={errorObj.type}
                            />
                          )}
                        </Field>
                      </UE.InputBox>
                      <UE.InputBox wiDth={"100%"}>
                        <Field name="body">
                          {({ field }) => (
                            <TextArea
                              {...field}
                              placeholder="Text Here..."
                              label="Message"
                              rows="6"
                              optional={false}
                              feildLoading={false}
                              value={formikBag.values.body}
                              onChange={(e) => {
                                formikBag.setFieldValue("body", e.target.value);

                                formikBag.setFieldTouched("body", true, false);
                                setErrorObj({});
                              }}
                              error={
                                formikBag.touched.body && formikBag.errors.body
                                  ? formikBag.errors.body
                                  : null
                              }
                              erroros={errorObj.body}
                            />
                          )}
                        </Field>
                      </UE.InputBox>
                    </UE.InputBoxContainer>
                    <CDD.ButtonGroup
                      style={{ marginTop: "0rem" }}
                      themeColor={theme}
                    >
                      <CDD.SaveButton
                        cursor={isLoading}
                        disabled={isLoading}
                        className="buttonload"
                        type="submit"
                      >
                        {isLoading ? (
                          <i class="fa fa-spinner fa-spin"></i>
                        ) : (
                          localState.send
                        )}
                      </CDD.SaveButton>
                    </CDD.ButtonGroup>
                  </Form>
                );
              }}
            </Formik>
          </SRS.SendMailBox>
        }
      />
      <ModalThree
        theme={theme}
        // maxWidth={"750px"}
        isOpen={isMailOpen}
        isClose={handleClose}
        component={
          <SRS.SendMailBox themeColor={theme}>
            <Formik
              enableReinitialize
              initialValues={mailLoginVlaues}
              validateOnChange
              validate={EmailSendValidator}
              onSubmit={(values) => handleMail(values, false)}
            >
              {(formikBag) => {
                return (
                  <Form style={{ width: "100%" }}>
                    {/* <SRS.PublicRow style={{ marginBottom: "0.7rem" }}>
                      <SRS.LabelBox themeColor={theme}>
                        <p>{localState.from} :</p>
                      </SRS.LabelBox>
                      <SRS.InputBox style={{ width: "100%" }}>
                        <Field name="from">
                          {({ field }) => (
                            <SelectLabel
                              {...field}
                              theme={theme}
                              options={locationList}
                              value={locationList?.filter(
                                (val) => val.value === formikBag.values.from
                              )}
                              placeholder="Select Course"
                              onChange={(e) => {}}
                              error={
                                formikBag.touched.from &&
                                formikBag.errors.from
                                  ? formikBag.errors.from
                                  : null
                              }
                            />
                          )}
                        </Field>
                      </SRS.InputBox>
                    </SRS.PublicRow> */}
                    {/* <SRS.PublicRow>
                      <SRS.LabelBox themeColor={theme}>
                        <p>{localState.send_to} :</p>
                      </SRS.LabelBox>
                      <SRS.InputBox>
                        <Field name="users">
                          {({ field }) => (
                            <Input
                              {...field}
                              theme={theme}
                              placeholder="Send To"
                              value={formikBag.values.users}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "users",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.users && 
                                formikBag.errors.users
                                  ? formikBag.errors.users
                                  : null
                              }
                            />
                          )}
                        </Field>
                      </SRS.InputBox>
                    </SRS.PublicRow> */}
                    <SRS.PublicRow>
                      <SRS.LabelBox themeColor={theme}>
                        <p>{localState.subject} :</p>
                      </SRS.LabelBox>
                      <SRS.InputBox>
                        <Field name="subject">
                          {({ field }) => (
                            <Input
                              {...field}
                              placeholder="Subject"
                              value={formikBag.values.subject}
                              theme={theme}
                              onChange={(e) => {
                                formikBag.setFieldValue(
                                  "subject",
                                  e.target.value
                                );
                              }}
                              error={
                                formikBag.touched.subject &&
                                formikBag.errors.subject
                                  ? formikBag.errors.subject
                                  : null
                              }
                            />
                          )}
                        </Field>
                      </SRS.InputBox>
                    </SRS.PublicRow>
                    <SRS.EmailEditor
                      error={
                        formikBag.touched.body && formikBag.errors.body
                          ? formikBag.errors.body
                          : null
                      }
                      themeColor={theme}
                    >
                      <ReactQuill
                        ref={reactQuillRef}
                        value={formikBag.values.body}
                        placeholder="Text Here..."
                        onChange={(e) => {
                          // setErrorObj({});
                          formikBag.setFieldValue("body", e);
                        }}
                        modules={modules}
                      />
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                      {/* <div className="my-editing-area"></div> */}
                    </SRS.EmailEditor>
                    <p
                      style={{
                        paddingTop: 10,
                        fontSize: 11,
                        color: "red ",
                      }}
                    >
                      {(formikBag.touched.body && formikBag.errors.body) ||
                      errorObj.body
                        ? formikBag.errors.body || errorObj.body
                        : null}
                    </p>
                    <CDD.ButtonGroup
                      style={{ marginTop: "5rem" }}
                      themeColor={theme}
                    >
                      <CDD.SaveButton
                        cursor={isLoading}
                        disabled={isLoading}
                        className="buttonload"
                        type="submit"
                      >
                        {isLoading ? (
                          <i class="fa fa-spinner fa-spin"></i>
                        ) : (
                          localState.send
                        )}
                      </CDD.SaveButton>
                    </CDD.ButtonGroup>
                  </Form>
                );
              }}
            </Formik>
          </SRS.SendMailBox>
        }
      />
      <PopUp
        isOpen={showsucessMail}
        onClose={handleClose}
        maxWidth="400px"
        title={
          <>
            <SEC.SuccessIconBoxTwo>
              <img src={theme ? successModal : successModal} alt="danger" />
            </SEC.SuccessIconBoxTwo>
          </>
        }
        content={
          <SEC.InnerSection>
            <SEC.TitleTwo themeColor={theme}>Great!</SEC.TitleTwo>

            <SEC.Subtitle themeColor={theme}>
              Mail has been sent successfully.
            </SEC.Subtitle>
            <SEC.GreenContinue
              onClick={() => {
                setShowsucessMail(false);
              }}
            >
              Continue
            </SEC.GreenContinue>
          </SEC.InnerSection>
        }
      />
    </>
  );
};

export default CourseDetail;
