import React from "react";
import socketio from "socket.io-client";
// import { env } from "../env";
import { API_URL_SOCKET } from "../pages/constants/Statics";

// let hostD = "https://admin.pepesibubbles.com/";

export const socket = socketio.connect(API_URL_SOCKET);
export const SocketContext = React.createContext();
// import { env } from "../env";
