import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";

export const ProfileContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 1rem;
`;
export const AccountTitleBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  font-family: EB Garamond;
  font-weight: 500;
  font-size: 1.7rem;
  border-bottom: ${(props) =>
    props.themeColor ? "0.8px solid #333741" : "0.8px solid #d7dae0"};
  margin-top: 1rem;
  padding-bottom: 1rem;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#24262D")};

  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
    margin-right: 10px;
    cursor: pointer;
  }
`;
export const SectionContainer = styled.section`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 4rem;
  margin-top: 1.8rem;
`;
export const SectionSidebar = styled.ul`
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  padding: 0.8rem;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  border-radius: 8px;
`;
export const SidebarMenu = styled(NavLink)`
  text-decoration: none;
  width: 100%;
  background-color: ${(props) =>
    props.isActive
      ? props.themeColor
        ? "#1F242F"
        : "#f6f7f9"
      : props.themeColor
      ? "#0C111D"
      : "#FFFFFF"};
  color: ${(props) =>
    props.isActive ? "#f05d24" : props.themeColor ? "#85888E" : "#667085"};
  border-radius: 8px;
  padding: 0.8rem;
  font-weight: 500;
  font-size: 1rem;
`;
export const SectionRightBox = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
`;
export const RightHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: ${(props) => (props.themeColor ? "1px solid #333741" : "1px solid #d7dae0")};
  padding-bottom: 1.2rem;
`;
export const SectionTitle = styled.p`
  font-size: 1rem;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363a44")};
  font-weight: 600;
  text-align: left;
  margin-bottom: 5px;
`;
export const SectionDesc = styled.p`
  font-size: 0.8rem;
  color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
  font-weight: 400;
  text-align: left;
`;
export const RightContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ProfieIcon = styled.div`
  /* width: 10%; */
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 15px;
  border-radius: 50%;

  img {
    height: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 50%;
  }
  `;

export const UploadProfileBox = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
export const InputLable = styled.label`
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 0.3rem;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
  margin-bottom: 0.5rem;
  text-transform: capitalize;

  @media (max-width: 351px) {
    font-size: 0.8rem;
  }
`;
