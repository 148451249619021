import React, { useState, useRef, useEffect } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";

import "@react-pdf-viewer/core/lib/styles/index.css";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfWorker from "pdfjs-dist/build/pdf.worker.entry";

const PdfView = ({ policyUrl }) => {
  pdfjsLib.GlobalWorkerOptions.workerSrc = pdfWorker;

  return (
    <div>
      {/* {!policyUrl && <span>Loading...</span>} */}
      <Worker workerUrl={pdfWorker}>
        <div style={{ minHeight: "450px" }}>
          <Viewer fileUrl={policyUrl} />
        </div>
      </Worker>
    </div>
  );
};

export default PdfView;
