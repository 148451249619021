import React from "react";
import * as MS from "../../styles/ModelStyled";
import { useDispatch } from "react-redux";
import { setIsModal } from "../../store/action";
import redCross from "../../assets/images/redCross.png";
import crossIcon from "../../assets/images/crossIcon.png";
import { useSelector } from "react-redux";

const Modal = ({ component, isOpen, isClose, backColor, maxWidth }) => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state?.isDark);
  return isOpen ? (
    <MS.ModalContainer>
      <MS.InnerBox maxWidth={maxWidth} backColor={backColor}>
        {isClose ? (
          <MS.CrossRow themeColor={theme}>
            <img
              className="cross-con"
              onClick={() => {
                isClose(false);
                dispatch(setIsModal(false));
              }}
              src={crossIcon}
              alt=""
            />
          </MS.CrossRow>
        ) : (
          ""
        )}

        {component}
      </MS.InnerBox>
    </MS.ModalContainer>
  ) : (
    ""
  );
};

export default Modal;
