import React, { useEffect, useRef, useState } from "react";
import * as CDS from "../../../styles/CourseDetailStyled";
import * as SES from "../../../styles/SettingStyled";
import CourseView from "./CourseView";
import { useSelector, useDispatch } from "react-redux";
import Switch from "../../../components/Switch/Switch";
import * as MS from "../../../styles/ManageStyled";
import plusIcon from "../../../assets/images/plusIcon.png";
import { Commiittee } from "../../../utils/api";
import axios from "../../../utils/axios";
import * as UE from "../../../styles/UserElement";
import { removeDuplicatesById } from "../../../utils/Helpers";
import * as CMS from "../../../styles/CommitteeStyled";
import editIcon from "../../../assets/images/editLight.png";
import delIcon from "../../../assets/images/delete.png";
import { PopUp } from "../../../components/PopUp/PopUp";
import * as SEC from "../../../styles/SectionStyled";
import warnIcon from "../../../assets/images/warn.png";

const Committee = ({ navigate, theme, localState }) => {
  const dispatch = useDispatch();
  const [activeId, setActiveId] = useState(false);
  const [commitLearnerToggled, setCommitLearnerToggled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [userList, setUserList] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const courseId = useSelector((state) => state?.courseId);
  const [errorObj, setErrorObj] = useState({});
  const [listId, setListId] = useState("");
  const sentinelRef = useRef();
  const [opens, setOpens] = useState(false);

  const pageLimit = 10;

  const [comitInitialValues, setComitInitialValues] = useState({
    committeeName: "",
    city: "",
    commiteId: "",
  });
  const getCommiittee = async () => {
    if (currentPage === 1) {
      setUserList([]); // Reset feedback list on major parameter change or first page load
    }

    setIsLoading(true);
    let url = `${Commiittee.getCommiittee}`;
    const formValue = {
      limit: pageLimit,
      page: currentPage,
      // clientId: clientId,
      courseId: courseId,
    };
    try {
      const { data } = await axios.post(url, formValue);
      setIsLoading(false);
      if (data?.result?.docs?.length > 0) {
        setUserList((prevList) => [
          ...prevList, // Keeps existing data if this is a subsequent page of data
          ...data?.result?.docs,
        ]);
      } else if (currentPage === 1) {
        setUserList([]); // Clear the feedback list if response is empty and it's the first page
      }
      setTotalCount(data?.result?.totalPages);
    } catch (error) {
      // alert(error.response.data.message);
      setIsLoading(false);
      console.log(error.response.data.message);
    }
  };

  const deleteComit = async (e) => {
    let url = Commiittee.deleteCommiittee;
    const formValue = {
      ids: [e],
    };
    try {
      const { data } = await axios.post(url, formValue);
      getCommiittee();
      setOpens(false);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  useEffect(() => {
    getCommiittee(-1, "createdAt");
  }, []);

  const handleCloses = () => {
    setOpens(false);
  };

  const configUpdate = async (e) => {
    let url = Commiittee.statusUpdate;
    const formValue = {
      courseId: courseId,
      committe: e,
    };
    try {
      const { data } = await axios.post(url, formValue);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  const getCommitStatus = async (e) => {
    let url = `${Commiittee.getStatus}${courseId}`;
    try {
      const { data } = await axios.get(url);
      if (data?.data) {
        setCommitLearnerToggled(data?.data?.committe);
      } else {
        setCommitLearnerToggled(false);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    getCommitStatus();
  }, []);
  return (
    <>
      <CDS.DetailContainer>
        {/* <CourseView
          theme={theme}
          courseId={courseId}
          navigate={navigate}
          localState={localState}
        /> */}
        <CDS.DetailCon>
          <CDS.DetailInnerCon>
            <SES.RowCommiittee themeColor={theme}>
              <SES.ComRowSwitch
                style={{ margin: "1.5rem 0 0.5rem 0", width: "50%" }}
                themeColor={theme}
              >
                <p style={{ fontWeight: "600" }}>
                  {localState.committe_for_learner} :{" "}
                </p>
                <Switch
                  rounded={true}
                  isToggled={commitLearnerToggled}
                  bgColorChecked="#F6591C"
                  roundColorChecked="#ffffff"
                  wiDth={"40px"}
                  themeColor={theme}
                  border={theme ? "1px solid transparent" : "1px solid #D7DAE0"}
                  bgColorUnChecked={theme ? "#1F242F" : "#F5F5F6"}
                  roundColorUnChecked="#ffffff"
                  onToggle={() => {
                    setCommitLearnerToggled(!commitLearnerToggled);
                    configUpdate(!commitLearnerToggled);
                  }}
                />
              </SES.ComRowSwitch>
              {userList?.length > 0 ? (
                <div>
                  <SES.AddCommit
                    themeColor={theme}
                    onClick={() => {
                      navigate("/client-admin/courses-manage/add-committee");
                    }}
                  >
                    <img src={plusIcon} alt="" />
                    <p>{localState.add_commiittee}</p>
                  </SES.AddCommit>
                </div>
              ) : (
                ""
              )}
            </SES.RowCommiittee>
            {commitLearnerToggled ? (
              userList?.length > 0 ? (
                <UE.FormContainerComit
                  themeColor={theme}
                  style={{ padding: "0 1.5rem 0 0", gap: "0.8rem" }}
                >
                  {removeDuplicatesById(userList)?.map((val, ind) => {
                    return (
                      <CMS.CommitRow themeColor={theme} key={ind}>
                        <CMS.FirstBox themeColor={theme}>
                          <p className="title">{val.title}</p>
                          <p className="desc">{val.city}</p>
                        </CMS.FirstBox>
                        <CMS.IconBox themeColor={theme}>
                          <img
                            src={editIcon}
                            onClick={() => {
                              navigate(
                                "/client-admin/courses-manage/add-committee",
                                {
                                  state: {
                                    category_data: {
                                      committeeName: val.title,
                                      city: val.city,
                                      commiteId: val._id,
                                    },
                                  },
                                }
                              );

                              setErrorObj({});
                            }}
                            alt=""
                          />
                          <img
                            src={delIcon}
                            onClick={() => {
                              setListId(val._id);
                              setOpens(true);
                            }}
                            alt=""
                          />
                        </CMS.IconBox>
                      </CMS.CommitRow>
                    );
                  })}

                  <div
                    ref={sentinelRef}
                    style={{ height: "1px", width: "100%" }}
                  ></div>
                </UE.FormContainerComit>
              ) : (
                <CDS.AddCommitteeContainer themeColor={theme}>
                  <MS.AddCommonButton
                    onClick={() =>
                      navigate("/client-admin/courses-manage/add-committee")
                    }
                  >
                    <img src={plusIcon} alt="" />
                    {localState.add_commiittee}
                  </MS.AddCommonButton>
                </CDS.AddCommitteeContainer>
              )
            ) : (
              ""
            )}
          </CDS.DetailInnerCon>
        </CDS.DetailCon>
      </CDS.DetailContainer>
      <PopUp
        isOpen={opens}
        onClose={handleCloses}
        maxWidth="400px"
        title={
          <>
            <SEC.WarnIconBox>
              <img src={warnIcon} alt="danger" />
            </SEC.WarnIconBox>
          </>
        }
        content={
          <>
            <SEC.InnerSection themeColor={theme}>
              <SEC.Title style={{ textAlign: "center" }} themeColor={theme}>
                {localState.are_you_sure}!
              </SEC.Title>
              {/* <SEC.TitleDesc themeColor={theme}>
                (Category contains associated content or)
              </SEC.TitleDesc> */}
              <SEC.Subtitle style={{ textAlign: "center" }} themeColor={theme}>
                “
                {
                  localState.deleting_this_committee_will_remove_all_associated_data_and_cannot_be_reversed
                }
                ”.
              </SEC.Subtitle>
              <SEC.BtnGroup>
                <SEC.ComButton
                  themeColor={theme}
                  onClick={() => setOpens(false)}
                  type="button"
                >
                  {localState.cancel}
                </SEC.ComButton>
                <SEC.ComButton
                  themeColor={theme}
                  color={"#FFFFFF"}
                  borderColor={"#B3B9C6"}
                  backColor={"#E0271A"}
                  onClick={() => {
                    deleteComit(listId);
                  }}
                >
                  {localState.delete}
                </SEC.ComButton>
              </SEC.BtnGroup>
            </SEC.InnerSection>
          </>
        }
      />
    </>
  );
};

export default Committee;
