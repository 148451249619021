import React from "react";
import * as MS from "../../styles/ModelStyled";
import { useDispatch } from "react-redux";
import { setIsModal } from "../../store/action";
import redCross from "../../assets/images/redCross.png";
import videoDark from "../../assets/images/videoDark.png";
import videoLight from "../../assets/images/videoLight.png";

const Modal = ({ component, isOpen, isClose, backColor, maxWidth, theme, handleTrailerPlay }) => {
  const dispatch = useDispatch();
  return isOpen ? (
    <MS.ModalContainerSo>
      <MS.InnerBoxCalender themeColor={theme} maxWidth={maxWidth} backColor={backColor}>
        {component}
      </MS.InnerBoxCalender>
    </MS.ModalContainerSo>
  ) : (
    ""
  );
};

export default Modal;
